// Description: This component is used to update the user's profile tellMore field
import React, { useState } from "react";
import Loader from "../Loader/Loader";

import { Card, CardContent, Button } from "@mui/material";
import "./AboutMe.css";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useUser } from "../../hooks/auth";
import { useUpdateUserProfile } from "../../hooks/user";

export default function AboutMe() {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  const { isLoading: loading, data: user } = useUser();
  const {
    isLoading: updateLoading,

    mutate: update,
  } = useUpdateUserProfile();

  const [tellMore, setTellMore] = useState(user?.tellMore);
  const submitHandler = async (e) => {
    e.preventDefault();
    update({
      ...user,
      tellMore,
    });
    // await dispatch(editAboutMe(tellMore));

    navigate("/account");
  };

  return loading || updateLoading ? (
    <Loader />
  ) : (
    <div className="userCard">
      <br />

      <Card className="p-0" style={{ height: "100%" }}>
        <div className="page-card-header d-flex justify-content-between">
          <h2
            className="p-3 d-flex w-100"
            style={{
              justifyContent:
                i18n.language === "ar" ? "flex-end" : "flex-start",
            }}
          >
            {t("profile.aboutMe.title")}
          </h2>
        </div>

        <CardContent>
          <div
            className="d-flex"
            style={{ paddingLeft: "15px", paddingRight: "15px" }}
          >
            <form style={{ width: "100%" }} onSubmit={submitHandler}>
              <h2
                className="more-about d-flex w-100"
                style={{
                  justifyContent:
                    i18n.language === "ar" ? "flex-end" : "flex-start",
                }}
              >
                {t("profile.aboutMe.label_tell_more")}
              </h2>
              <textarea
                // className="more-about-field w-100"
                style={{ width: "100%", paddingBottom: "15px" }}
                formControlName="tellMore"
                className="form-control"
                //="tellMore"
                maxLength="1000"
                minLength="75"
                rows="3"
                required
                // rows={3}
                // maxLength="1000"
                placeholder={t("profile.aboutMe.label_tell_more")}
                value={tellMore}
                onChange={(e) => setTellMore(e.target.value)}
              />
              <br />
              <br />

              <div
                className="d-flex"
                style={{
                  justifyContent: "center",
                  paddingLeft: "15px",
                  paddingRight: "15px",
                }}
              >
                <Button
                  type="submit"
                  variant="contained"
                  className="update-button  w-50"
                >
                  {t("profile.aboutMe.save")}
                </Button>
              </div>
            </form>
          </div>
        </CardContent>
      </Card>
    </div>
  );
}
