// Description: This component is used to show the conditions to sign up as a cofounder
import React, { useState } from "react";
import { Link } from "react-router-dom";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { useTranslation } from "react-i18next";
export default function ConditionToSignUpCoFounder() {
  const { t, i18n } = useTranslation();
  const [open, setOpen] = useState(true);
  const [firstCondition, setFirstCondition] = useState(false);
  const [secondCondition, setSecondCondition] = useState(false);
  const [thirdCondition, setThirdCondition] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);

  const handleChange1 = (event) => {
    setFirstCondition(event.target.checked);
  };
  const handleChange2 = (event) => {
    setSecondCondition(event.target.checked);
  };
  const handleChange3 = (event) => {
    setThirdCondition(event.target.checked);

    // setIsDisabled(false);
  };

  const handleClose = () => {
    if (
      firstCondition === true &&
      secondCondition === true &&
      thirdCondition === true
    ) {
      setOpen(false);
    }
  };
  const handleButton = () => {
    if (
      firstCondition === true &&
      secondCondition === true &&
      thirdCondition === true
    ) {
      return false;
    } else {
      return true;
    }
  };
  const handleButtonStyle = () => {
    if (
      firstCondition === true &&
      secondCondition === true &&
      thirdCondition === true
    ) {
      return "outlined";
    } else {
      return "contained";
    }
  };

  return (
    <div>
      <Dialog open={open} fullWidth={true} maxWidth={"md"}>
        <DialogTitle className=" text-center">
          {t("userType.cofounder")}
        </DialogTitle>
        <DialogContent className=" pt-3">
          <div className="col-lg-12   pr-lg-5 pt-3  ">
            <FormGroup>
              <FormControlLabel
                style={{
                  textAlign: i18n.language === "ar" ? "right" : "left",
                  direction: i18n.language === "ar" ? "rtl" : "ltr",
                }}
                control={
                  <Checkbox
                    checked={firstCondition}
                    onChange={handleChange1}
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      textAlign: i18n.language === "ar" ? "right" : "left",
                      direction: i18n.language === "ar" ? "rtl" : "ltr",
                    }}
                  />
                }
                label={t("signup.personCheck")}
              />
            </FormGroup>
          </div>
          <div className="col-lg-12   pr-lg-5 pt-3  ">
            <FormGroup>
              <FormControlLabel
                style={{
                  textAlign: i18n.language === "ar" ? "right" : "left",
                  direction: i18n.language === "ar" ? "rtl" : "ltr",
                }}
                control={
                  <Checkbox
                    checked={secondCondition}
                    onChange={handleChange2}
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      textAlign: i18n.language === "ar" ? "right" : "left",
                      direction: i18n.language === "ar" ? "rtl" : "ltr",
                    }}
                  />
                }
                label={t("signup.founderCheck")}
              />
            </FormGroup>
          </div>
          <div className="col-lg-12   pr-lg-5 pt-3  ">
            <FormGroup>
              <FormControlLabel
                style={{
                  textAlign: i18n.language === "ar" ? "right" : "left",
                  direction: i18n.language === "ar" ? "rtl" : "ltr",
                }}
                control={
                  <Checkbox
                    checked={thirdCondition}
                    onChange={handleChange3}
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      textAlign: i18n.language === "ar" ? "right" : "left",
                      direction: i18n.language === "ar" ? "rtl" : "ltr",
                    }}
                  />
                }
                label={t("signup.notVBCheck")}
              />
            </FormGroup>
          </div>
          <div className="col-lg-12   pr-lg-5 pt-3   ">
            <h6
              style={{
                display: "flex",
                color: "red",
                textAlign: i18n.language === "ar" ? "right" : "left",
                direction: i18n.language === "ar" ? "rtl" : "ltr",
                // paddingRight: "50px",
              }}
            >
              {t("signup.condition")}
            </h6>
            <h6
              style={{
                display: "flex",
                color: "black",
                textAlign: i18n.language === "ar" ? "right" : "left",
                direction: i18n.language === "ar" ? "rtl" : "ltr",
                // paddingRight: "50px",
              }}
            >
              {t("signup.condition2")}
            </h6>
          </div>
        </DialogContent>
        <DialogActions style={{ alignSelf: "center" }}>
          <div className="col-12 mb-3  justify-content-center p-2 ">
            <Link style={{ color: "#37a11a" }} to="/" className=" pb-2">
              <Button
                className="btn-block "
                variant="outlined"
                color="primary"

                //onClick={handleClose}
              >
                {t("signup.return")}
              </Button>
            </Link>
            <br />
            <Button
              disabled={handleButton()}
              variant="contained"
              color="primary"
              className="btn-block "
              onClick={handleClose}
            >
              {t("signup.confirm")}
            </Button>
          </div>
        </DialogActions>
      </Dialog>
    </div>
  );
}
