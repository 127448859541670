import axios from "axios";

const API = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  withCredentials: true,
});

export const getAllMessages = async (userFrom, contactId) => {
  try {
    const { messages } = await API.get(`/message/get-messages/` + userFrom + `/` + contactId).
    then((res) => res.data);
    return messages;
  } catch (error) {
    throw error;
  }
};
//send message to contact user
export const sendMessage = async (message) => {
  try {
    const { messageRes } = await API.post(`/message/send-message`, {
        message
    }).then((res) => res.data);
    return messageRes;
  } catch (error) {
    throw error;
  }
};
