// Description: Header Component
import React, { useState } from "react";
import Logo from "../../assets/img/Imateco@3x.png";
import { Link } from "react-router-dom";
import "./Header.css";
import { useTranslation } from "react-i18next";
import { useLogout, useUser } from "../../hooks/auth";

import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Offcanvas from 'react-bootstrap/Offcanvas';
import Divider from "@mui/material/Divider";
import { BiMessageDetail, BiUser } from "react-icons/bi";
import LanguageSwitcher from './LanguageSwitcher';
import Dropdown from 'react-bootstrap/Dropdown';
import { useMediaQuery } from 'react-responsive';
import imatecoLogo from "../../assets/img/imatco_logo.png";


export default function Header() {

	const { t, i18n } = useTranslation();

	const [toggleMenu, setToggleMenu] = useState(false);
	const isMobile = useMediaQuery({ query: `(max-width: 760px)` });
	const { data: user } = useUser(); //get user data
	const { refetch } = useLogout(); //logout function

	const logoutHandler = () => {
		setToggleMenu(!toggleMenu);
		refetch();
	};
	return (
		<nav className="py-4 topnav" dir={i18n.language === "ar" ? "rtl" : "ltr"}>
			<div className="container">
				<div className="d-flex justify-content-between align-items-center">
					<Link to='/'>
						<img src={imatecoLogo} alt="Imatco Logo Brand" className='logo-brand' />
					</Link>

					<div className={`mx-auto offcanvas-menu ${toggleMenu ? 'open' : ''}`}>

						<div className="d-lg-none d-flex justify-content-between mb-3">

							<Link to='/'>
								<img src={imatecoLogo} alt="Imatco Logo Brand" onClick={() => setToggleMenu(!toggleMenu)} className='logo-brand' />
							</Link>

							<button className="toggel-menu" onClick={() => setToggleMenu(!toggleMenu)}>
								<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
									<path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12" />
								</svg>
							</button>

						</div>

						<ul className='d-flex align-items-center mx-auto m-0 nav-menu'>
							{(isMobile && user) && (
								<>
									<li>
										<h4 className="green-heading text-center">
											{user.firstName} {user.lastName}</h4>
											<Divider />
									</li>
									<li>

										<Link onClick={() => setToggleMenu(!toggleMenu)}
											to="/account"
										>
											{t("global.account")}
										</Link>
									</li>
								</>
							)}
							<li>
								<Link onClick={() => setToggleMenu(!toggleMenu)} to='/profiles'>{t('menu.technical profiles')}</Link>
							</li>
							<li>
								<Link onClick={() => setToggleMenu(!toggleMenu)} to='/blog'>{t('menu.blog')}</Link>
							</li>
							<li>
								<Link onClick={() => setToggleMenu(!toggleMenu)} to='/us'>{t('menu.about us')}</Link>
							</li>
							<li>
								<Link onClick={() => setToggleMenu(!toggleMenu)} to='/partners'>{t('menu.our sponsers')}</Link>
							</li>
							<li>
								<Link onClick={() => setToggleMenu(!toggleMenu)} to='/faq'>{t('menu.faq')}</Link>
							</li>

							{!user ? (
								<li className="d-lg-none">
									<Link onClick={() => setToggleMenu(!toggleMenu)} to='/login' className='btn px-4 btn-primary btn-md text-capitalize fw-medium m-auto d-block' style={{width: "60%"}}>{t("global.login")}</Link>
								</li>
							) :
								<li className="d-lg-none">
									<Link to='/login' onClick={logoutHandler} className='btn px-4 btn-primary btn-md text-capitalize fw-medium m-auto d-block' style={{width: "60%"}}>  {t("global.logout")}</Link>
								</li>
							}
						</ul>
					</div>

					<div className={`overlay-menu ${toggleMenu ? 'open' : ''}`} onClick={() => setToggleMenu(!toggleMenu)}></div>


					<div className="d-flex flex-row gap-2">
						<div className={`d-flex align-items-center ${!isMobile ? 'gap-4' : 'gap-3'}`}>
							{user && (
								<>
									<Link
										className="d-none d-md-block"
										style={{
											color: "#37a11a",
											textAlign: i18n.language === "ar" ? "right" : "left",

											fontSize: i18n.language === "ar" && "16px",
										}}
										to="/messages"
									>
										<span>{<BiMessageDetail size={23} />}</span>
									</Link>
									<Dropdown>
										<Dropdown.Toggle className="btn btn-primary btn-md text-capitalize fw-medium d-none d-md-block" id="dropdown-basic">
											<span>{<BiUser size={23} />}</span>
										</Dropdown.Toggle>

										<Dropdown.Menu className={`${i18n.language === "ar" ? 'dropdown-menu-right' : 'dropdown-menu-left'}`}>
											<Dropdown.Item>{user.firstName} {user.lastName}</Dropdown.Item>

											<Dropdown.Divider />
											<Dropdown.Item href="/account">{t("global.account")}</Dropdown.Item>
											<Dropdown.Item onClick={logoutHandler} href="/login">{t("global.logout")}</Dropdown.Item>
										</Dropdown.Menu>
									</Dropdown>

								</>
							)}

							{!user ? (
								<Link to='/login' className='btn px-4 btn-primary btn-md text-capitalize fw-medium d-none d-md-block' style={{
									float: i18n.language === "ar" ? "right" : "left",
									fontSize: i18n.language === "ar" && "16px",
								}}>{t("global.login")}</Link>
							) : ''}
							<LanguageSwitcher />
						</div>
						<button className="d-lg-none toggel-menu" onClick={() => setToggleMenu(!toggleMenu)}>
							<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} className="w-6 h-6">
								<path strokeLinecap="round" strokeLinejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
							</svg>
						</button>
					</div>
				</div>
			</div>
			
		</nav>
	);
}
