import React from "react";
import "./Privacy.css";
export default function Privacy() {
  return (
    <div>
      <div className="c11">
        <h1 className="c5" id="h.5lsbqnsh0jx2">
          <span className="c13">Privacy Policy for imateco</span>
        </h1>
        <p className="c1">
          <span className="c2">
            At imateco, accessible from imateco.com, one of our main priorities
            is the privacy of our visitors. This Privacy Policy document
            contains types of information that is collected and recorded by
            imateco and how we use it.
          </span>
        </p>
        <p className="c1">
          <span className="c2">
            If you have additional questions or require more information about
            our Privacy Policy, do not hesitate to contact us.
          </span>
        </p>
        <p className="c1">
          <span className="c2">
            This Privacy Policy applies only to our online activities and is
            valid for visitors to our website with regards to the information
            that they shared and/or collect in imateco. This policy is not
            applicable to any information collected offline or via channels
            other than this website.
          </span>
        </p>
        <h2 className="c5" id="h.soo2uvmd72yr">
          <span className="c3">Consent</span>
        </h2>
        <p className="c1">
          <span className="c2">
            By using our website, you hereby consent to our Privacy Policy and
            agree to its terms.
          </span>
        </p>
        <h2 className="c5" id="h.fpxf0iqv98xf">
          <span className="c3">Information we collect</span>
        </h2>
        <p className="c1">
          <span className="c2">
            The personal information that you are asked to provide, and the
            reasons why you are asked to provide it, will be made clear to you
            at the point we ask you to provide your personal information.
          </span>
        </p>
        <p className="c1">
          <span className="c2">
            If you contact us directly, we may receive additional information
            about you such as your name, email address, phone number, the
            contents of the message and/or attachments you may send us, and any
            other information you may choose to provide.
          </span>
        </p>
        <p className="c1">
          <span className="c2">
            When you register for an Account, we may ask for your contact
            information, including items such as name, company name, address,
            email address, and telephone number.
          </span>
        </p>
        <h2 className="c5" id="h.l87wxhse1hww">
          <span className="c3">How we use your information</span>
        </h2>
        <p className="c1">
          <span className="c2">
            We use the information we collect in various ways, including to:
          </span>
        </p>
        <ul className="c7 lst-kix_e2iq44nex7j7-0 start">
          <li className="c0">
            <span className="c2">
              Provide, operate, and maintain our webste
            </span>
          </li>
          <li className="c0">
            <span className="c2">
              Improve, personalize, and expand our webste
            </span>
          </li>
          <li className="c0">
            <span className="c2">
              Understand and analyze how you use our webste
            </span>
          </li>
          <li className="c0">
            <span className="c2">
              Develop new products, services, features, and functionality
            </span>
          </li>
          <li className="c0">
            <span className="c2">
              Communicate with you, either directly or through one of our
              partners, including for customer service, to provide you with
              updates and other information relating to the webste, and for
              marketing and promotional purposes
            </span>
          </li>
          <li className="c0">
            <span className="c2">Send you emails</span>
          </li>
          <li className="c0">
            <span className="c2">Find and prevent fraud</span>
          </li>
        </ul>
        <h2 className="c5" id="h.5unccexbtvtz">
          <span className="c3">Log Files</span>
        </h2>
        <p className="c1">
          <span className="c9">
            imateco follows a standard procedure of using log files. These files
            log visitors when they visit websites. All hosting companies do this
            and a part of hosting services&#39; analytics. The information
            collected by log files include internet protocol (IP) addresses,
            browser type, Internet Service Provider (ISP), date and time stamp,
            referring/exit pages, and possibly the number of clicks. These are
            not linked to any information that is personally identifiable. The
            purpose of the information is for analyzing trends, administering
            the site, tracking users&#39; movement on the website, and gathering
            demographic information. Our Privacy Policy was created with the
            help of the
          </span>
          <span
          // className="c10"
          >
            <a
              className="c4 
            
              "
              href="https://www.google.com/url?q=https://www.privacypolicygenerator.info/&amp;sa=D&amp;ust=1589918984762000"
            >
              Privacy Policy Generator
            </a>
          </span>
          <span className="c9">&nbsp;and the </span>
          <span className="">
            <a
              className="c4"
              href="https://www.google.com/url?q=https://www.disclaimergenerator.org/&amp;sa=D&amp;ust=1589918984762000"
            >
              Disclaimer Generator
            </a>
          </span>
          <span className="c2">.</span>
        </p>
        <h2 className="c5" id="h.eodqsvxp6dj9">
          <span className="c3">Cookies and Web Beacons</span>
        </h2>
        <p className="c1">
          <span className="c2">
            Like any other website, imateco uses &#39;cookies&#39;. These
            cookies are used to store information including visitors&#39;
            preferences, and the pages on the website that the visitor accessed
            or visited. The information is used to optimize the users&#39;
            experience by customizing our web page content based on
            visitors&#39; browser type and/or other information.
          </span>
        </p>
        <p className="c1">
          <span className="c9">
            For more general information on cookies, please read
          </span>
          <span className="">
            <a
              className="c4"
              href="https://www.google.com/url?q=https://www.cookieconsent.com/what-are-cookies/&amp;sa=D&amp;ust=1589918984763000"
            >
              &quot;What Are Cookies&quot;
            </a>
          </span>
          <span className="c2">.</span>
        </p>
        <h2 className="c5" id="h.6fac4p6ldfoc">
          <span className="c3">Google DoubleClick DART Cookie</span>
        </h2>
        <p className="c1">
          <span className="c9">
            Google is one of a third-party vendor on our site. It also uses
            cookies, known as DART cookies, to serve ads to our site visitors
            based upon their visit to www.website.com and other sites on the
            internet. However, visitors may choose to decline the use of DART
            cookies by visiting the Google ad and content network Privacy Policy
            at the following URL &ndash;
          </span>
          <span className="">
            <a
              className="c4"
              href="https://www.google.com/url?q=https://policies.google.com/technologies/ads&amp;sa=D&amp;ust=1589918984763000"
            >
              https://policies.google.com/technologies/ads
            </a>
          </span>
        </p>
        <h2 className="c5" id="h.hb5r1d7xqvx4">
          <span className="c3">Our Advertising Partners</span>
        </h2>
        <p className="c1">
          <span className="c2">
            Some of advertisers on our site may use cookies and web beacons. Our
            advertising partners are listed below. Each of our advertising
            partners has their own Privacy Policy for their policies on user
            data. For easier access, we hyperlinked to their Privacy Policies
            below.
          </span>
        </p>
        <ul className="c7 lst-kix_yp2zjf1tqt42-0 start">
          <li className="c0">
            <span className="c9">
              Google
              <br />
            </span>
            <span className="" style={{ overflowWrap: "break-word" }}>
              <a
                className="c4"
                href="https://www.google.com/url?q=https://policies.google.com/technologies/ads&amp;sa=D&amp;ust=1589918984764000"
              >
                https://policies.google.com/technologies/ads
              </a>
            </span>
          </li>
        </ul>
        <h2 className="c5" id="h.hkhaakprxk8n">
          <span className="c3">Advertising Partners Privacy Policies</span>
        </h2>
        <p className="c1">
          <span className="c2">
            You may consult this list to find the Privacy Policy for each of the
            advertising partners of imateco.
          </span>
        </p>
        <p className="c1">
          <span className="c2">
            Third-party ad servers or ad networks uses technologies like
            cookies, JavaScript, or Web Beacons that are used in their
            respective advertisements and links that appear on imateco, which
            are sent directly to users&#39; browser. They automatically receive
            your IP address when this occurs. These technologies are used to
            measure the effectiveness of their advertising campaigns and/or to
            personalize the advertising content that you see on websites that
            you visit.
          </span>
        </p>
        <p className="c1">
          <span className="c2">
            Note that imateco has no access to or control over these cookies
            that are used by third-party advertisers.
          </span>
        </p>
        <h2 className="c5" id="h.yesbc37ysqv6">
          <span className="c3">Third Party Privacy Policies</span>
        </h2>
        <p className="c1">
          <span className="c2">
            imateco&#39;s Privacy Policy does not apply to other advertisers or
            websites. Thus, we are advising you to consult the respective
            Privacy Policies of these third-party ad servers for more detailed
            information. It may include their practices and instructions about
            how to opt-out of certain options.
          </span>
        </p>
        <p className="c1">
          <span className="c2">
            You can choose to disable cookies through your individual browser
            options. To know more detailed information about cookie management
            with specific web browsers, it can be found at the browsers&#39;
            respective websites.
          </span>
        </p>
        <h2 className="c5" id="h.x597cjgrt0pg">
          <span className="c3">
            CCPA Privacy Rights (Do Not Sell My Personal Information)
          </span>
        </h2>
        <p className="c1">
          <span className="c2">
            Under the CCPA, among other rights, California consumers have the
            right to:
          </span>
        </p>
        <p className="c1">
          <span className="c2">
            Request that a business that collects a consumer&#39;s personal data
            disclose the categories and specific pieces of personal data that a
            business has collected about consumers.
          </span>
        </p>
        <p className="c1">
          <span className="c2">
            Request that a business delete any personal data about the consumer
            that a business has collected.
          </span>
        </p>
        <p className="c1">
          <span className="c2">
            Request that a business that sells a consumer&#39;s personal data,
            not sell the consumer&#39;s personal data.
          </span>
        </p>
        <p className="c1">
          <span className="c2">
            If you make a request, we have one month to respond to you. If you
            would like to exercise any of these rights, please contact us.
          </span>
        </p>
        <h2 className="c5" id="h.vlb609syof1x">
          <span className="c3">GDPR Data Protection Rights</span>
        </h2>
        <p className="c1">
          <span className="c2">
            We would like to make sure you are fully aware of all of your data
            protection rights. Every user is entitled to the following:
          </span>
        </p>
        <p className="c1">
          <span className="c2">
            The right to access &ndash; You have the right to request copies of
            your personal data. We may charge you a small fee for this service.
          </span>
        </p>
        <p className="c1">
          <span className="c2">
            The right to rectification &ndash; You have the right to request
            that we correct any information you believe is inaccurate. You also
            have the right to request that we complete the information you
            believe is incomplete.
          </span>
        </p>
        <p className="c1">
          <span className="c2">
            The right to erasure &ndash; You have the right to request that we
            erase your personal data, under certain conditions.
          </span>
        </p>
        <p className="c1">
          <span className="c2">
            The right to restrict processing &ndash; You have the right to
            request that we restrict the processing of your personal data, under
            certain conditions.
          </span>
        </p>
        <p className="c1">
          <span className="c2">
            The right to object to processing &ndash; You have the right to
            object to our processing of your personal data, under certain
            conditions.
          </span>
        </p>
        <p className="c1">
          <span className="c2">
            The right to data portability &ndash; You have the right to request
            that we transfer the data that we have collected to another
            organization, or directly to you, under certain conditions.
          </span>
        </p>
        <p className="c1">
          <span className="c2">
            If you make a request, we have one month to respond to you. If you
            would like to exercise any of these rights, please contact us.
          </span>
        </p>
        <h2 className="c5" id="h.l60f7b2zh3h9">
          <span className="c3">Children&#39;s Information</span>
        </h2>
        <p className="c1">
          <span className="c2">
            Another part of our priority is adding protection for children while
            using the internet. We encourage parents and guardians to observe,
            participate in, and/or monitor and guide their online activity.
          </span>
        </p>
        <p className="c1">
          <span className="c2">
            imateco does not knowingly collect any Personal Identifiable
            Information from children under the age of 13. If you think that
            your child provided this kind of information on our website, we
            strongly encourage you to contact us immediately and we will do our
            best efforts to promptly remove such information from our records.
          </span>
        </p>
        <p className="c12">
          <span className="c6"></span>
        </p>
      </div>
    </div>
  );
}
