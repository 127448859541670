// Description: This component is used to edit the personal information of the user first name, last name, country, city, avatar, and activated status
import React, { useEffect, useState } from "react";

import { data } from "../../assets/data";
import Loader from "../Loader/Loader";
import {
  Card,
  CardContent,
  TextField,
  Button,
  FormGroup,
  FormControlLabel,
  Divider,
  Switch,
  ToggleButton,
  ToggleButtonGroup,
  MenuItem,
  Select,
} from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { Link, useNavigate } from "react-router-dom";
import "./EditPersonal.css";
import { useTranslation } from "react-i18next";
import { useUser } from "../../hooks/auth";
import { useUpdateUserProfile } from "../../hooks/user";
export default function EditPersonal() {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  const { isLoading: loading, data: user } = useUser();
  const {
    isLoading: updateLoading,
    error: updateError,
    message: updateMessage,
    mutate: update,
  } = useUpdateUserProfile();

  const [firstName, setFirstName] = useState(user?.firstName);
  const [lastName, setLastName] = useState(user?.lastName);
  const [country, setCountry] = useState(user?.country);
  const [city, setCity] = useState(user?.city);
  const [avatar, setAvatar] = useState(
    user?.avatar?.url || user?.avatarLink || ""
  );
  const [activated, setActivated] = useState(user?.activated);
  const [avatarPrev, setAvatarPrev] = useState(
    user?.avatar?.url || user?.avatarLink || ""
  );
  const availableCities = data.countries.find((c) => c.name === country);
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    const Reader = new FileReader();
    Reader.readAsDataURL(file);
    Reader.onload = () => {
      if (Reader.readyState === 2) {
        setAvatarPrev(Reader.result);
        setAvatar(Reader.result);
      }
    };
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    update({
      ...user,
      firstName,
      lastName,
      country,
      city,
      avatar,
      activated,
    });

    navigate("/account");
  };

  return loading || updateLoading ? (
    <Loader />
  ) : (
    <div
      className="userCard"
      style={{ direction: i18n.language === "ar" ? "rtl" : "ltr" }}
    >
      <br />
      <Card className="p-0">
        <div className="page-card-header d-flex justify-content-between">
          <span
            className="flex-1 title-edit-personal"
            style={{
              textAlign: i18n.language === "ar" ? "right" : "left",
              paddingLeft: "15px",
              paddingRight: "15px",
            }}
          >
            {t("profile.editPersonal.title")}
          </span>
        </div>
        <CardContent style={{ padding: "15px" }}>
          <form onSubmit={submitHandler}>
            {/* <TextField
              className="edit-profile-field-total"
              variant="outlined"
              label={t("profile.editPersonal.field_firstName")}
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
            />
            <br />
            <br />
            <br />

            <TextField
              className="edit-profile-field-total"
              variant="outlined"
              label={t("profile.editPersonal.field_lastName")}
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
            />
            <br />
            <br />
            <br /> */}

            <Select
              label={null}
              className="edit-profile-field-total"
              value={country}
              onChange={(e) => setCountry(e.target.value)}
            >
              <MenuItem value="">---</MenuItem>

              {data.countries.map((value, key) => {
                return (
                  <MenuItem value={value.name} key={key}>
                    {value.name}
                  </MenuItem>
                );
              })}
            </Select>
            <br />
            <br />
            <br />

            <Select
              label={null}
              className="edit-profile-field-total"
              value={city}
              onChange={(e) => setCity(e.target.value)}
            >
              <MenuItem value="">---</MenuItem>

              {availableCities?.cities.map((value, key) => {
                return (
                  <MenuItem value={value} key={key}>
                    {value}
                  </MenuItem>
                );
              })}
            </Select>
            <br />
            <br />
            <Divider />
            <br />
            <div className="form-group position-relative">
              <label
                style={{
                  display: "flex",
                  flexDirection:
                    i18n.language === "ar" ? "flex-end" : "flex-start",
                }}
              >
                {t("signup.image")}
              </label>

              <div className="row">
                <div className="col-12">
                  <input
                    className="form-control"
                    type="file"
                    accept="image/*"
                    onChange={handleImageChange}
                  />
                </div>
              </div>
            </div>
            <br />
            <Divider />
            <br />
            <div
              style={{
                display: "flex",
                flexDirection:
                  i18n.language === "ar" ? "flex-end" : "flex-start",
              }}
            >
              <Link to="/forgot/password">
                {t("profile.editPersonal.change_password")}
              </Link>
            </div>
            <br />
            <Divider />
            <br />
            <div className="w-100 justify-content-center">
              {/* <Switch
                //value={isAvailable}
                checked={activated}
                onChange={(e) => setActivated(e.target.checked)}
              /> */}
              <p
                style={{
                  textAlign: i18n.language === "ar" ? "right" : "left",
                  paddingBottom: "15px",
                  paddingLeft: "15px",
                  paddingRight: "15px",
                }}
              >
                {t("profile.editPersonal.activated")}
              </p>
              <div
                className="d-flex"
                style={{
                  justifyContent: "center",
                  paddingLeft: "15px",
                  paddingRight: "15px",
                }}
              >
                <ToggleButtonGroup
                  sx={{
                    direction: "ltr",
                  }}
                  style={{ minWidth: "90%" }}
                  value={activated}
                  exclusive
                  onChange={(e, newValue) => setActivated(newValue)}
                >
                  <ToggleButton style={{ minWidth: "50%" }} value={true}>
                    {t("profile.expertise.yes")}
                  </ToggleButton>
                  <ToggleButton style={{ minWidth: "50%" }} value={false}>
                    {t("profile.expertise.no")}
                  </ToggleButton>
                </ToggleButtonGroup>
              </div>
            </div>
            <br />
            <br />
            <div style={{ textAlign: "center" }}>
              <Button
                className="btn btn-block btn-primary"
                variant="contained"
                style={{ textAlign: "center" }}
                //disabled={loading}
                type="submit"
              >
                {t("profile.editPersonal.save")}
              </Button>
            </div>
            <br />
            <br />
          </form>
        </CardContent>
      </Card>
    </div>
  );
}
