// Description: Header Component
import React, { useState } from "react";
import square from "../../assets/img/namacoders-square.JPG";

import largeleaderboard from "../../assets/img/namacoders-leaderboard.JPG";

export default function Banner() {

	return (
		<>
			<div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 align-content-center justify-content-center m-auto d-md-none d-lg-none d-xl-none">
				<a
					href="https://namacoders.com/ar/%d8%a7%d9%84%d8%b5%d9%81%d8%ad%d8%a9-%d8%a7%d9%84%d8%b1%d8%a6%d9%8a%d8%b3%d9%8a%d8%a9/#contact-us"
					rel="noreferrer"
					target="_blank"
					className="d-flex"
				>
					<img src={square} alt="Nama Coders" className="m-auto square" />
				</a>
			</div>
			<div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 align-content-center justify-content-center pb-2 d-none d-md-block d-lg-block d-xl-block">
				<a
					href="https://namacoders.com/ar/%d8%a7%d9%84%d8%b5%d9%81%d8%ad%d8%a9-%d8%a7%d9%84%d8%b1%d8%a6%d9%8a%d8%b3%d9%8a%d8%a9/#contact-us"
					rel="noreferrer"
					target="_blank"
					className="d-flex"
				>
					<img
						src={largeleaderboard}
						alt="Nama Coders"
						className="m-auto leaderboard"
					/>
				</a>
				<br />
			</div>
		</>
	);
}
