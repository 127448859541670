// sign up page for technical person
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Checkbox, ToggleButton, ToggleButtonGroup } from "@mui/material";
import GitHubIcon from "../../assets/img/github-logo (2).png";
import FormControlLabel from "@mui/material/FormControlLabel";
import userIcon from "../../assets/img/user.svg";
import emailIcon from "../../assets/img/mail.svg";
import keyIcon from "../../assets/img/key.svg";
import linkedInIcon from "../../assets/img/linkedin.svg";
import twitterIcon from "../../assets/img/twitter.svg";

import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
// import { LoginSocialGithub } from "reactjs-social-login";

import { data, technologies } from "../../assets/data";
import PasswordChecklist from "react-password-checklist";
import ConditionToSignUp from "./ConditionToSignUp";

import "./Register.css";
import Loader from "../Loader/Loader";
import { useTranslation } from "react-i18next";
import { Button } from "@mui/material";
import MyGitHubLoginButton from "./MyGitHubLoginButton";

import MyTwitterLoginButton from "./MyTwitterLoginButton";

import { TwitterSignIn, GitHubSignIn } from "../../firebase";
import { useRegister, useSocialRegister, useUserEmail } from "../../hooks/auth";
import Banner from "../Banner/Banner";
function getWindowSize() {
  //get the size of the window
  const { innerWidth, innerHeight } = window;
  return { innerWidth, innerHeight };
}
export default function Register() {
  const {
    data: messageEmail,
    isLoading: loadingEmail,
    error: errorEmail,
    mutate: mutateEmail,
  } = useUserEmail(); //check if the email is already used

  const { mutate: register, isLoading: RegisterLoading } = useRegister(); //register the user using email and password

  const { mutate: SocialRegister, isLoading: SocialLoading } =
    useSocialRegister(); //register the user using social media

  const [checked, setChecked] = useState(false);
  const [checkError, setcheckError] = useState("");
  const [isMobile, setIsMobile] = useState(false);
  const [country, setCountry] = useState("");
  const [city, setCity] = useState("");
  const [yearsExperience, setYearsExperience] = useState("");
  const [startupExperience, setStartupExperience] = useState("");
  const [relocate, setRelocate] = useState("");
  const [commitFulltime, setCommitFulltime] = useState("");
  const [remote, setRemote] = useState("");
  const [equity, setEquity] = useState("");
  const [whenStart, setWhenStart] = useState("");
  const [linkedInProfile, setLinkedInProfile] = useState("");
  const [gitHub, setGitHub] = useState("");
  const [twitter, SetTwitter] = useState("");
  const [tellMore, setTellMore] = useState("");
  const [bestAt, setBestAt] = useState("");
  const [socialUser, setSocialUser] = useState({});
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [socialLogin, setSocialLogin] = useState(false);

  const [email, setEmail] = useState("");
  const [avatar, setAvatar] = useState("");
  const [password, setPassword] = useState("");
  const [passwordAgain, setPasswordAgain] = useState("");
  const availableCities = data.countries.find((c) => c.name === country); //get the cities of the selected country
  const [type, setType] = useState("user");
  useEffect(() => {
    if (messageEmail) {
      //if the email is not used yet then go to the next step
      setIsFirstStep(false);
      setIsSecondStep(true);
      if (isMobile === true) {
        setIsMobile(false);
        setIsSecondStep(true);
      }
    }

    if (errorEmail) {
      setSocialLogin(false);
    }
  }, [messageEmail, errorEmail, isMobile]);
  const [windowSize, setWindowSize] = useState(getWindowSize());
  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []); //get the size of the window

  const handleCheck = (event) => {
    //check if the checkbox is checked
    setChecked(event.target.checked);
    if (event.target.checked === true) {
      setcheckError(false);
    } else if (event.target.checked === false) {
      setcheckError(true);
    }
  };

  const { t, i18n } = useTranslation();

  const [isFirstStep, setIsFirstStep] = useState();
  const [isSecondStep, setIsSecondStep] = useState(false);
  const [isThirdStep, setIsThirdStep] = useState(false);
  const [isFourthStep, setIsFourthStep] = useState(false);

  const handelSlide1to2 = (e) => {
    //go to the next step
    e.preventDefault();
    if (checkError === "") {
      //if the checkbox is not checked then show an error
      setcheckError(true);
    }

    if (checkError === false) {
      //if the checkbox is checked then send the email to the server to check if it is already used
      mutateEmail(email);
    }
  };
  const handelSlide1toIsMobile = () => {
    //go to the next step
    if (checkError === "") {
      //if the checkbox is not checked then show an error
      setcheckError(true);
    }
    if (checkError === false) {
      //if the checkbox is checked then go to the next step
      setIsFirstStep(false);
      setIsMobile(true);
    }
  };
  const handelSlideIsMobileto2 = (e) => {
    //go to the next step
    e.preventDefault();
    if (checkError === "") {
      setcheckError(true);
    }
    if (checkError === false) {
      //if the checkbox is checked then send the email to the server to check if it is already used
      mutateEmail(email);
    }
  };

  const handelSlide2to3 = () => {
    //go to the next step
    window.scrollTo(0, 0);
    setIsSecondStep(false);
    setIsThirdStep(true);
  };
  const handelSlide3to2 = () => {
    window.scrollTo(0, 0);
    setIsThirdStep(false);
    setIsSecondStep(true);
  };
  const handelSlide3to4 = () => {
    window.scrollTo(0, 0);
    setIsThirdStep(false);
    setIsFourthStep(true);
  };

  const handelSlide4to3 = () => {
    window.scrollTo(0, 0);
    setIsFourthStep(false);
    setIsThirdStep(true);
  };

  const handleImageChange = (e) => {
    //upload the image
    const file = e.target.files[0];

    const Reader = new FileReader();
    Reader.readAsDataURL(file);

    Reader.onload = () => {
      if (Reader.readyState === 2) {
        setAvatar(Reader.result);
      }
    };
  };
  const [frontend, setFrontEnd] = useState([]);
  const [devops, setDevops] = useState([]);
  const [backend, setBackend] = useState([]);
  const [design, setDesign] = useState([]);
  const [database, setDatabase] = useState([]);
  const [count, setCount] = useState(0);

  const handelTellMore = (e) => {
    //get the value of the tell more textarea
    setTellMore(e.target.value);
    setCount(e.target.value.length);
  };

  const handelTwitter = async (e) => {
    //sign in with twitter
    if (checkError === false) {
      const user = await TwitterSignIn();
      setSocialUser(user.user.auth);
      let lastName = "-";

      setEmail(user.user.auth.currentUser.email);

      setPassword(user.user.auth.currentUser.uid);

      setFirstName(user.user.auth.currentUser.displayName);
      setLastName(lastName);
      setAvatar(user.user.auth.currentUser.photoURL);

      SetTwitter(user.user.auth.currentUser.reloadUserInfo.screenName);
      mutateEmail(user.user.auth.currentUser.email); //send the email to the server to check if it is already used

      setSocialLogin(true);
    } else {
      setcheckError(true);
    }
  };
  const handelGithub = async (e) => {
    if (checkError === false) {
      const user = await GitHubSignIn();
      setSocialUser(user.user.auth);

      let lastName = "-";

      setEmail(user.user.auth.currentUser.email);

      setPassword(user.user.auth.currentUser.uid);

      setFirstName(user.user.auth.currentUser.displayName);

      setLastName(lastName);
      setAvatar(user.user.auth.currentUser.photoURL);

      setGitHub(user.user.auth.currentUser.reloadUserInfo.screenName);
      mutateEmail(user.user.auth.currentUser.email); //send the email to the server to check if it is already used

      setSocialLogin(true);
    } else {
      setcheckError(true);
    }
  };

  const submitHandler = (e) => {
    e.preventDefault();

    if (socialLogin === true) {
      const user = {
        firstName,
        lastName,
        email,
        password,
        avatar,
        country,
        city,
        yearsExperience,
        startupExperience,
        relocate,
        commitFulltime,
        remote,
        equity,
        whenStart,
        tellMore,
        linkedInProfile,
        gitHub,
        twitter,
        bestAt,
        frontend,
        devops,
        backend,
        design,
        database,
        type,
        socialLogin,
      };
      SocialRegister({ user, socialUser }); //register the user with the social login
    } else {
      const user = {
        firstName,
        lastName,
        email,
        password,
        avatar,
        country,
        city,
        yearsExperience,
        startupExperience,
        relocate,
        commitFulltime,
        remote,
        equity,
        whenStart,
        tellMore,
        linkedInProfile,
        gitHub,
        twitter,
        bestAt,
        frontend,
        devops,
        backend,
        design,
        database,
        type,
        socialLogin,
      };
      register(user); //register the user with the normal login
    }
  };

  useEffect(() => {
    if (socialLogin === true) {
      //if the user is signing up with the social login then go to the next step
      setIsFirstStep(false);
      setIsSecondStep(true);
    } else {
      setIsFirstStep(true);
      setIsSecondStep(false);
    }
  }, [socialLogin, checkError, checked]);

  return RegisterLoading || SocialLoading ? (
    <Loader />
  ) : (
    <div className=" ">
      {loadingEmail && <Loader />}
      <br />
      <ConditionToSignUp />
      <div className="container">
        <section
          className="row bg-home d-flex align-items-center justify-content-center "
          style={{
            paddingTop: "30px",
            direction: i18n.language === "ar" ? "rtl" : "ltr",
          }}
        >
          <Banner />
          <form onSubmit={submitHandler}>
            <div
              //[dir]="(this.storageService.getLanguage()==='ar') ? 'rtl' : 'ltr'"
              className="container mt-sm-5 mt-2"
            >
              <div className=" row justify-content-center align-items-center">
                {isFirstStep === true &&
                  (SocialLoading === true ? (
                    <Loader />
                  ) : (
                    <>
                      <div
                        //[hidden]="!isFirstStep"
                        className="col-lg-10 col-12 mb-5"
                      >
                        <div className="card login-page bg-white shadow rounded border-0">
                          <br />
                          <h4 className=" text-center">{t("signup.title")}</h4>
                          <div class="row  justify-content-between p-3">
                            <div class="col-lg-6 col-12 pr-lg-2 pb-2">
                              <MyTwitterLoginButton onClick={handelTwitter} />
                            </div>

                            <div class="col-lg-6 col-12 pl-lg-2 ">
                              <MyGitHubLoginButton onClick={handelGithub} />
                            </div>
                          </div>
                          <div
                            // onSubmit={submitHandler}
                            className="login-form"
                          //[formGroup]="signupFormEmailGroup"
                          >
                            <div className="row">
                              {windowSize.innerWidth > 700 && (
                                <>
                                  <div
                                    className="col-lg-6 "
                                  // *ngIf="this.innerWidth>=700 || this.signupEmail===true"
                                  >
                                    <div className="form-group position-relative px-3">
                                      <label
                                        style={{
                                          display: "flex",
                                          flexDirection:
                                            i18n.language === "ar"
                                              ? "justify-content-end"
                                              : "justify-content-start",
                                        }}
                                      //[ngStyle]="{'flex-direction':(this.storageService.getLanguage()==='ar') ? 'justify-content-end' : 'justify-content-start' }"
                                      >
                                        {t("signup.field_firstName")}{" "}
                                        <span className="text-danger"> *</span>
                                      </label>
                                      <i
                                        data-feather="user"
                                        className="fea icon-sm icons"
                                      ></i>
                                      <img
                                        src={userIcon}
                                        className="fea icon-sm icons"
                                        alt="user icon"
                                      />
                                      <input
                                        //[ngStyle]="{'border-color':( form1Error && (signupFormEmailGroup.value.firstName == null || signupFormEmailGroup.value.firstName.length<1))   ? 'red' : 'gray' }"
                                        className="form-control pl-5"
                                        placeholder={t("signup.field_firstName")}
                                        required
                                        value={firstName}
                                        onChange={(e) =>
                                          setFirstName(e.target.value)
                                        }
                                      />
                                    </div>
                                  </div>

                                  <div
                                    className="col-lg-6"
                                  //  *ngIf="this.innerWidth>=700 || this.signupEmail===true"
                                  >
                                    <div className="form-group position-relative px-3">
                                      <label
                                        style={{
                                          display: "flex",
                                          flexDirection:
                                            i18n.language === "ar"
                                              ? "justify-content-end"
                                              : "justify-content-start",
                                        }}
                                      //[ngStyle]="{'flex-direction':(this.storageService.getLanguage()==='ar') ? 'justify-content-end' : 'justify-content-start' }"
                                      >
                                        {t("signup.field_lastName")}
                                        <span className="text-danger"> *</span>
                                      </label>
                                      <i
                                        data-feather="user"
                                        className="fea icon-sm icons"
                                      ></i>
                                      <img
                                        src={userIcon}
                                        className="fea icon-sm icons"
                                        alt="user icon"
                                      />
                                      <input
                                        // [ngStyle]="{'border-color':( form1Error && (signupFormEmailGroup.value.lastName == null || signupFormEmailGroup.value.lastName.length<1))   ? 'red' : 'gray' }"
                                        className="form-control pl-5"
                                        placeholder={t("signup.field_lastName")}
                                        //="lastName"
                                        required
                                        value={lastName}
                                        onChange={(e) =>
                                          setLastName(e.target.value)
                                        }
                                      />
                                    </div>
                                  </div>
                                  <div
                                    className="col-lg-6"
                                  // *ngIf="this.innerWidth>=700 || this.signupEmail===true"
                                  >
                                    <div className="form-group position-relative px-3">
                                      <label
                                        style={{
                                          display: "flex",
                                          flexDirection:
                                            i18n.language === "ar"
                                              ? "justify-content-end"
                                              : "justify-content-start",
                                        }}
                                      //[ngStyle]="{'flex-direction':(this.storageService.getLanguage()==='ar') ? 'justify-content-end' : 'justify-content-start' }"
                                      >
                                        {t("signup.email")}{" "}
                                        <span className="text-danger"> *</span>
                                        {errorEmail && (
                                          <span
                                            className="text-danger"
                                            style={{
                                              textAlign:
                                                i18n.language === "ar"
                                                  ? "right"
                                                  : "left",
                                            }}
                                          >
                                            {t("signup.user_exist")}
                                          </span>
                                        )}
                                      </label>
                                      <i
                                        data-feather="user"
                                        className="fea icon-sm icons"
                                      ></i>
                                      <img
                                        src={emailIcon}
                                        className="fea icon-sm icons"
                                        alt="email icon"
                                      />
                                      <input
                                        type="email"
                                        // [ngStyle]="{'border-color':( form1Error && (signupFormEmailGroup.value.email == null || signupFormEmailGroup.value.email.length<1))   ? 'red' : 'gray' }"
                                        className="form-control pl-5"
                                        //="email"
                                        placeholder={t("signup.email")}
                                        name="email"
                                        required
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                      />
                                    </div>
                                  </div>
                                  <div
                                    className="col-lg-6"
                                  //*ngIf="this.innerWidth>=700 || this.signupEmail===true"
                                  >
                                    <div className="form-group position-relative px-3">
                                      <label
                                        style={{
                                          display: "flex",
                                          flexDirection:
                                            i18n.language === "ar"
                                              ? "justify-content-end"
                                              : "justify-content-start",
                                        }}
                                      //[ngStyle]="{'flex-direction':(this.storageService.getLanguage()==='ar') ? 'justify-content-end' : 'justify-content-start' }"
                                      >
                                        {t("signup.image")}
                                      </label>

                                      <div className="row">
                                        <div className="col-10">
                                          <input
                                            className="form-control "
                                            type="file"
                                            accept="image/*"
                                            onChange={handleImageChange}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </>
                              )}
                              {windowSize.innerWidth < 700 ? (
                                <div className="px-3">
                                  <div
                                    // *ngIf="this.innerWidth>=700 || this.signupEmail===true"
                                    className="col-12 col-lg-6 pb-3"
                                    style={{ paddingLeft: "1.3rem", paddingRight: "1.3rem" }}
                                  >
                                    <Button
                                      onClick={handelSlide1toIsMobile}
                                      //(click)="next()" [disabled]=" (this.uploadPourcentage>0 && this.uploadPourcentage<100) "
                                      className="btn btn-primary btn-block"
                                    >
                                      {t("signup.button_signup")}
                                    </Button>
                                  </div>
                                  <br />
                                  <div className="col-12 col-lg-6 mb-1">
                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          required
                                          sx={{
                                            color:
                                              checkError === true &&
                                              "#e43f52",
                                          }}
                                          checked={checked}
                                          onChange={handleCheck}
                                          style={{
                                            textAlign:
                                              i18n.language === "ar"
                                                ? "right"
                                                : "left",

                                          }}
                                          color="success"
                                        />
                                      }
                                      label={
                                        <>
                                          <p
                                            style={{
                                              textAlign:
                                                i18n.language === "ar"
                                                  ? "right"
                                                  : "left",
                                              color:
                                                checkError === true && "#e43f52",

                                            }}
                                          >
                                            {t("signup.understand")}{" "}
                                            <Link
                                              style={{
                                                textDecoration: "none",
                                                color: " #37A11A",
                                              }}
                                              to="/terms"
                                            >
                                              <strong>
                                                {t("signup.userAgreement")}{" "}
                                              </strong>
                                            </Link>
                                            {t("signup.and")}{" "}
                                            <Link
                                              style={{
                                                textDecoration: "none",
                                                color: " #37A11A",
                                              }}
                                              to="/privacy"
                                            >
                                              <strong>
                                                {t("signup.privacyPolicy")}
                                              </strong>
                                            </Link>
                                          </p>
                                        </>
                                      }
                                    />
                                  </div>
                                </div>
                              ) : (
                                <>
                                  <div className="col-sm-5 col-md-12 col-lg-6 mb-1">
                                    <FormControlLabel
                                      className="px-3"
                                      control={
                                        <Checkbox
                                          required
                                          checked={checked}
                                          onChange={handleCheck}
                                          style={{
                                            textAlign:
                                              i18n.language === "ar"
                                                ? "right"
                                                : "left",
                                          }}
                                          color="success"
                                        />
                                      }
                                      label={
                                        <>
                                          <p
                                            style={{
                                              textAlign:
                                                i18n.language === "ar"
                                                  ? "right"
                                                  : "left",
                                              color:
                                                checkError === true && "#e43f52",
                                              border:
                                                checkError === true &&
                                                "2px solid #e43f52",
                                            }}
                                          >
                                            {t("signup.understand")}{" "}
                                            <Link
                                              style={{
                                                textDecoration: "none",
                                                color: " #37A11A",
                                              }}
                                              to="/terms"
                                            >
                                              <strong>
                                                {t("signup.userAgreement")}{" "}
                                              </strong>
                                            </Link>
                                            {t("signup.and")}{" "}
                                            <Link
                                              style={{
                                                textDecoration: "none",
                                                color: " #37A11A",
                                              }}
                                              to="/privacy"
                                            >
                                              <strong>
                                                {t("signup.privacyPolicy")}
                                              </strong>
                                            </Link>
                                          </p>
                                        </>
                                      }
                                    />
                                  </div>
                                  <div
                                    // *ngIf="this.innerWidth>=700 || this.signupEmail===true"
                                    className="col-12 col-md-10 mx-md-5 mx-lg-4 px-sm-1 col-lg-5 pt-lg-2 pt-md-0 pt-sm-0 pb-4"
                                  >
                                    <button
                                      onClick={(e) => handelSlide1to2(e)}
                                      variant="contained"
                                      // style={{ width: "27rem" }}
                                      //(click)="next()" [disabled]=" (this.uploadPourcentage>0 && this.uploadPourcentage<100) "
                                      className="btn btn-primary btn-block"
                                    >
                                      {t("signup.button_signup")}
                                    </button>
                                  </div>
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  ))}
                {isMobile === true && (
                  <div
                    //[hidden]="!isFirstStep"
                    className="col-lg-10 col-12 mb-5"
                  >
                    <div className="card login-page bg-white shadow rounded border-0">
                      <h4 className=" text-center">{t("signup.title")}</h4>

                      <div
                        // onSubmit={submitHandler}
                        className="login-form mt-4"
                      //[formGroup]="signupFormEmailGroup"
                      >
                        <div className="row">
                          {windowSize.innerWidth < 700 && (
                            <>
                              <div
                                className="col-lg-6"
                              // *ngIf="this.innerWidth>=700 || this.signupEmail===true"
                              >
                                <div className="form-group position-relative px-3">
                                  <label
                                    style={{
                                      display: "flex",
                                      flexDirection:
                                        i18n.language === "ar"
                                          ? "justify-content-end"
                                          : "justify-content-start",
                                    }}
                                  //[ngStyle]="{'flex-direction':(this.storageService.getLanguage()==='ar') ? 'justify-content-end' : 'justify-content-start' }"
                                  >
                                    {t("signup.field_firstName")}
                                    <span className="text-danger"> *</span>
                                  </label>
                                  <i
                                    data-feather="user"
                                    className="fea icon-sm icons"
                                  ></i>
                                  <img
                                    src={userIcon}
                                    className="fea icon-sm icons"
                                    alt="user icon"
                                  />
                                  <input
                                    //[ngStyle]="{'border-color':( form1Error && (signupFormEmailGroup.value.firstName == null || signupFormEmailGroup.value.firstName.length<1))   ? 'red' : 'gray' }"
                                    className="form-control pl-5"
                                    placeholder={t("signup.field_firstName")}
                                    required
                                    value={firstName}
                                    onChange={(e) => setFirstName(e.target.value)}
                                  />
                                </div>
                              </div>

                              <div
                                className="col-lg-6"
                              //  *ngIf="this.innerWidth>=700 || this.signupEmail===true"
                              >
                                <div className="form-group position-relative px-3">
                                  <label
                                    style={{
                                      display: "flex",
                                      flexDirection:
                                        i18n.language === "ar"
                                          ? "justify-content-end"
                                          : "justify-content-start",
                                    }}
                                  //[ngStyle]="{'flex-direction':(this.storageService.getLanguage()==='ar') ? 'justify-content-end' : 'justify-content-start' }"
                                  >
                                    {t("signup.field_lastName")}
                                    <span className="text-danger"> *</span>
                                  </label>
                                  <i
                                    data-feather="user"
                                    className="fea icon-sm icons"
                                  ></i>
                                  <img
                                    src={userIcon}
                                    className="fea icon-sm icons"
                                    alt="user icon"
                                  />
                                  <input
                                    // [ngStyle]="{'border-color':( form1Error && (signupFormEmailGroup.value.lastName == null || signupFormEmailGroup.value.lastName.length<1))   ? 'red' : 'gray' }"
                                    className="form-control pl-5"
                                    placeholder={t("signup.field_lastName")}
                                    //="lastName"
                                    required
                                    value={lastName}
                                    onChange={(e) => setLastName(e.target.value)}
                                  />
                                </div>
                              </div>
                              <div
                                className="col-lg-6"
                              // *ngIf="this.innerWidth>=700 || this.signupEmail===true"
                              >
                                <div className="form-group position-relative px-3">
                                  <label
                                    style={{
                                      display: "flex",
                                      flexDirection:
                                        i18n.language === "ar"
                                          ? "justify-content-end"
                                          : "justify-content-start",
                                    }}
                                  //[ngStyle]="{'flex-direction':(this.storageService.getLanguage()==='ar') ? 'justify-content-end' : 'justify-content-start' }"
                                  >
                                    {t("signup.email")}{" "}
                                    <span className="text-danger"> *</span>
                                    {errorEmail && (
                                      <span
                                        className="text-danger"
                                        style={{
                                          textAlign:
                                            i18n.language === "ar"
                                              ? "right"
                                              : "left",
                                        }}
                                      >
                                        {t("signup.user_exist")}
                                      </span>
                                    )}
                                  </label>
                                  <i
                                    data-feather="user"
                                    className="fea icon-sm icons"
                                  ></i>
                                  <img
                                    src={emailIcon}
                                    className="fea icon-sm icons"
                                    alt="email icon"
                                  />
                                  <input
                                    type="email"
                                    // [ngStyle]="{'border-color':( form1Error && (signupFormEmailGroup.value.email == null || signupFormEmailGroup.value.email.length<1))   ? 'red' : 'gray' }"
                                    className="form-control pl-5"
                                    //="email"
                                    placeholder={t("signup.email")}
                                    name="email"
                                    required
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                  />
                                </div>
                              </div>
                              <div
                                className="col-lg-6"
                              //*ngIf="this.innerWidth>=700 || this.signupEmail===true"
                              >
                                <div className="form-group position-relative px-3 ">
                                  <label
                                    style={{
                                      display: "flex",
                                      flexDirection:
                                        i18n.language === "ar"
                                          ? "justify-content-end"
                                          : "justify-content-start",
                                    }}
                                  //[ngStyle]="{'flex-direction':(this.storageService.getLanguage()==='ar') ? 'justify-content-end' : 'justify-content-start' }"
                                  >
                                    {t("signup.image")}
                                  </label>

                                  <div className="row">
                                    <div className="col-10">
                                      <input
                                        className="form-control "
                                        type="file"
                                        accept="image/*"
                                        onChange={handleImageChange}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </>
                          )}
                          {windowSize.innerWidth < 700 ? (
                            <div className="px-3">
                              <div
                                // *ngIf="this.innerWidth>=700 || this.signupEmail===true"
                                className=" col-12 col-lg-6 pb-3 "
                                style={{ paddingLeft: "1.3rem", paddingRight: "1.3rem" }}
                              >
                                <Button
                                  onClick={(e) => handelSlideIsMobileto2(e)}
                                  //(click)="next()" [disabled]=" (this.uploadPourcentage>0 && this.uploadPourcentage<100) "
                                  className="btn btn-primary btn-block"
                                >
                                  {t("signup.button_signup")}
                                </Button>
                              </div>
                              <br />
                              <div className="col-12 col-lg-6 mb-1 px-3">
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      required
                                      checked={checked}
                                      onChange={handleCheck}
                                      style={{
                                        textAlign:
                                          i18n.language === "ar"
                                            ? "right"
                                            : "left",
                                      }}
                                      color="success"
                                    />
                                  }
                                  label={
                                    <>
                                      <p
                                        style={{
                                          textAlign:
                                            i18n.language === "ar"
                                              ? "right"
                                              : "left",
                                          color: checkError === true && "#e43f52",
                                          border:
                                            checkError === true &&
                                            "2px solid #e43f52",
                                        }}
                                      >
                                        {t("signup.understand")}{" "}
                                        <Link
                                          style={{
                                            textDecoration: "none",
                                            color: " #37A11A",
                                          }}
                                          to="/terms"
                                        >
                                          <strong>
                                            {t("signup.userAgreement")}{" "}
                                          </strong>
                                        </Link>
                                        {t("signup.and")}{" "}
                                        <Link
                                          style={{
                                            textDecoration: "none",
                                            color: " #37A11A",
                                          }}
                                          to="/privacy"
                                        >
                                          <strong>
                                            {t("signup.privacyPolicy")}
                                          </strong>
                                        </Link>
                                      </p>
                                    </>
                                  }
                                />
                              </div>
                            </div>
                          ) : (
                            <>
                              <div className="col-12 col-lg-6 mb-1">
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      required
                                      checked={checked}
                                      onChange={handleCheck}
                                      style={{
                                        textAlign:
                                          i18n.language === "ar"
                                            ? "right"
                                            : "left",
                                      }}
                                      color="success"
                                    />
                                  }
                                  label={
                                    <>
                                      <p
                                        style={{
                                          textAlign:
                                            i18n.language === "ar"
                                              ? "right"
                                              : "left",
                                          color: checkError === true && "#e43f52",
                                          border:
                                            checkError === true &&
                                            "2px solid #e43f52",
                                        }}
                                      >
                                        {t("signup.understand")}{" "}
                                        <Link
                                          style={{
                                            textDecoration: "none",
                                            color: " #37A11A",
                                          }}
                                          to="/terms"
                                        >
                                          <strong>
                                            {t("signup.userAgreement")}{" "}
                                          </strong>
                                        </Link>
                                        {t("signup.and")}{" "}
                                        <Link
                                          style={{
                                            textDecoration: "none",
                                            color: " #37A11A",
                                          }}
                                          to="/privacy"
                                        >
                                          <strong>
                                            {t("signup.privacyPolicy")}
                                          </strong>
                                        </Link>
                                      </p>
                                    </>
                                  }
                                />
                              </div>
                              <div
                                // *ngIf="this.innerWidth>=700 || this.signupEmail===true"
                                className="col-12 col-lg-6 ml-5"
                              >
                                <Button
                                  onClick={(e) => handelSlide1to2(e)}
                                  //(click)="next()" [disabled]=" (this.uploadPourcentage>0 && this.uploadPourcentage<100) "
                                  className="btn btn-primary btn-block"
                                >
                                  {t("signup.button_signup")}
                                </Button>
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <div className=" row justify-content-center align-items-center">
                {isSecondStep === true && (
                  <div
                    // [hidden]="!isSecondStep "
                    className="col-lg-5 col-md-6 mb-5 "
                  >
                    <div className="card login-page bg-white shadow rounded border-0">
                      <div className="card-body">
                        <h5 className="card-title text-center">
                          {t("signup.title2")}
                        </h5>
                        <h6 className="card-title text-center">
                          <b>{email}</b>
                        </h6>
                        <div
                          className="login-form mt-4"
                        //[formGroup]="signupFormGeralGroup"
                        >
                          <div className="row">
                            {socialLogin === true ? null : (
                              <>
                                <div
                                  className="col-lg-12"
                                // *ngIf="oathSignup==false"
                                >
                                  <div className="form-group position-relative toolPassword ">
                                    <label
                                      style={{
                                        display: "flex",
                                        flexDirection:
                                          i18n.language === "ar"
                                            ? "justify-content-end"
                                            : "justify-content-start",
                                      }}
                                    //[ngStyle]="{'flex-direction':(this.storageService.getLanguage()==='ar') ? 'justify-content-end' : 'justify-content-start' }"
                                    >
                                      {t("signup.field_password")}
                                      <span className="text-danger"> *</span>
                                    </label>
                                    <img
                                      src={keyIcon}
                                      className="fea icon-sm icons"
                                      alt="password icon"
                                    />
                                    <input
                                      type="password"
                                      className="form-control pl-5"
                                      //="password"
                                      //[ngStyle]="{'border-color':( form2Error && (signupFormGeralGroup.value.password == null || signupFormGeralGroup.value.password.length<6))   ? 'red' : 'gray' }"
                                      placeholder={t("signup.field_password")}
                                      //="password"
                                      required
                                      value={password}
                                      onChange={(e) =>
                                        setPassword(e.target.value)
                                      }
                                    />
                                  </div>
                                </div>

                                <div
                                  className="col-lg-12"
                                // *ngIf="oathSignup==false"
                                >
                                  <div className="form-group position-relative toolPassword">
                                    <label
                                      style={{
                                        display: "flex",
                                        flexDirection:
                                          i18n.language === "ar"
                                            ? "justify-content-end"
                                            : "justify-content-start",
                                      }}
                                    // [ngStyle]="{'flex-direction':(this.storageService.getLanguage()==='ar') ? 'justify-content-end' : 'justify-content-start' }"
                                    >
                                      {t("signup.password-control")}
                                      <span className="text-danger"> *</span>
                                    </label>
                                    <img
                                      src={keyIcon}
                                      className="fea icon-sm icons"
                                      alt="password icon"
                                    />
                                    <input
                                      type="password"
                                      className="form-control pl-5"
                                      //="password"
                                      placeholder={t("signup.password-control")}
                                      //="confirmPassword"
                                      required
                                      value={passwordAgain}
                                      onChange={(e) =>
                                        setPasswordAgain(e.target.value)
                                      }
                                    //[ngStyle]="{'border-color':( form2Error && (signupFormGeralGroup.value.confirmPassword == null || signupFormGeralGroup.value.confirmPassword.length<6))   ? 'red' : 'gray' }"
                                    />
                                  </div>
                                  {i18n.language === "ar" ? (
                                    <PasswordChecklist
                                      rules={["minLength", "match"]}
                                      minLength={6}
                                      value={password}
                                      valueAgain={passwordAgain}
                                      messages={{
                                        minLength: "6 أحرف على الأقل",

                                        match: "يجب ان تتطابق كلمات المرور",
                                      }}
                                    />
                                  ) : (
                                    <PasswordChecklist
                                      rules={["minLength", "match"]}
                                      minLength={6}
                                      value={password}
                                      valueAgain={passwordAgain}
                                      messages={{
                                        minLength: "At least 6 characters",

                                        match: "Password must match",
                                      }}
                                    />
                                  )}
                                </div>
                              </>
                            )}

                            <div className="col-lg-12">
                              <div className="form-group position-relative ">
                                <label
                                  style={{
                                    display: "flex",
                                    flexDirection:
                                      i18n.language === "ar"
                                        ? "justify-content-end"
                                        : "justify-content-start",
                                  }}
                                //[ngStyle]="{'flex-direction':(this.storageService.getLanguage()==='ar') ? 'justify-content-end' : 'justify-content-start' }"
                                >
                                  {t("signup.field_country")}
                                  <span className="text-danger"> *</span>
                                </label>
                                <select
                                  value={country}
                                  onChange={(e) => setCountry(e.target.value)}
                                  className="form-control custom-select"
                                //#mySelectm(change)='loadCities(mySelect.value)'
                                //="country"
                                //[ngStyle]="{'border-color':( form2Error && signupFormGeralGroup.value.country == null )   ? 'red' : 'gray' }"
                                >
                                  <option
                                    value=""
                                  //[value]="" [disabled]="true"  [selected]="true"
                                  >
                                    ---
                                  </option>

                                  {data.countries.map((value, key) => {
                                    return (
                                      <option value={value.name} key={key}>
                                        {value.name}
                                      </option>
                                    );
                                  })}
                                </select>
                              </div>
                            </div>
                            <div className="col-lg-12">
                              <div className="form-group position-relative ">
                                <label
                                  style={{
                                    display: "flex",
                                    flexDirection:
                                      i18n.language === "ar"
                                        ? "justify-content-end"
                                        : "justify-content-start",
                                  }}
                                // [ngStyle]="{'flex-direction':(this.storageService.getLanguage()==='ar') ? 'justify-content-end' : 'justify-content-start' }"
                                >
                                  {t("signup.field_city")}
                                  <span className="text-danger"> *</span>
                                </label>
                                <select
                                  className="form-control custom-select"
                                  //="city"
                                  value={city}
                                  onChange={(e) => setCity(e.target.value)}
                                // [ngStyle]="{'border-color':( form2Error && signupFormGeralGroup.value.city == null )   ? 'red' : 'gray' }"
                                >
                                  <option
                                    // *ngFor="let city of cities" [value]="city.name"
                                    value=""
                                  >
                                    --
                                  </option>
                                  {availableCities?.cities.map((e, key) => {
                                    return (
                                      <option value={e} key={key}>
                                        {e}
                                      </option>
                                    );
                                  })}
                                </select>
                              </div>
                            </div>
                            <div className="col-lg-12" dir="ltr">
                              <label>
                                <b>LinkedIn</b>
                                <span className="text-danger"> *</span>
                              </label>
                              <div className="input-group mb-3">
                                <div className="input-group-prepend">
                                  <span
                                    className="input-group-text prepend-text"
                                    id="basic-addon3"
                                  >
                                    <img
                                      src={linkedInIcon}
                                      className="fea icon-sm icons mr-1"
                                      alt="linkedin icon"
                                    />{" "}
                                    https://www.linkedin.com/in/
                                  </span>
                                </div>
                                <input
                                  //="linkedInProfile"
                                  type="text"
                                  //[ngStyle]="{'border-color':( form2Error &&(signupFormGeralGroup.value.linkedInProfile == null || signupFormGeralGroup.value.linkedInProfile == '') )   ? 'red' : 'gray' }"
                                  className="form-control"
                                  // id="basic-url"
                                  aria-describedby="basic-addon3"
                                  value={linkedInProfile}
                                  onChange={(e) =>
                                    setLinkedInProfile(e.target.value)
                                  }
                                />
                              </div>
                            </div>
                            <div className="col-lg-12" dir="ltr">
                              <label>
                                <b>github</b>
                                <span className="text-danger"> *</span>
                              </label>
                              <div className="input-group mb-3">
                                <div className="input-group-prepend">
                                  <span
                                    className="input-group-text prepend-text"
                                    id="basic-addon3"
                                  >
                                    <img
                                      src={GitHubIcon}
                                      className="fea icon-sm icons mr-1"
                                      alt="linkedin icon"
                                    />{" "}
                                    https://github.com/
                                  </span>
                                </div>
                                <input
                                  //="linkedInProfile"
                                  type="text"
                                  //[ngStyle]="{'border-color':( form2Error &&(signupFormGeralGroup.value.linkedInProfile == null || signupFormGeralGroup.value.linkedInProfile == '') )   ? 'red' : 'gray' }"
                                  className="form-control"
                                  // id="basic-url"
                                  aria-describedby="basic-addon3"
                                  value={gitHub}
                                  onChange={(e) => setGitHub(e.target.value)}
                                />
                              </div>
                            </div>
                            <div
                              className="col-lg-12"
                              dir="ltr"
                            //*ngIf="this.twitterLog===false"
                            >
                              <label>
                                <b>Twitter</b>
                                <span className="text-danger"> *</span>
                              </label>
                              <div className="input-group mb-3">
                                <div className="input-group-prepend">
                                  <span
                                    className="input-group-text prepend-text"
                                    id="basic-addon3"
                                  >
                                    <img
                                      src={twitterIcon}
                                      className="fea icon-sm icons mr-1"
                                      alt="twitter icon"
                                    />{" "}
                                    https://twitter.com/
                                  </span>
                                </div>
                                <input
                                  //="twitter"
                                  type="text"
                                  className="form-control"
                                  //id="basic-url"
                                  aria-describedby="basic-addon3"
                                  value={twitter}
                                  onChange={(e) => SetTwitter(e.target.value)}
                                // [ngStyle]="{'border-color':( form2Error &&(signupFormGeralGroup.value.twitter == null || signupFormGeralGroup.value.twitter == '') )   ? 'red' : 'gray' }"
                                />
                              </div>
                            </div>

                            <div className="col-lg-12">
                              <div className="row justify-content-between ">
                                <div
                                  //*ngIf="oathSignup==false"
                                  className="col-lg-12 pb-3 pt-3 "
                                >
                                  <button
                                    //(click)="next2()"
                                    onClick={handelSlide2to3}
                                    className="btn btn-primary btn-block "
                                  >
                                    {t("signup.continue")}
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <div className=" row justify-content-center align-items-center">
                {isThirdStep && (
                  <div
                    //[hidden]="!isThirdStep "
                    className="col-lg-5 col-md-6 mb-5"
                  >
                    <div className="card login-page bg-white shadow rounded border-0">
                      <div className="card-body">
                        <h5 className="card-title text-center">
                          {t("signup.title2")}
                        </h5>
                        <h6 className="card-title text-center">
                          <b>{email}</b>
                        </h6>
                        <div
                          className="login-form mt-4"
                        //[formGroup]="signupFormExperienceGroup"
                        >
                          <div className="row">
                            <div className="col-lg-12">
                              <div className="form-group position-relative px-3">
                                <label
                                  className="required"
                                  style={{
                                    display: "flex",
                                    flexDirection:
                                      i18n.language === "ar"
                                        ? "justify-content-end"
                                        : "justify-content-start",
                                  }}
                                //[ngStyle]="{'flex-direction':(this.storageService.getLanguage()==='ar') ? 'justify-content-end' : 'justify-content-start' }"
                                >
                                  {t("profile.expertise.yearsExperience")}
                                  {/* <span className="text-danger"> *</span> */}
                                </label>

                                <div
                                  className="d-flex"
                                  style={{
                                    justifyContent: "center",
                                    paddingLeft: "15px",
                                    paddingRight: "15px",
                                  }}
                                >
                                  <ToggleButtonGroup
                                    style={{
                                      minWidth: "80%",
                                    }}
                                    sx={{
                                      direction: "ltr",
                                    }}
                                    value={yearsExperience}
                                    exclusive
                                    onChange={(e, newValue) =>
                                      setYearsExperience(newValue)
                                    }
                                  >
                                    <ToggleButton
                                      style={{
                                        minWidth: "33%",
                                      }}
                                      sx={{
                                        direction: "ltr",
                                      }}
                                      value="0-3"
                                    >
                                      0-3
                                    </ToggleButton>
                                    <ToggleButton
                                      style={{
                                        minWidth: "33%",
                                      }}
                                      sx={{
                                        direction: "ltr",
                                      }}
                                      value="3-7"
                                    >
                                      3-7
                                    </ToggleButton>
                                    <ToggleButton
                                      sx={{
                                        direction: "ltr",
                                      }}
                                      style={{
                                        minWidth: "33%",
                                      }}
                                      value="7+"
                                    >
                                      7+
                                    </ToggleButton>
                                  </ToggleButtonGroup>
                                </div>
                              </div>
                              <br />
                            </div>

                            <div className="col-lg-12">
                              <div className="form-group position-relative px-3">
                                <label
                                  style={{
                                    display: "flex",
                                    flexDirection:
                                      i18n.language === "ar"
                                        ? "justify-content-end"
                                        : "justify-content-start",
                                  }}
                                // [ngStyle]="{'flex-direction':(this.storageService.getLanguage()==='ar') ? 'justify-content-end' : 'justify-content-start' }"
                                >
                                  {t("profile.expertise.workedStartup")}
                                  <span className="text-danger"> *</span>
                                </label>

                                <div
                                  className="d-flex"
                                  style={{
                                    justifyContent: "center",
                                    paddingLeft: "15px",
                                    paddingRight: "15px",
                                  }}
                                >
                                  <ToggleButtonGroup
                                    sx={{
                                      direction: "ltr",
                                    }}
                                    style={{ minWidth: "80%" }}
                                    value={startupExperience}
                                    exclusive
                                    onChange={(e, newValue) =>
                                      setStartupExperience(newValue)
                                    }
                                  >
                                    <ToggleButton
                                      style={{ minWidth: "50%" }}
                                      value="yes"
                                    >
                                      {t("profile.expertise.yes")}
                                    </ToggleButton>
                                    <ToggleButton
                                      style={{ minWidth: "50%" }}
                                      value="no"
                                    >
                                      {t("profile.expertise.no")}
                                    </ToggleButton>
                                  </ToggleButtonGroup>
                                </div>
                              </div>
                              <br />
                            </div>

                            <div className="col-lg-12">
                              <div className="form-group position-relative px-3">
                                <label
                                  style={{
                                    display: "flex",
                                    flexDirection:
                                      i18n.language === "ar"
                                        ? "justify-content-end"
                                        : "justify-content-start",
                                  }}
                                // [ngStyle]="{'flex-direction':(this.storageService.getLanguage()==='ar') ? 'justify-content-end' : 'justify-content-start' }"
                                >
                                  {t("profile.expertise.willingRelocate")}
                                  <span className="text-danger"> *</span>
                                </label>

                                <div
                                  className="d-flex"
                                  style={{
                                    justifyContent: "center",
                                    paddingLeft: "15px",
                                    paddingRight: "15px",
                                  }}
                                >
                                  <ToggleButtonGroup
                                    sx={{
                                      direction: "ltr",
                                    }}
                                    style={{ minWidth: "80%" }}
                                    value={relocate}
                                    exclusive
                                    onChange={(e, newValue) =>
                                      setRelocate(newValue)
                                    }
                                  >
                                    <ToggleButton
                                      style={{ minWidth: "50%" }}
                                      value="yes"
                                    >
                                      {t("profile.expertise.yes")}
                                    </ToggleButton>
                                    <ToggleButton
                                      style={{ minWidth: "50%" }}
                                      value="no"
                                    >
                                      {t("profile.expertise.no")}
                                    </ToggleButton>
                                  </ToggleButtonGroup>
                                </div>
                              </div>
                              <br />
                            </div>

                            <div className="col-lg-12">
                              <div className="form-group position-relative px-3">
                                <label
                                  style={{
                                    display: "flex",
                                    flexDirection:
                                      i18n.language === "ar"
                                        ? "justify-content-end"
                                        : "justify-content-start",
                                  }}
                                // [ngStyle]="{'flex-direction':(this.storageService.getLanguage()==='ar') ? 'justify-content-end' : 'justify-content-start' }"
                                >
                                  {t("profile.expertise.remote")}
                                  <span className="text-danger">*</span>
                                </label>

                                <div
                                  className="d-flex"
                                  style={{
                                    justifyContent: "center",
                                    paddingLeft: "15px",
                                    paddingRight: "15px",
                                  }}
                                >
                                  <ToggleButtonGroup
                                    sx={{
                                      direction: "ltr",
                                    }}
                                    style={{ minWidth: "80%" }}
                                    value={remote}
                                    exclusive
                                    onChange={(e, newValue) =>
                                      setRemote(newValue)
                                    }
                                  >
                                    <ToggleButton
                                      style={{ minWidth: "50%" }}
                                      value="yes"
                                    >
                                      {t("profile.expertise.yes")}
                                    </ToggleButton>
                                    <ToggleButton
                                      style={{ minWidth: "50%" }}
                                      value="no"
                                    >
                                      {t("profile.expertise.no")}
                                    </ToggleButton>
                                  </ToggleButtonGroup>
                                </div>
                              </div>
                              <br />
                            </div>

                            <div className="col-lg-12">
                              <div className="form-group position-relative px-3">
                                <label
                                  style={{
                                    display: "flex",
                                    flexDirection:
                                      i18n.language === "ar"
                                        ? "justify-content-end"
                                        : "justify-content-start",
                                  }}
                                //[ngStyle]="{'flex-direction':(this.storageService.getLanguage()==='ar') ? 'justify-content-end' : 'justify-content-start' }"
                                >
                                  {t("profile.expertise.equity")}
                                  <span className="text-danger"> *</span>
                                </label>

                                <div
                                  className="d-flex"
                                  style={{
                                    justifyContent: "center",
                                    paddingLeft: "15px",
                                    paddingRight: "15px",
                                  }}
                                >
                                  <ToggleButtonGroup
                                    sx={{
                                      direction: "ltr",
                                    }}
                                    style={{ minWidth: "80%" }}
                                    value={equity}
                                    exclusive
                                    onChange={(e, newValue) =>
                                      setEquity(newValue)
                                    }
                                  >
                                    <ToggleButton
                                      style={{ minWidth: "50%" }}
                                      value="yes"
                                    >
                                      {t("profile.expertise.yes")}
                                    </ToggleButton>
                                    <ToggleButton
                                      style={{ minWidth: "50%" }}
                                      value="no"
                                    >
                                      {t("profile.expertise.no")}
                                    </ToggleButton>
                                  </ToggleButtonGroup>
                                </div>
                              </div>
                              <br />
                            </div>

                            <div className="col-lg-12">
                              <div className="form-group position-relative px-3">
                                <label
                                  style={{
                                    display: "flex",
                                    flexDirection:
                                      i18n.language === "ar"
                                        ? "justify-content-end"
                                        : "justify-content-start",
                                  }}
                                //[ngStyle]="{'flex-direction':(this.storageService.getLanguage()==='ar') ? 'justify-content-end' : 'justify-content-start' }"
                                >
                                  {t("profile.expertise.commitFulltime")}
                                  <span className="text-danger"> *</span>
                                </label>

                                <div
                                  className="d-flex"
                                  style={{
                                    justifyContent: "center",
                                    paddingLeft: "15px",
                                    paddingRight: "15px",
                                  }}
                                >
                                  <ToggleButtonGroup
                                    sx={{
                                      direction: "ltr",
                                    }}
                                    style={{ minWidth: "80%" }}
                                    value={commitFulltime}
                                    exclusive
                                    onChange={(e, newValue) =>
                                      setCommitFulltime(newValue)
                                    }
                                  >
                                    <ToggleButton
                                      style={{ minWidth: "50%" }}
                                      value="yes"
                                    >
                                      {t("profile.expertise.yes")}
                                    </ToggleButton>
                                    <ToggleButton
                                      style={{ minWidth: "50%" }}
                                      value="no"
                                    >
                                      {t("profile.expertise.no")}
                                    </ToggleButton>
                                  </ToggleButtonGroup>
                                </div>
                              </div>
                              <br />
                            </div>

                            <div className="col-lg-12">
                              <div className="form-group position-relative px-3">
                                <label
                                  style={{
                                    display: "flex",
                                    flexDirection:
                                      i18n.language === "ar"
                                        ? "justify-content-end"
                                        : "justify-content-start",
                                  }}
                                //[ngStyle]="{'flex-direction':(this.storageService.getLanguage()==='ar') ? 'justify-content-end' : 'justify-content-start' }"
                                >
                                  {t("profile.expertise.whenStart")}
                                  <span className="text-danger"> *</span>
                                </label>

                                <div
                                  className="d-flex"
                                  style={{
                                    justifyContent: "center",
                                    paddingLeft: "15px",
                                    paddingRight: "15px",
                                  }}
                                >
                                  <ToggleButtonGroup
                                    sx={{
                                      direction: "ltr",
                                    }}
                                    style={{ minWidth: "80%" }}
                                    value={whenStart}
                                    exclusive
                                    orientation="vertical"
                                    onChange={(e, newValue) =>
                                      setWhenStart(newValue)
                                    }
                                  >
                                    <ToggleButton
                                      style={{ minWidth: "33%" }}
                                      value="immediately"
                                    >
                                      {t("profile.expertise.immediately")}
                                    </ToggleButton>
                                    <ToggleButton
                                      style={{ minWidth: "33%" }}
                                      value="in one week"
                                    >
                                      {t("profile.expertise.in one week")}
                                    </ToggleButton>
                                    <ToggleButton
                                      style={{ minWidth: "33%" }}
                                      value="in one month"
                                    >
                                      {t("profile.expertise.in one month")}
                                    </ToggleButton>
                                  </ToggleButtonGroup>
                                </div>
                              </div>
                              <br />
                            </div>

                            <div className="col-lg-12">
                              <div className="form-group position-relative px-3">
                                <label
                                  style={{
                                    display: "flex",
                                    flexDirection:
                                      i18n.language === "ar"
                                        ? "justify-content-end"
                                        : "justify-content-start",
                                  }}
                                //[ngStyle]="{'flex-direction':(this.storageService.getLanguage()==='ar') ? 'justify-content-end' : 'justify-content-start' }"
                                >
                                  {t("profile.expertise.bestAt")}
                                  <span className="text-danger"> *</span>
                                </label>

                                <div
                                  className="d-flex"
                                  style={{
                                    justifyContent: "center",
                                    paddingLeft: "15px",
                                    paddingRight: "15px",
                                  }}
                                >
                                  <ToggleButtonGroup
                                    sx={{
                                      direction: "ltr",
                                    }}
                                    style={{ minWidth: "80%" }}
                                    value={bestAt}
                                    orientation="vertical"
                                    exclusive
                                    onChange={(e, newValue) => {
                                      setBestAt(newValue);
                                    }}
                                  >
                                    <ToggleButton value="Frontend">
                                      Frontend
                                    </ToggleButton>
                                    <ToggleButton value="Backend">
                                      Backend
                                    </ToggleButton>
                                    <ToggleButton value="Devops">
                                      Devops
                                    </ToggleButton>
                                    <ToggleButton value="Design">
                                      Design
                                    </ToggleButton>
                                    <ToggleButton value="Database">
                                      Database
                                    </ToggleButton>
                                  </ToggleButtonGroup>
                                </div>
                              </div>
                              <br />
                            </div>

                            <div className="col-lg-12">
                              <div className="form-group position-relative moreTool px-3">
                                <hr />
                                <label
                                  style={{
                                    display: "flex",
                                    flexDirection:
                                      i18n.language === "ar"
                                        ? "justify-content-end"
                                        : "justify-content-start",
                                  }}
                                //[ngStyle]="{'flex-direction':(this.storageService.getLanguage()==='ar') ? 'justify-content-end' : 'justify-content-start' }"
                                >
                                  {t("profile.aboutMe.label_tell_more")}
                                  <span className="text-danger"> *</span>
                                </label>

                                <textarea
                                  //[(ngModel)]="about"
                                  style={{
                                    borderColor: count < 75 ? "red" : "gray",
                                  }}
                                  className="form-control"
                                  placeholder="Tell us more about yourself"
                                  //="tellMore"
                                  maxLength="1000"
                                  minLength="75"
                                  rows="3"
                                  required
                                  value={tellMore}
                                  onChange={handelTellMore}
                                //[ngStyle]="{'border-color':( form3Error &&signupFormExperienceGroup.value.tellMore.length<75 )   ? 'red' : 'gray' }"
                                ></textarea>
                                <div className="d-flex justify-content-end w-100">
                                  <p
                                    style={{
                                      color: count < 75 ? "red" : "green",
                                    }}
                                  //[ngStyle]="{'color': about.length<75? 'red' : 'green'}" align="end"
                                  >
                                    {count} / 75{" "}
                                    {t("profile.expertise.characters")}
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-12">
                              <div className="row justify-content-between ">
                                <div className="col-lg-6 mb-lg-0 mb-2 pb-3 ">
                                  <button
                                    // (click)="previous3()"
                                    onClick={handelSlide3to2}
                                    className="btn btn-outline-primary btn-block "
                                  >
                                    {t("signup.return")}
                                  </button>
                                </div>
                                <div className="col-lg-6 ">
                                  <button
                                    onClick={handelSlide3to4}
                                    // (click)="next3()"
                                    className="btn btn-primary btn-block "
                                  >
                                    {t("signup.continue")}
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <div className=" row justify-content-center align-items-center">
                {isFourthStep === true && (
                  <div
                    // [hidden]="!this.isFourthStep "
                    className="col-lg-5 col-md-6 mb-5"
                  >
                    <div className="card login-page bg-white shadow rounded border-0">
                      <div className="card-body">
                        <h5 className="card-title text-center">
                          {t("signup.title2")}
                        </h5>
                        <h6 className="card-title text-center">
                          {t("signup.all-skills")}
                        </h6>
                        <h6 className="card-title text-center">
                          <b>{email}</b>
                        </h6>
                        <div
                          className="login-form mt-4"
                        //  [formGroup]="signupFormExperienceGroup"
                        >
                          <div className="row">
                            <div className="col-lg-12">
                              <div className="d-flex">
                                <div
                                  className="form-group position-relative px-3"
                                  style={{ width: "100%" }}
                                >
                                  <label>
                                    {t("profile.expertise.frontend")}{" "}
                                    <span className="text-danger"></span>
                                  </label>

                                  <Autocomplete
                                    multiple
                                    ChipProps={{ color: "primary" }}
                                    // className="green-chip"
                                    //className="green-chip"
                                    color="primary"
                                    id="tags-outlined"
                                    //defaultValue={[names[13]]}
                                    //onChange={handleFrontEnd}

                                    // value={frontend}
                                    options={technologies.frontEnd}
                                    value={frontend}
                                    onChange={(event, newValue) => {
                                      setFrontEnd(newValue);
                                    }}
                                    filterSelectedOptions
                                    renderInput={(params) => (
                                      <TextField
                                        sx={{
                                          direction: "ltr",
                                        }}
                                        color="primary"
                                        className="edit-profile-field-total"
                                        {...params}
                                        label="skills"
                                      //placeholder="Favorites"
                                      />
                                    )}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-12">
                              <div className="d-flex">
                                <div
                                  className="form-group position-relative px-3"
                                  style={{ width: "100%" }}
                                >
                                  <label>
                                    {t("profile.expertise.backend")}{" "}
                                    <span className="text-danger"></span>
                                  </label>

                                  <Autocomplete
                                    multiple
                                    ChipProps={{ color: "primary" }}
                                    // className="green-chip"
                                    //className="green-chip"
                                    color="primary"
                                    id="tags-outlined"
                                    //={(option) => option}
                                    options={technologies.backEnd}
                                    value={backend}
                                    onChange={(event, newValue) => {
                                      setBackend(newValue);
                                    }}
                                    //defaultValue={[names[13]]}
                                    filterSelectedOptions
                                    renderInput={(params) => (
                                      <TextField
                                        sx={{
                                          direction: "ltr",
                                        }}
                                        color="primary"
                                        className="edit-profile-field-total"
                                        {...params}
                                        label="skills"
                                      //placeholder="Favorites"
                                      />
                                    )}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-12">
                              <div className="d-flex">
                                <div
                                  className="form-group position-relative px-3"
                                  style={{ width: "100%" }}
                                >
                                  <label>
                                    {t("profile.expertise.devops")}{" "}
                                    <span className="text-danger"></span>
                                  </label>

                                  <Autocomplete
                                    multiple
                                    ChipProps={{ color: "primary" }}
                                    // className="green-chip"
                                    //className="green-chip"
                                    color="primary"
                                    id="tags-outlined"
                                    //={(option) => option}
                                    options={technologies.devOps}
                                    value={devops}
                                    onChange={(event, newValue) => {
                                      setDevops(newValue);
                                    }}
                                    //defaultValue={[names[13]]}
                                    filterSelectedOptions
                                    renderInput={(params) => (
                                      <TextField
                                        sx={{
                                          direction: "ltr",
                                        }}
                                        color="primary"
                                        className="edit-profile-field-total"
                                        {...params}
                                        label="skills"
                                      //placeholder="Favorites"
                                      />
                                    )}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-12">
                              <div className="d-flex">
                                <div
                                  className="form-group position-relative px-3"
                                  style={{ width: "100%" }}
                                >
                                  <label>
                                    {t("profile.expertise.design")}{" "}
                                    <span className="text-danger"></span>
                                  </label>

                                  <Autocomplete
                                    multiple
                                    ChipProps={{ color: "primary" }}
                                    // className="green-chip"
                                    //className="green-chip"
                                    color="primary"
                                    id="tags-outlined"
                                    //={(option) => option}
                                    options={technologies.Design}
                                    value={design}
                                    onChange={(event, newValue) => {
                                      setDesign(newValue);
                                    }}
                                    //defaultValue={[names[13]]}
                                    filterSelectedOptions
                                    renderInput={(params) => (
                                      <TextField
                                        sx={{
                                          direction: "ltr",
                                        }}
                                        color="primary"
                                        className="edit-profile-field-total"
                                        {...params}
                                        label="skills"
                                      //placeholder="Favorites"
                                      />
                                    )}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-12">
                              <div className="d-flex">
                                <div
                                  className="form-group position-relative px-3"
                                  style={{ width: "100%" }}
                                >
                                  <label>
                                    {t("profile.expertise.database")}{" "}
                                    <span className="text-danger"></span>
                                  </label>

                                  <Autocomplete
                                    multiple
                                    ChipProps={{ color: "primary" }}
                                    // className="green-chip"
                                    //className="green-chip"
                                    color="primary"
                                    id="tags-outlined"
                                    //={(option) => option}
                                    options={technologies.dataBase}
                                    value={database}
                                    onChange={(event, newValue) => {
                                      setDatabase(newValue);
                                    }}
                                    //defaultValue={[names[13]]}
                                    filterSelectedOptions
                                    renderInput={(params) => (
                                      <TextField
                                        sx={{
                                          direction: "ltr",
                                        }}
                                        color="primary"
                                        className="edit-profile-field-total"
                                        {...params}
                                        label="skills"
                                      //placeholder="Favorites"
                                      />
                                    )}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-12">
                              <div className="row justify-content-between ">
                                <div className="col-lg-6 mb-lg-0 mb-2 pb-3 ">
                                  <button
                                    onClick={handelSlide4to3}
                                    //(click)="previous4()"
                                    className="btn btn-outline-primary btn-block "
                                  >
                                    {t("signup.return")}
                                  </button>
                                </div>
                                <div className="col-lg-6 ">
                                  <button
                                    //onClick={handelSlide4to5}
                                    type="submit"
                                    //onSubmit={submitHandler}
                                    //disabled={loading}
                                    //(click)="signup()"
                                    // [disabled]="signupFormExperienceGroup.invalid  "
                                    color="secondary"
                                    className="btn btn-primary btn-block "
                                  >
                                    {t("signup.continue")}
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </form>
        </section>
      </div>
    </div>
  );
}
