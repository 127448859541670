import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import Picker from 'emoji-picker-react'
import { IoMdSend } from 'react-icons/io'
import { BsEmojiSmileFill } from 'react-icons/bs'
import { useTranslation } from 'react-i18next';

export default function ChatInput({ handleSendMsg }) {
    const [showEmojiPicker, setShowEmojiPicker] = useState(false);
    const [msg, setMsg] = useState("");
    const [submitClassName, setSubmitClassName] = useState("submit");
    const { t, i18n } = useTranslation();
    const handleEmojiPickerHideShow = () => {
        setShowEmojiPicker(!showEmojiPicker);
    };

    const handleEmojiClick = (e, emoji) => {
        let message = msg;
        message += emoji.emoji;
        setMsg(message);
    }

    useEffect(() => {
        if (msg.length > 0) {
            setSubmitClassName("submit activeSubmit");
        } else {
            setSubmitClassName("submit");
        }
    }, [msg]);

    const sendChat = (e) => {
        e.preventDefault();
        if (msg.length > 0) {
            handleSendMsg(msg);
            setMsg('');

        }
    }
    return (
        <Container>
            <div className="button-container">
                <form className='input-container' onSubmit={(e) => sendChat(e)}>
                    <input type="text" placeholder={t('chat.typeHint')} value={msg} onChange={(e) => { setMsg(e.target.value) }} />
                    <button className={submitClassName}>
                        <IoMdSend style={{transform: i18n.language === 'ar' ? 'rotate(180deg)' : '' }} />
                    </button>
                </form>
                {/* <div className="emoji" >
                <BsEmojiSmileFill onClick={handleEmojiPickerHideShow}/>
                { showEmojiPicker && <Picker onEmojiClick={handleEmojiClick}/> }
            </div> */}
            </div>

        </Container>
    )
}

const Container = styled.div`
display: grid;
grid-template-columns: 95% 95%;
align-items: center;
padding: 0 1rem 0 0;
padding-bottom: 0.3rem;
border-top: 1px solid #dee2e6;
@media screen and (min-width: 720px) and (max-width: 1080px){
    padding: 0 1rem;
    gap: 1rem;
}
.button-container{
    display: flex;
    align-items: center;
    color: white;
    gap: 1rem;
    .emoji{
        position: relative;
        svg{
            font-size: 1.5rem;
            color: #37a11a;
            cursor: pointer;
        }
        .emoji-picker-react{
            position: absolute;
            top: -350px;
            background-color: #080420;
            box-shadow: 0 5px 10px #dee2e6;
            border-color: #9186f3;
            .emoji-scroll-wrapper::-webkit-scrollbar{
                background-color: #080420;
                width: 5px;
                &-thumb {
                    background-color: #dee2e6;
                }
            }
            .emoji-categories{
                button{
                    filter: contrast(0);
                }
            }
            .emoji-search{
                background-color: #f0f2f5;
                border-color: #9186f3;
                color: white;
            }
            .emoji-group:before {
                background-color: #080420;
            }
        }
    }
}

.input-container{
    width: 100%;
    border-radius: 2rem;
    display: flex;
    align-items: center;
    gap: 1rem;
    background-color: #ffffff34;
    input{
        width: 90%;
        height: auto;
        background-color: #f0f2f5;
        color: #000;
        border: none;
        padding: 0.3rem 1rem;
        font-size: 1.2rem;
        border-radius: 2rem;
        &::selection{
            background-color: #9186f3;
        }
        &:focus{
            outline: none;
        }
    }
    button.submit{
        padding: 0.3rem 2rem;
        border-radius: 2rem;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #dee2e6;
        border: none;
        cursor: pointer;
        @media screen and (min-width: 720px) and (max-width: 1080px){
            padding: 0.3rem 1rem;
            svg{
            font-size: 1rem;
            color: white;
        }
        }
        svg{
            font-size: 2rem;
            color: white;
        }
    }
    button.activeSubmit{
        @media screen and (min-width: 720px) and (max-width: 1080px){
            svg{
            color: #37a11a;
        }
        }
        svg{
            color: #37a11a;
        }
    }
}

@media screen and (max-width: 760px){
    button.submit{
        padding:0.2rem 0.8rem !important;
        svg {
            font-size: 1.7rem !important;
        }
        
        
    }
 ]


}
`;
