import { useQuery } from "@tanstack/react-query";
import { getCounter } from "../api/counter";
//get counter
export const useGetCounter = () => {
  const { data, isLoading, error, refetch } = useQuery(
    ["counter"],
    () => getCounter() //get counter from api/counter
  );

  return { data, isLoading, error, refetch };
};
