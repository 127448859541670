// Description: This component is used to display the user's account information

import React from "react";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import CardContent from "@mui/material/CardContent";
import Avatar from "@mui/material/Avatar";
import Divider from "@mui/material/Divider";
import Loader from "../Loader/Loader";
import GitHubIcon from "../../assets/img/github-logo (1).png";
import PinIcon from "../../assets/img/map-pin (1).svg";
import settings from "../../assets/img/settings (1).svg";
import linkedinIcon from "../../assets/img/linkedin-profile.svg";
import twitterIcon from "../../assets/img/001-twitter-profile.svg";
import { useTranslation } from "react-i18next";
import "./Account.css";
import { Link } from "react-router-dom";
export default function TechAccount({ user, userLoading }) {
  const { t, i18n } = useTranslation();

  return userLoading === true ? (
    <Loader />
  ) : (
    <div className="userCard">
      <br />
      <Card>
        <div
          className="d-flex mt-2 mb-15 custom-card-header justify-content-between"
          style={{
            flexDirection: "row",
          }}
        >
          <div
            className="d-flex "
            style={{
              flexDirection: "row",
            }}
          >
            <CardHeader
              avatar={
                <Avatar
                  className="card-avatar "
                  sx={{ height: "8vmax", width: "8vmax" }}
                  src={user?.avatar?.url || user?.avatarLink}
                />
              }
            />
            <div className="mr-3">
              <h4 className="mt-2 mb-0 p-0">
                {user?.firstName} {user?.lastName}
              </h4>
              <h6 className={"second-title " + (i18n.language === "ar" ? "text-right" : "text-left")}>
                <img src={PinIcon} alt="" />
                {user?.city} , {user?.country}
              </h6>
            </div>
          </div>
          <ul
            className="list-unstyled social-icon mb-0 mt-3 mr-3  "
            style={{
              float: "right",
              marginLeft: i18n.language === "ar" && "16px",
              marginRight: i18n.language === "ar" && "0px",
            }}
          >
            <li className="list-inline-item">
              <Link
                className="rounded"
                style={{
                  backgroundColor: "#37a11a",
                  cursor: "pointer",
                  borderColor: "white",
                  color: "white",
                }}
                to="/account/edit-personal"
              >
                <img
                  src={settings}
                  className="fea icon-sm fea-social"
                  alt="settings icon"
                />
              </Link>
            </li>
          </ul>
        </div>
        <CardContent>
          <Divider />

          <div
            className="d-flex justify-content-between"
            style={{
              columnCount: "2",
              paddingTop: "10px",
              flexDirection: "row",
            }}
          >
            <div
              style={{
                width: "100%",
                textAlign: i18n.language === "ar" ? "right" : "left",
              }}
              className="profile-description"
            >
              {user?.tellMore}
            </div>
            <div
            // style={{ width: "10%" }}
            >
              <ul
                className="list-unstyled social-icon"
                style={{ float: "right" }}
              >
                <li className="list-inline-item">
                  <Link
                    className="rounded"
                    style={{
                      backgroundColor: "#37a11a",
                      cursor: "pointer",
                      borderColor: "white",
                      color: "white",
                    }}
                    to="/account/about-me"
                  >
                    <img
                      src={settings}
                      className="fea icon-sm fea-social"
                      alt="settings icon"
                    />
                  </Link>
                </li>
              </ul>
            </div>
          </div>
          <Divider />
          <div
            style={{
              display: "flex",
              columnCount: "2",
              paddingTop: "10px",
              flexDirection: "row",
            }}
            className="d-flex justify-content-between"
          >
            <div className="social-links">
              <b>{t("profile.social_links")}</b>
            </div>

            <ul
              className="list-unstyled social-icon"
              style={{ float: "right" }}
            >
              <li className="list-inline-item">
                <Link
                  className="rounded"
                  style={{
                    backgroundColor: "#37a11a",
                    cursor: "pointer",
                    borderColor: "white",
                    color: "white",
                  }}
                  to="/account/social-links"
                >
                  <img
                    src={settings}
                    className="fea icon-sm fea-social"
                    alt="settings icon"
                  />
                </Link>
              </li>
            </ul>
          </div>
          <div className="d-flex-row" style={{ paddingBottom: "20px", direction: i18n.language === "ar" ? "rtl" : "ltr" }}>
            <div className={"mb-2 " + (i18n.language === "ar" ? "text-right" : "text-left")}>
              <div style={{ wordBreak: "break-all" }}>
                <img src={GitHubIcon} alt="github icon" />
                <a
                  style={{ textDecoration: "none", color: "#37a11a" }}
                  href={`https://github.com/${user?.gitHub}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  {" "}
                  /{user?.gitHub}
                </a>
              </div>
            </div>
            <div className={"mb-2 " + (i18n.language === "ar" ? "text-right" : "text-left")}>
              <div style={{ wordBreak: "break-all" }}>
                <img src={linkedinIcon} alt="linkedin icon" />
                <a
                  style={{ textDecoration: "none", color: "#37a11a" }}
                  href={`https://linkedin.com/in/${user?.linkedInProfile}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  {" "}
                  /{user?.linkedInProfile}
                </a>
              </div>
            </div>
            <div className={"mb-2 " + (i18n.language === "ar" ? "text-right" : "text-left")}>
              <div style={{ wordBreak: "break-all" }}>
                <img src={twitterIcon} alt="twitter icon" />
                <a
                  style={{ textDecoration: "none", color: " #37a11a" }}
                  href={`https://twitter.com/${user?.twitter}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  {" "}
                  /{user?.twitter}
                </a>
              </div>
            </div>
          </div>

          <Divider />

          <div
            className="d-flex"
            style={{
              columnCount: "2",
              paddingTop: "10px",
              flexDirection: "row",
            }}
          >
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
              }}
            >
              <div className={"mb-2 " + (i18n.language === "ar" ? "text-right" : "text-left")}>
                <span className="green-heading">
                  {t("profile.yearsExperience")}
                </span>
                <span className="profile-prop">{user?.yearsExperience}</span>
              </div>
              <div className={"mb-2 " + (i18n.language === "ar" ? "text-right" : "text-left")}>
                <span className="green-heading">
                  {t("profile.workedStartup")}
                </span>
                <span className="profile-prop">
                  {" "}
                  {t(
                    `profile.expertise.${user?.startupExperience?.toLowerCase()}`
                  )}
                </span>
              </div>
              <div className={"mb-2 " + (i18n.language === "ar" ? "text-right" : "text-left")}>
                <span className="green-heading">
                  {t("profile.commitFulltime")}
                </span>
                <span className="profile-prop">
                  {t(
                    `profile.expertise.${user?.commitFulltime?.toLowerCase()}`
                  )}
                </span>
              </div>
              <div className={"mb-2 " + (i18n.language === "ar" ? "text-right" : "text-left")}>
                <span className="green-heading">
                  {t("profile.willingRelocate")}
                </span>
                <span className="profile-prop">
                  {" "}
                  {t(`profile.expertise.${user?.relocate?.toLowerCase()}`)}
                </span>
              </div>
              <div className={"mb-2 " + (i18n.language === "ar" ? "text-right" : "text-left")}>
                <span className="green-heading">{t("profile.remote")}</span>
                <span className="profile-prop">
                  {" "}
                  {t(`profile.expertise.${user?.remote?.toLowerCase()}`)}
                </span>
              </div>
              <div className={"mb-2 " + (i18n.language === "ar" ? "text-right" : "text-left")}>
                <span className="green-heading">{t("profile.equity")}</span>
                <span className="profile-prop">
                  {t(`profile.expertise.${user?.equity?.toLowerCase()}`)}
                </span>
              </div>
              <div className={"mb-2 " + (i18n.language === "ar" ? "text-right" : "text-left")}>
                <span className="green-heading">{t("profile.whenStart")}</span>{" "}
                <span className="profile-prop">
                  {t(`profile.expertise.${user?.whenStart?.toLowerCase()}`)}
                </span>
              </div>
              <div className={"mb-2 " + (i18n.language === "ar" ? "text-right" : "text-left")}>
                <span className="green-heading">{t("profile.bestAt")}</span>{" "}
                <span className="profile-prop">{user?.bestAt}</span>
              </div>
            </div>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <ul
                className="list-unstyled social-icon"
                style={{ float: "right" }}
              >
                <li className="list-inline-item">
                  <Link
                    className="rounded"
                    style={{
                      backgroundColor: "#37a11a",
                      cursor: "pointer",
                      borderColor: "white",
                      color: "white",
                    }}
                    to="/account/expertise"
                  >
                    <img src={settings} className="fea icon-sm fea-social" />
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </CardContent>
      </Card>
      <Divider />
      <Card className="profile-prop-card mtb-15 p-0">
        <CardHeader
          component="div"
          className="profile-prop-card-header"
          title={
            <div className="profile-prop-card-header-content">
              <b>Frontend</b>

              <ul
                className="list-unstyled social-icon"
                style={{ float: "right" }}
              >
                <li className="list-inline-item">
                  <Link
                    className="rounded"
                    style={{
                      backgroundColor: "#37a11a",
                      cursor: "pointer",
                      borderColor: "white",
                      color: "white",
                    }}
                    to="/account/skills"
                  >
                    <img
                      src={settings}
                      className="fea icon-sm fea-social"
                      alt="settings icon"
                    />
                  </Link>
                </li>
              </ul>
            </div>
          }
        />
        <div
        //className="profile-prop-card-header"
        ></div>

        <CardContent className="profile-prop-card-content">
          <Stack
            direction="row"
            spacing={1}
            className="profile-prop-chip-list"
            color="primary"
          >
            {user?.frontend.map((value, key) => (
              <Chip color="primary" label={value} key={key} />
            ))}
          </Stack>
        </CardContent>
      </Card>
      <Divider />
      <Card className="profile-prop-card mtb-15 p-0">
        <CardHeader
          component="div"
          className="profile-prop-card-header"
          title={
            <div className="profile-prop-card-header-content">
              <b>Backend</b>

              <ul
                className="list-unstyled social-icon"
                style={{ float: "right" }}
              >
                <li className="list-inline-item">
                  <Link
                    className="rounded"
                    style={{
                      backgroundColor: "#37a11a",
                      cursor: "pointer",
                      borderColor: "white",
                      color: "white",
                    }}
                    to="/account/skills"
                  >
                    <img
                      src={settings}
                      className="fea icon-sm fea-social"
                      alt="settings icon"
                    />
                  </Link>
                </li>
              </ul>
            </div>
          }
        />
        <CardContent className="profile-prop-card-content">
          <Stack
            direction="row"
            spacing={1}
            className="profile-prop-chip-list"
            color="primary"
          >
            {user?.backend.map((value, key) => (
              <Chip color="primary" label={value} key={key} />
            ))}
          </Stack>
        </CardContent>
      </Card>
      <Divider />
      <Card className="profile-prop-card mtb-15 p-0">
        <CardHeader
          component="div"
          className="profile-prop-card-header"
          title={
            <div className="profile-prop-card-header-content">
              <b>Devops</b>

              <ul
                className="list-unstyled social-icon"
                style={{ float: "right" }}
              >
                <li className="list-inline-item">
                  <Link
                    className="rounded"
                    style={{
                      backgroundColor: "#37a11a",
                      cursor: "pointer",
                      borderColor: "white",
                      color: "white",
                    }}
                    to="/account/skills"
                  >
                    <img
                      src={settings}
                      className="fea icon-sm fea-social"
                      alt="settings icon"
                    />
                  </Link>
                </li>
              </ul>
            </div>
          }
        />
        <CardContent className="profile-prop-card-content">
          <Stack
            direction="row"
            spacing={1}
            className="profile-prop-chip-list"
            color="primary"
          >
            {user?.devops.map((value, key) => (
              <Chip color="primary" label={value} key={key} />
            ))}
          </Stack>
        </CardContent>
      </Card>
      <Divider />
      <Card className="profile-prop-card mtb-15 p-0">
        <CardHeader
          component="div"
          className="profile-prop-card-header"
          title={
            <div className="profile-prop-card-header-content">
              <b>Design</b>

              <ul
                className="list-unstyled social-icon"
                style={{ float: "right" }}
              >
                <li className="list-inline-item">
                  <Link
                    className="rounded"
                    style={{
                      backgroundColor: "#37a11a",
                      cursor: "pointer",
                      borderColor: "white",
                      color: "white",
                    }}
                    to="/account/skills"
                  >
                    <img
                      src={settings}
                      className="fea icon-sm fea-social"
                      alt="settings icon"
                    />
                  </Link>
                </li>
              </ul>
            </div>
          }
        />
        <CardContent className="profile-prop-card-content">
          <Stack
            direction="row"
            spacing={1}
            className="profile-prop-chip-list"
            color="primary"
          >
            {user?.design.map((value, key) => (
              <Chip color="primary" label={value} key={key} />
            ))}
          </Stack>
        </CardContent>
      </Card>
      <Divider />
      <Card className="profile-prop-card mtb-15 p-0">
        <CardHeader
          component="div"
          className="profile-prop-card-header"
          title={
            <div className="profile-prop-card-header-content">
              <b>Database</b>

              <ul
                className="list-unstyled social-icon"
                style={{ float: "right" }}
              >
                <li className="list-inline-item">
                  <Link
                    className="rounded"
                    style={{
                      backgroundColor: "#37a11a",
                      cursor: "pointer",
                      borderColor: "white",
                      color: "white",
                    }}
                    to="/account/skills"
                  >
                    <img
                      src={settings}
                      className="fea icon-sm fea-social"
                      alt="settings icon"
                    />
                  </Link>
                </li>
              </ul>
            </div>
          }
        />
        <CardContent className="profile-prop-card-content">
          <Stack
            direction="row"
            spacing={1}
            className="profile-prop-chip-list"
            color="primary"
          >
            {user?.database.map((value, key) => (
              <Chip color="primary" label={value} key={key} />
            ))}
          </Stack>
        </CardContent>
      </Card>
    </div>
  );
}
