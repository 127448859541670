// Description: This component is used to update the skills of the user
import React, { useEffect, useState } from "react";

import Loader from "../Loader/Loader";

import {
  Card,
  CardContent,
  Autocomplete,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  InputAdornment,
} from "@mui/material";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import TwitterIcon from "@mui/icons-material/Twitter";
import GitHubIcon from "@mui/icons-material/GitHub";

import "./SocialLinks.css";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useUser } from "../../hooks/auth";
import { useUpdateUserProfile } from "../../hooks/user";
export default function SocialLinks() {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  const { isLoading: loading, data: user } = useUser();
  const {
    isLoading: updateLoading,
    error: updateError,
    message: updateMessage,
    mutate: update,
  } = useUpdateUserProfile();

  const [linkedInProfile, setLinkedInProfile] = useState(user?.linkedInProfile);
  const [twitter, setTwitter] = useState(user?.twitter);
  const [gitHub, setGitHub] = useState(user?.gitHub);

  const submitHandler = async (e) => {
    e.preventDefault();

    update({
      ...user,
      linkedInProfile,
      twitter,
      gitHub,
    });
    navigate("/account");
  };

  return loading ? (
    <Loader />
  ) : (
    <div className="userCard">
      <Card className="p-0" style={{ width: "100%" }}>
        <CardContent style={{ paddingTop: "20px" }}>
          <br />
          <div
            className="d-flex"
            style={{ paddingLeft: "15px", paddingRight: "15px" }}
          >
            <h3 className="title-edit-personal-description">
              {t("profile.socialLinks.description")}
            </h3>
          </div>
          <br />
          <div
            className="d-flex"
            style={{ paddingLeft: "15px", paddingRight: "15px" }}
          >
            <form style={{ width: "100%" }}>
              <TextField
                //className="edit-profile-field-total"
                label="linkedin"
                value={linkedInProfile}
                onChange={(e) => setLinkedInProfile(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <LinkedInIcon />
                      https://www.linkedin.com/in/
                    </InputAdornment>
                  ),
                }}
                variant="outlined"
              />
              <br />
              <br />
              <br />
              <TextField
                //className="edit-profile-field-total"
                label="twitter"
                value={twitter}
                onChange={(e) => setTwitter(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <TwitterIcon />
                      https://twitter.com/
                    </InputAdornment>
                  ),
                }}
                variant="outlined"
              />
              <br />
              <br />
              <br />
              <TextField
                //className="edit-profile-field-total"
                label="gitHub"
                value={gitHub}
                onChange={(e) => setGitHub(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <GitHubIcon />
                      https://githubcom/
                    </InputAdornment>
                  ),
                }}
                variant="outlined"
              />
            </form>
          </div>
        </CardContent>
        <br />
        <br />
        <div
          className="d-flex"
          style={{
            justifyContent: "center",
            paddingLeft: "30%",
            paddingRight: "30%",
          }}
        >
          <button
            //(click)="update()"
            onClick={submitHandler}
            className="update-button"
          >
            {t("profile.aboutMe.save")}
          </button>
        </div>
        <br />
      </Card>
    </div>
  );
}
