import React, { useState, useEffect } from 'react'
import styled from "styled-components"
import { useParams } from "react-router-dom";
import { addContact, getAllContacts } from "../../api/contact";
import { useTranslation } from 'react-i18next';

import ChatAvatar from '../../assets/img/chat-avatar.png'

export default function Contacts({ currentUser, changeChat }) {
  const [isLoaded, setIsLoaded] = useState(false);
  const [contacts, setContacts] = useState({});
  const [currentSelected, setCurrentSelected] = useState(undefined);
  const { t, i18n } = useTranslation();

  const params = useParams();
  useEffect(() => {
    if (currentUser) {
      const fetchData = async () => {
        if (params.id) {
          await addContact(currentUser._id, params.id);
        }
        const contacts = await getAllContacts(currentUser._id);
        if(contacts){
          setContacts(contacts);
          setIsLoaded(true);
        }
       
      }
      fetchData();
    }

  }, []);

  const changeCurrentChat = (index, contact) => {

    setCurrentSelected(index);
    changeChat(contact);
  };

  const addDefaultSrc = (ev)=>{
    ev.target.src = ChatAvatar;
  }
  return (
    <>
      {
        contacts &&
        (
          <Container>
            <div className="brand shadow-sm pb-2">
              <h3 className="mt-3">{t('chat.chats')}</h3>
            </div>
            <div className="contacts mt-2">
              {
                isLoaded && contacts.map((contact, index) => {
                  return (
                     <div
                      className={`contact ${index === currentSelected ? "selected" : ""
                        }`}
                      key={contact._id}
                      onClick={() => changeCurrentChat(index, contact)}>
                      <div className="avatar">

                        <img src={contact.userDoc.avatar ? contact.userDoc.avatar.url : ChatAvatar}  onError={(e) => { addDefaultSrc(e) }} className="rounded-circle" alt="avatar" width={48} />


                      </div>
                      <div className="username">
                        <h6>{contact.userDoc.firstName} {contact.userDoc.lastName}</h6>
                      </div>
                    </div>
                  )
                })
              }
            </div>
          </Container>
        )
      }
    </>
  )
}


const Container = styled.div`
  overflow: hidden;
  .brand {
    display: flex;
    align-items: center;
    gap: .5rem;
    justify-content: center;
    height: 2.5rem;
    img {
      height: 1rem;
    }
    h3 {
      color: black;
      text-transform: uppercase;
    }
    small{
      font-size: .5em;
      color: #666666
    }
  }
  .contacts {
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: auto;
    gap: 0.5rem;
    &::-webkit-scrollbar {
      width: 0.2rem;
      &-thumb {
        background-color: #ffffff39;
        width: 0.1rem;
        border-radius: 1rem;
      }
    }
    .contact {
      min-height: 3rem;
      cursor: pointer;
      width: 100%;
      border-radius: 0.2rem;
      padding: 0.4rem;
      display: flex;
      gap: 1rem;
      align-items: center;
      transition: 0.5s ease-in-out;
      &.contact:hover{
        background-color: #f0f2f5;
      }
      .avatar {
        img {
          height: 3rem;
        }
      }
      .username {
        h3 {
          color: white;
          text-transform: capitalize;
        }
      }
    }
    .selected {
      background-color: #f0f2f5;
      color: #37a11a;
    }
  }
  .current-user {
    background-color: #0d0d30;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2rem;
    .avatar {
      img {
        height: 4rem;
        max-inline-size: 100%;
      }
    }
    .username {
      h2 {
        color: white;
        text-transform: capitalize;
      }
    }
    @media screen and (min-width: 720px) and (max-width: 1080px) {
      gap: 0.5rem;
      .username {
        h2 {
          font-size: 1rem;
        }
      }
    }
  }

  // for mobile
  @media screen and (max-width: 760px) {
    .contacts {
      display: flex;
      flex-direction: row !important;
      align-items: center;
      overflow-x: auto;
      overflow-y: hidden;
      gap: 0.5rem;
      
  
      &::-webkit-scrollbar {
        height: 0.2rem;
        &-thumb {
          background-color: #ffffff39;
          height: 0.1rem;
          border-radius: 1rem;
        }
      }
      
      .contact {
        padding: 0.5rem !important;
        .username {
          display: none; // Hide the contact names on mobile
        }

      }
      
    }
  }
  
`;








