// This is the footer component
import React from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col, ListGroup } from 'react-bootstrap';
import Logo from "../../assets/img/Imateco@3x.png";
import { useTranslation } from "react-i18next";
import "./Footer.css";
import { FaTwitter } from "react-icons/fa";

function Footer() {

  const { t, i18n } = useTranslation();

  return (
    <footer
      className='py-5 bg-light'
      dir={i18n.language === "ar" ? "rtl" : "ltr"}
    >
        <Container>
          <Row className='gap-5 gap-sm-0'>
            <Col sm={6} lg={3} className="mb-4 mb-md-0">
            {/* Content for the first column */}
              <div className="d-flex d-flex-col align-items-center justify-content-center justify-content-sm-start">
                <img src={Logo} alt="Imatco Logo Brand" className="logo-brand mt-3 mt-lg-0" />
              </div>
            </Col>
            <Col sm={6} lg={3} className="mb-5 mb-md-0">
            {/* Content for the third column */}
              <div className="d-flex justify-content-center justify-content-sm-start">
                <ListGroup as="ul" className="list-unstyled text-capitalize fw-medium footer-list">
                  <li>
                    <Link to="/us">{t("global.about_us")}</Link>
                  </li>
                  <li>
                    <Link to="/profiles">{t("global.profiles")}</Link>
                  </li>
                  <li>
                    <Link to="/partners">{t("global.our_partners")}</Link>
                  </li>
                  <li>
                    <Link to="/faq">{t("global.faq")}</Link>
                  </li>
                </ListGroup>
              </div>
            </Col>
            <Col sm={6} lg={3} className="mb-4 mb-md-0">
            {/* Content for the third column */}
              <div className="d-flex justify-content-center justify-content-sm-start">
                <ListGroup as="ul" className="list-unstyled  gap-3 text-capitalize fw-medium footer-list">
                  <li>
                    <Link to="/blog">{t("global.blog")} </Link>
                  </li>
                  <li>
                    <Link to="/login">{t("global.login")}</Link>
                  </li>
                  <li>
                    <Link to="/signup">{t("global.signup")}</Link>
                  </li>
                </ListGroup>
              </div>
            </Col>
            <Col sm={6} lg={3}>
            {/* Content for the second column */}
              <div className="d-flex justify-content-center justify-content-sm-start  mt-3 mt-lg-0">
                <div className="d-flex flex-cols align-items-center">
                  <div className="">
                    <p className='fw-bold text-uppercase header-colored'>{t('global.social')} <span className='colord-text'>{t('global.media')}</span></p>
                    <a href="https://twitter.com/imatecorocks" rel="noreferrer" target='_blank' className='text-center text-sm-end social-icon'>
                      <FaTwitter size={24} />
                    </a>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
    </footer>
  );
}

export default Footer;
