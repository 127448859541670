// Description: This file is used to configure the i18n library
import i18n from "i18next";

import { initReactI18next } from "react-i18next";

import translationEn from "./assets/i18n/locales/en/translation.json";
import translationAr from "./assets/i18n/locales/ar/translation.json";

const languages = ["en", "ar"]; // (*)

const resource = {
  en: {
    translation: translationEn,
  },
  ar: {
    translation: translationAr,
  },
};

i18n
  //.use(Backend)
  .use(initReactI18next)
  //.use(LanguageDetector)
  .init({
    //detection: { order: ["path", "navigator"] },
    fallbackLng: "ar",
    resources: resource,
    whitelist: languages,
    debug: true,
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
