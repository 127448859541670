import React, { useEffect } from "react";
import { useMutationConfirmUser } from "../../hooks/user";
import { Link, useParams } from "react-router-dom";
import Loader from "../Loader/Loader";

export default function UserConfirmed() {
  const { email } = useParams();

  const { mutate, isLoading, isError } = useMutationConfirmUser(); //useMutationConfirmUser is a custom hook that returns the mutate function, loading state, and error state

  useEffect(() => {
    mutate(email); //upon component mount, the mutate function is called with the email param to confirm the user after they click the link in the email
  }, []);

  return isLoading ? (
    <Loader />
  ) : (
    <div>
      {isError && <h1>Something went wrong</h1>}
      <h1>Thank you for confirming your email</h1>
      <Link to="/login">Login</Link>
    </div>
  );
}
