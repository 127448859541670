// Description: This component is used to display the post details
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { IconButton } from "@mui/material";
import Loader from "../Loader/Loader";
import { useTranslation } from "react-i18next";

import { useGetPost } from "../../hooks/blog";
import Banner from "../Banner/Banner";
export default function Post() {
  const { i18n } = useTranslation();
  const { blogId, id } = useParams(); //useParams is a react router hook that returns the params from the url path

  const { data: post, isLoading: loading } = useGetPost(blogId, id); //useGetPost is a custom hook that returns the post data, loading state, and error state
  let datePublished = new Date(post?.published);
  let navigate = useNavigate();

  return loading ? (
    <Loader />
  ) : (
    <div>
      {" "}
      <br />
      <div
        className="container"
        style={{ direction: i18n.language === "ar" ? "rtl" : "ltr" }}
      >
        <div
          className="page-card-header d-flex justify-content-between"
          style={{
            flexDirection: i18n.language === "ar" ? "row-reverse" : "row",
          }}
        >
          <IconButton
            color="primary"
            //style={{ marginLeft: "25px", marginRight: "25px" }}
            onClick={(e) => navigate(-1)} //navigate(-1) is used to go back to the previous page
          >
            <ArrowBackIcon />
          </IconButton>

          <br />
        </div>
        {/*
        
        ads
        */}
        <Banner />
        {/*
        
        ads
        */}
        <div className="row align-content-center justify-content-center">
          <div className="col-xl-2 col-lg-2 col-md-12 col-sm-12"></div>
          <div className="col-xl-7 col-lg-7 col-md-12 col-sm-12">
            <div className="card">
              <div className="card-body">
                <h2
                  className="card-title"
                  style={{
                    textAlign: i18n.language === "ar" ? "right" : "left",
                  }}
                >
                  {post?.title}
                </h2>

                <div
                  dangerouslySetInnerHTML={{
                    __html: post?.content, //set the content of the post as html
                  }}
                />
              </div>
              <div class="card-footer">
                <p class="text-muted">
                  {datePublished.toLocaleDateString("en-US", {
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                  })}
                </p>
              </div>
            </div>
            <br />
            <br />
          </div>
          <div className="col-xl-2 col-lg-2 col-md-12 col-sm-12"></div>
        </div>
      </div>
    </div>
  );
}
