import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  updateUserProfile,
  getUserById,
  getAllUsers,
  confirmUser,
  sendContactEmail,
} from "../api/user";

export const useUpdateUserProfile = () => {
  const { t } = useTranslation();

  const queryClient = useQueryClient();
  const { mutate, isLoading, error } = useMutation(
    ["user"],
    (user) => updateUserProfile(user), //update user profile from api/user
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries({ queryKey: ["user"] }); //invalidate user query to update user profile and get new data
        toast.success(t("profile.editPersonal.updateSuccess"), {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      },
      onError: (error) => {
        toast.error(t("profile.editPersonal.updateError"), {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      },
    }
  );

  return { mutate, isLoading, error };
};

export const useGetUserProfile = (id) => {
  const { t } = useTranslation();
  const { data, isLoading, error, refetch } = useQuery(
    ["userProfile"],
    () => getUserById(id), //get user by id from api/user

    {
      enabled: !!id, //if id is true then enabled is true

      onError: (error) => {
        toast.error(t("search.profile.errorLoading"));
      },
    }
  );

  return { data, isLoading, error, refetch };
};

//get all users
export const useGetAllUsers = ({
  firstName,
  lastName,
  country,
  city,
  bestAt,
  yearsExperience,
  startupExperience,
  relocate,
  commitFulltime,
  remote,
  equity,
  whenStart,
}) => {
  const { data, isLoading, error, refetch } = useQuery(
    ["users"],
    () =>
      getAllUsers(
        firstName,
        lastName,
        country,
        city,
        bestAt,
        yearsExperience,
        startupExperience,
        relocate,
        commitFulltime,
        remote,
        equity,
        whenStart
      ),
    {
      enabled: false, //if id is true then enabled is true so this is false and will have to run refetch to get data
      // onSuccess: (data) => {
      //   console.log(data);
      // },
      // onError: (error) => {
      //   console.log(error);
      // },
    }
  );

  return { data, isLoading, error, refetch };
};

export function useMutationConfirmUser() {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const { data, mutate, isLoading, isError } = useMutation(
    ["newUser"],
    (email) => confirmUser(email), //confirm user from api/user
    {
      onSuccess: () => {
        toast.success(t("confirm.success"), {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        navigate("/account", { replace: true }); //navigate to account page
        // queryClient.invalidateQueries({ queryKey: ["user"] });
      },
      onError: (error) => {
        toast.error(t("confirm.error"), {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      },
    }
  );

  return { data, mutate, isLoading, isError };
}

export function useSendContactEmail() {
  const { t } = useTranslation();

  const { data, mutate, isLoading, isError } = useMutation(
    ["contactEmail"],
    (email) => sendContactEmail(email), //send contact email from api/user
    {
      onSuccess: () => {
        toast.success(t("search.profile.successEmail"), {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });

        // queryClient.invalidateQueries({ queryKey: ["user"] });
      },
      onError: (error) => {
        toast.error(t("search.profile.errorEmail"), {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      },
    }
  );

  return { data, mutate, isLoading, isError };
}
