import axios from "axios";

const API = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  withCredentials: true,
});

//get all messages by id
export const getAllContacts = async (userId) => {
  try {
    const { contacts } = await API.get(`/contact/get-contacts/` + userId )
    .then((res) => res.data);
    return contacts;
  } catch (error) {
    throw error;
  }
};
export const addContact = async (from, to) => {
  try {
    const { contact:contact, isNew: isNew } = await API.post(`/contact/add-contact`, {
        from: from,
        to: to,
    }).then((res) => res.data);
    return {contact, isNew};
  } catch (error) {
    throw error;
  }
};