// Description: this component is used to display the blog posts

import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import Loader from "../Loader/Loader";
import usePagination from "../Pagination";
import Pagination from "@mui/material/Pagination";
import { Link } from "react-router-dom";
import ChopLines from "chop-lines";
import insane from "insane";

import square from "../../assets/img/nama_ad_simple_square_250x250.svg";
import { useGetBlog } from "../../hooks/blog";
import Banner from "../Banner/Banner";

const Sanitized = ({ html }) => {
  const { t, i18n } = useTranslation();
  return (
    <div
      style={{
        textAlign: i18n.language === "ar" ? "right" : "left",
        direction: i18n.language === "ar" ? "rtl" : "ltr",
      }}
      dangerouslySetInnerHTML={{
        __html: insane(html), //to sanitize the html
      }}
    />
  );
};
export default function Blog() {
  const { data: posts, isLoading: loading, refetch } = useGetBlog();

  const _DATA = usePagination(posts); //usePagination is a custom hook that returns the paginated data, the current page, and the total pages

  const { t, i18n } = useTranslation();
  useEffect(() => {
    refetch(); //refetch is a function that refetches the data from the server when the language changes or when the component is mounted
  }, [i18n.language]);

  return loading ? (
    <Loader />
  ) : (
    <div>
      <br />
      <div
        className="container"
        style={{ direction: i18n.language === "ar" ? "rtl" : "ltr" }}
      >
        <div className="row  row-cols-1 row-cols-md-3 g-4">
          <div className="col-md-12">
            <Banner />
            <div className="card border border-light shadow-0 mb-3">
              <div className="card-body">
                <h1
                  className="card-title center flex-1 title-show-profile"
                  style={{
                    fontSize: "30px",
                  }}
                >
                  {t("global.blog")}
                </h1>
                <br />
                <div className="row row-cols-1 row-cols-md-3 g-4 align-content-center justify-content-center">
                  {_DATA && _DATA?.maxPage > 0 ? (
                    _DATA?.currentData()?.map((post, index) => {
                      let datePublished = new Date(post?.published);
                      return (
                        <div className="col-md-4" key={post?.id}>
                          <Link
                            to={`/blog/${post?.blog?.id}/${post?.id}`}
                            class="text-dark"
                          >
                            <div className="card border border-light shadow-0 mb-3">
                              <div className="card-body">
                                <h3
                                  className="card-title"
                                  style={{
                                    textAlign:
                                      i18n.language === "ar" ? "right" : "left",
                                  }}
                                >
                                  {post?.title}
                                </h3>
                                {/*

to a small number of lines

*/}
                                <ChopLines
                                  lines={2}
                                  lineHeight={24}
                                  //ellipsis={<Ellipsis />}
                                >
                                  <Sanitized html={post?.content} />
                                </ChopLines>
                                {post?.labels?.map((label) => (
                                  <>
                                    <p class="badge bg-soft-success">{label}</p>
                                    <br />
                                  </>
                                ))}
                                <p class="badge bg-light">{t("home.learn")}</p>
                              </div>
                              <div class="card-footer">
                                <p class="text-muted">
                                  {datePublished.toLocaleDateString(
                                    i18n.language === "ar" ? "ar-EG" : "en-US",
                                    {
                                      year: "numeric",
                                      month: "long",
                                      day: "numeric",
                                    }
                                  )}
                                </p>
                              </div>
                            </div>
                            {index % 2 !== 0 && (
                              <div className="row align-content-center justify-content-center">
                                <div className="  mb-3">
                                  <a
                                    href="https://www.namaventures.com/submit/"
                                    rel="noreferrer"
                                    target="_blank"
                                  >
                                    <img
                                      src={square}
                                      alt="square"
                                      className="m-auto d-block"
                                      width="100%"
                                      height="100%"
                                    />
                                  </a>
                                </div>
                              </div>
                            )}
                          </Link>
                        </div>
                      );
                    })
                  ) : (
                    <div className="row w-100 justify-content-center">
                      <h4
                        style={{
                          display: "flex",
                          flexDirection:
                            i18n.language === "ar"
                              ? "justify-content-end"
                              : "justify-content-start",
                        }}
                      >
                        {t("blog.not_found")}
                      </h4>
                    </div>
                  )}
                </div>
              </div>
              <Pagination
                style={{ direction: i18n.language === "ar" ? "rtl" : "ltr" }}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                }}
                count={_DATA.maxPage}
                page={_DATA.currentPage}
                size="large"
                variant="outlined"
                shape="rounded"
                onChange={(e, p) => _DATA.jump(p)}
              />
              <br />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
