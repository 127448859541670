import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";

import {
  getAllMessages,
  sendMessage,
} from "../api/message";

// Create or get a chat
export const useSendMessage = () => {

  const queryClient = useQueryClient();
  const { mutate, isLoading, error } = useMutation(
    ["message"],
    (message) => sendMessage(message), //update user profile from api/user
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries({ queryKey: ["message"] }); //invalidate user query to update user profile and get new data
      
      },
      onError: (error) => {

      },
    }
  );

  return { mutate, isLoading, error };
};

export const useGetMessages = (userFrom, userTo) => {
  try {
    const { data, isLoading, error, refetch } = useQuery(
      ["messages"],
      (messages) => getAllMessages(userFrom, userTo), //get all messages

    );

    return { data, isLoading, error, refetch };
  } catch (error) {
    throw error;
  }
};