// Description: This component is used to reset the password of the user
import React, { useState } from "react";

import EmailIcon from "../../assets/img/mail.svg";
import { useTranslation } from "react-i18next";
import { useMutationForgotPassword } from "../../hooks/auth";
const ForgotPassword = () => {
  const { t } = useTranslation();
  const [email, setEmail] = useState("");
  const { mutate: forgotPassword, isLoading: loading } =
    useMutationForgotPassword(); // This is the mutation that is used to reset the password and takes the email as a parameter

  const submitHandler = (e) => {
    e.preventDefault();
    forgotPassword(email); //forgotPassword is the mutation that is used to reset the password and takes the email as a parameter it sends it the backend and the backend invoker firebase to reset the password
  };

  return (
    <section className="bg-home d-flex align-items-center pt-5">
      <div className="container mt-md-5">
        <div className="row align-items-center justify-content-center">
          <div className="col-lg-5 col-md-6">
            <div className="card login-page bg-white shadow rounded border-0">
              <div className="card-body">
                <h4 className="text-center">{t("global.reset")}</h4>
                <form className=" mt-4" onSubmit={submitHandler}>
                  <div className="row">
                    <div className="col-lg-12 mb-2">
                      <div className="form-group position-relative">
                        <label>
                          {t("forgot.field_email")}{" "}
                          <span className="text-danger">*</span>
                        </label>
                        <i data-feather="user" className="fea icon-sm "></i>
                        <img
                          src={EmailIcon}
                          className="fea icon-sm "
                          alt="email icon"
                        />

                        <input
                          type="email"
                          className="form-control pl-5"
                          placeholder="Email"
                          formControlName="email"
                          name="email"
                          required
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-lg-12 mb-0">
                      <button
                        disabled={loading}
                        type="submit"
                        className="btn btn-primary btn-block"
                      >
                        {t("forgot.button_reset")}
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ForgotPassword;
