import NamaLogo from "../assets/img/nama.png";
import NamaCodersLogo from "../assets/img/namacoders_small.png";

export const partners = [
  {
    name: "Name Ventures",
    logo: NamaLogo,
    logo2: NamaLogo,
    logo3: NamaLogo,
    site: "https://www.namaventures.com",
    alt: "Name Ventures",
  },
  {
    name: "Name Coders",
    logo: NamaCodersLogo,
    logo2: NamaCodersLogo,
    logo3: NamaCodersLogo,
    site: "https://namacoders.com/ar/%d8%a7%d9%84%d8%b5%d9%81%d8%ad%d8%a9-%d8%a7%d9%84%d8%b1%d8%a6%d9%8a%d8%b3%d9%8a%d8%a9/#contact-us",
    alt: "Name Coders",
  },
];
