// Description: This component is used to display all the profiles of the users
import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Avatar } from "@mui/material";

import Loader from "../Loader/Loader";
import { useUser } from "../../hooks/auth";
import usePagination from "../Pagination";
import userWhiteIcon from "../../assets/img/userWhite.svg";
import messageIcon from "../../assets/img/message.svg";
import { data } from "../../assets/data";
import Pagination from "@mui/material/Pagination";

import "./Profiles.css";
import { useTranslation } from "react-i18next";

import { useGetAllUsers } from "../../hooks/user";
import Spinner from "./Spinner";
import Banner from "../Banner/Banner";
//import { CreateChat, useCreateChat } from "../../hooks/chat";
export default function Profiles() {
  const navigate = useNavigate();
  //const { mutate: createChat } = useCreateChat();
  const { t, i18n } = useTranslation();
  const [accordionVisible, setAccordionVisible] = useState(false); //this is used to show the accordion on mobile devices
  const [isClear, setIsClear] = useState(false);
  const [filterLoading, setFilterLoading] = useState(false);
  const changeAccordion = () => {
    setAccordionVisible(!accordionVisible);
  };
  const [windowDimension, detectHW] = useState({
    winWidth: window.innerWidth,
    winHeight: window.innerHeight,
  }); //this is used to detect the window size
  const detectSize = () => {
    detectHW({
      winWidth: window.innerWidth,
      winHeight: window.innerHeight,
    });
  };

  useEffect(() => {
    window.addEventListener("resize", detectSize);
    if (window.innerWidth > 800) {
      setAccordionVisible(false);
    }
    return () => {
      window.removeEventListener("resize", detectSize);
    };
  }, [windowDimension]); //this is used to detect the window size

  const { data: authUser } = useUser(); //this is used to get the current user

  const [name, setName] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [country, setCountry] = useState("");
  const [city, setCity] = useState("");
  const [yearsExperience, setYearsExperience] = useState("");
  const [startupExperience, setStartupExperience] = useState("");
  const [relocate, setRelocate] = useState("");
  const [commitFulltime, setCommitFulltime] = useState("");
  const [remote, setRemote] = useState("");
  const [equity, setEquity] = useState("");
  const [whenStart, setWhenStart] = useState("");
  const [bestAt, setBestAt] = useState("");
  const availableCities = data.countries.find((c) => c.name === country); //this is used to get the available cities for the selected country
  const handelName = (name) => {
    // get first name and last name from name 
    const spaceIndex = name.indexOf(" ");

    if (spaceIndex !== -1) {
      // If there is a space, split the name into first and last names
      setFirstName(name.substring(0, spaceIndex));
      setLastName(name.substring(spaceIndex + 1));
    } else { setFirstName(name); }
    setName(name);
  };
  const {
    data: users,
    isLoading: loading,

    refetch,
  } = useGetAllUsers({
    firstName,
    lastName,
    country,
    city,
    bestAt,
    yearsExperience,
    startupExperience,
    relocate,
    commitFulltime,
    remote,
    equity,
    whenStart,
  }); //this is used to get all the users
  const _DATA = usePagination(users); //this is used to paginate the users

  const submitHandler = async (e) => {
    e.preventDefault();
    setAccordionVisible(false); //this is used to hide the accordion on mobile devices after submitting the form
    setFilterLoading(true);
    try {
      await refetch({
        firstName,
        lastName,
        country,
        city,
        bestAt,
        yearsExperience,
        startupExperience,
        relocate,
        commitFulltime,
        remote,
        equity,
        whenStart,
      });
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setFilterLoading(false); // Set loading to false when data fetching is complete
    }
  };
  const handelClear = () => {
    setName("");
    setFirstName("");
    setLastName("");
    setCountry("");
    setCity("");
    setYearsExperience("");
    setStartupExperience("");
    setRelocate("");
    setCommitFulltime("");
    setRemote("");
    setEquity("");
    setWhenStart("");
    setBestAt("");
    setAccordionVisible(false);
    setIsClear(!isClear);
  }; //this is used to clear the form and the search results

  useEffect(() => {
    refetch(
      firstName,
      lastName,
      country,
      city,
      bestAt,
      yearsExperience,
      startupExperience,
      relocate,
      commitFulltime,
      remote,
      equity,
      whenStart
    );
  }, [isClear]); //this is used to clear the form and the search results when the clear button is clicked

  return loading ? (
    <Loader />
  ) : (
    <div>
      <div className="container">
        <section
          className="section m-auto w-sm-100"
          style={{
            marginTop: "-20px",
            direction: i18n.language === "ar" ? "rtl" : "ltr",
          }}
        >
          <div className="container">
            <div className="row ">
              {/*
            ads
            */}
              <Banner />
              {/*
            ads
            */}
              <div className="row pl-2 pb-2 mr-3  w-100 ">
                {windowDimension.winWidth < 800 && ( //this is used to show the accordion on mobile devices
                  <>
                    <br />
                    <br />
                    <button
                      onClick={changeAccordion}
                      className="btn btn-primary ml-4  w-100"
                      type="button"
                      style={{ marginTop: "50px" }}
                      aria-expanded="false"
                    >
                      {t("profile.filter.filter")}
                    </button>
                  </>
                )}
                {accordionVisible && ( //this is used to show the accordion (form) on mobile devices
                  <div
                    style={{ direction: i18n.language === "ar" ? "rtl" : "ltr" }}
                    className="col-lg-4 col-md-6 col-12 ml-3 "
                  >
                    <div className="bg-light sticky-bar p-4 rounded">
                      <form
                        onSubmit={submitHandler}
                        id="searchform"
                        className="searchform"
                      >
                        <div className="widget mb-2 pb-2 border-bottom">
                          <h4
                            className="widget-title"
                            style={{
                              display: "flex",
                              flexDirection:
                                i18n.language === "ar"
                                  ? "justify-content-end"
                                  : "justify-content-start",
                            }}
                          >
                            {t("search.search")}
                          </h4>
                          <div
                            id="jobkeywords"
                            className="widget-search mt-4 mb-0"
                          >
                            <div>
                              <input
                                type="text"
                                className="border rounded"
                                name="name"
                                placeholder={t("search.search_input")}
                                value={name}
                                onChange={(e) => handelName(e.target.value)}
                              />
                            </div>
                          </div>
                        </div>

                        <div className="widget mb-2 pb-2 border-bottom">
                          <h4
                            className="widget-title"
                            style={{
                              display: "flex",
                              flexDirection:
                                i18n.language === "ar"
                                  ? "justify-content-end"
                                  : "justify-content-start",
                            }}
                          >
                            {t("signup.field_country")}
                          </h4>
                          <div className="form-group mt-4 mb-0">
                            <select
                              className="form-control custom-select"
                              value={country}
                              onChange={(e) => setCountry(e.target.value)}
                            >
                              <option value="">---</option>
                              {data.countries.map((value, key) => {
                                return (
                                  <option value={value.name} key={key}>
                                    {value.name}
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                        </div>
                        <div className="widget mb-2 pb-2 border-bottom">
                          <h4
                            className="widget-title"
                            style={{ display: "flex" }}
                          >
                            {t("signup.field_city")}
                          </h4>
                          <div className="form-group">
                            <select
                              id="job-catagories"
                              className="form-control custom-select"
                              value={city}
                              onChange={(e) => setCity(e.target.value)}
                            >
                              <option value="">---</option>

                              {availableCities?.cities.map((e, key) => {
                                return (
                                  <option value={e} key={key}>
                                    {e}
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                        </div>
                        <div className="widget mb-2 pb-2 border-bottom">
                          <h4
                            className="widget-title"
                            style={{
                              display: "flex",
                              flexDirection:
                                i18n.language === "ar"
                                  ? "justify-content-end"
                                  : "justify-content-start",
                            }}
                          >
                            {t("profile.filter.bestAt")}
                          </h4>
                          <div className="form-group mt-4 mb-0">
                            <select
                              value={bestAt}
                              onChange={(e) => setBestAt(e.target.value)}
                              className="form-control custom-select"
                              id="job-catagories"
                            >
                              <option value="">---</option>
                              <option value="Fullstack">Fullstack</option>
                              <option value="Frontend">Frontend</option>
                              <option value="Backend">Backend</option>
                              <option value="Devops">Devops</option>
                              <option value="Database">Database</option>
                              <option value="Design">Design</option>
                            </select>
                          </div>
                        </div>
                        <div className="widget mb-2 pb-2 border-bottom">
                          <h4
                            className="widget-title"
                            style={{
                              display: "flex",
                              flexDirection:
                                i18n.language === "ar"
                                  ? "justify-content-end"
                                  : "justify-content-start",
                            }}
                          >
                            {t("profile.filter.yearsExperience")}
                          </h4>
                          <div className="mt-4">
                            <div
                              className="custom-control custom-radio"
                              style={{ display: "flex" }}
                            >
                              <div className="form-group mb-2">
                                <input
                                  value="0-3"
                                  type="radio"
                                  id="lasthour"
                                  name="customRadio"
                                  className="custom-control-input"
                                  onChange={(e) =>
                                    setYearsExperience(e.target.value)
                                  }
                                />
                                <label className="custom-control-label">
                                  0-3
                                </label>
                              </div>
                            </div>

                            <div
                              className="custom-control custom-radio"
                              style={{ display: "flex" }}
                            >
                              <div className="form-group mb-2">
                                <input
                                  value="3-7"
                                  type="radio"
                                  id="oneday"
                                  name="customRadio"
                                  className="custom-control-input"
                                  onChange={(e) =>
                                    setYearsExperience(e.target.value)
                                  }
                                />
                                <label className="custom-control-label">
                                  3-7
                                </label>
                              </div>
                            </div>

                            <div
                              className="custom-control custom-radio"
                              style={{ display: "flex" }}
                            >
                              <div className="form-group mb-2">
                                <input
                                  value="7+"
                                  type="radio"
                                  id="sevenday"
                                  name="customRadio"
                                  className="custom-control-input"
                                  onChange={(e) =>
                                    setYearsExperience(e.target.value)
                                  }
                                />
                                <label
                                  className="custom-control-label"
                                  htmlFor="sevenday"
                                >
                                  7+
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="widget mb-2 pb-2 border-bottom">
                          <h4
                            className="widget-title"
                            style={{
                              display: "flex",
                              flexDirection:
                                i18n.language === "ar"
                                  ? "justify-content-end"
                                  : "justify-content-start",
                            }}
                          >
                            {t("profile.filter.workedStartup")}
                          </h4>
                          <div className="mt-4">
                            <div
                              className="custom-control custom-radio"
                              style={{ display: "flex" }}
                            >
                              <div className="form-group mb-2">
                                <input
                                  value="yes"
                                  type="radio"
                                  id="yesWorkedStartup"
                                  name="customRadio5"
                                  className="custom-control-input"
                                  onChange={(e) =>
                                    setStartupExperience(e.target.value)
                                  }
                                />
                                <label
                                  className="custom-control-label"
                                  htmlFor="yesWorkedStartup"
                                >
                                  {t("profile.filter.yes")}
                                </label>
                              </div>
                            </div>
                            <div
                              className="custom-control custom-radio"
                              style={{ display: "flex" }}
                            >
                              <div className="form-group mb-2">
                                <input
                                  value="no"
                                  type="radio"
                                  id="noWorkedStartup"
                                  name="customRadio5"
                                  className="custom-control-input"
                                  onChange={(e) =>
                                    setStartupExperience(e.target.value)
                                  }
                                />
                                <label
                                  className="custom-control-label"
                                  htmlFor="noWorkedStartup"
                                >
                                  {t("profile.filter.no")}
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="widget mb-2 pb-2 border-bottom">
                          <h4
                            className="widget-title"
                            style={{
                              display: "flex",
                              flexDirection:
                                i18n.language === "ar"
                                  ? "justify-content-end"
                                  : "justify-content-start",
                            }}
                          >
                            {t("profile.filter.willingRelocate")}
                          </h4>
                          <div className="mt-4">
                            <div
                              className="custom-control custom-radio"
                              style={{ display: "flex" }}
                            >
                              <div className="form-group mb-2">
                                <input
                                  value="yes"
                                  type="radio"
                                  id="yesRelocate"
                                  name="customRadio3"
                                  className="custom-control-input"
                                  onChange={(e) => setRelocate(e.target.value)}
                                />
                                <label
                                  className="custom-control-label"
                                  htmlFor="yesRelocate"
                                >
                                  {t("profile.filter.yes")}
                                </label>
                              </div>
                            </div>
                            <div
                              className="custom-control custom-radio"
                              style={{ display: "flex" }}
                            >
                              <div className="form-group mb-2">
                                <input
                                  value="no"
                                  type="radio"
                                  id="noRelocate"
                                  name="customRadio3"
                                  className="custom-control-input"
                                  onChange={(e) => setRelocate(e.target.value)}
                                />
                                <label
                                  className="custom-control-label"
                                  htmlFor="noRelocate"
                                >
                                  {t("profile.filter.no")}
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="widget mb-2 pb-2 border-bottom">
                          <h4
                            className="widget-title"
                            style={{
                              display: "flex",
                              flexDirection:
                                i18n.language === "ar"
                                  ? "justify-content-end"
                                  : "justify-content-start",
                            }}
                          >
                            {t("profile.filter.remote")}
                          </h4>
                          <div className="mt-4">
                            <div
                              className="custom-control custom-radio"
                              style={{ display: "flex" }}
                            >
                              <div className="form-group mb-2">
                                <input
                                  value="yes"
                                  type="radio"
                                  id="yesRemote"
                                  name="customRadio4"
                                  className="custom-control-input"
                                  onChange={(e) => setRemote(e.target.value)}
                                />
                                <label
                                  className="custom-control-label"
                                  htmlFor="yesRemote"
                                >
                                  {t("profile.filter.yes")}
                                </label>
                              </div>
                            </div>
                            <div
                              className="custom-control custom-radio"
                              style={{ display: "flex" }}
                            >
                              <div className="form-group mb-2">
                                <input
                                  value="no"
                                  type="radio"
                                  id="noRemote"
                                  name="customRadio4"
                                  className="custom-control-input"
                                  onChange={(e) => setRemote(e.target.value)}
                                />
                                <label
                                  className="custom-control-label"
                                  htmlFor="noRemote"
                                >
                                  {t("profile.filter.no")}
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="widget mb-2 pb-2 border-bottom">
                          <h4
                            className="widget-title"
                            style={{
                              display: "flex",
                              flexDirection:
                                i18n.language === "ar"
                                  ? "justify-content-end"
                                  : "justify-content-start",
                            }}
                          >
                            {t("profile.filter.commitFulltime")}
                          </h4>
                          <div className="mt-4">
                            <div
                              className="custom-control custom-radio"
                              style={{ display: "flex" }}
                            >
                              <div className="form-group mb-2">
                                <input
                                  value="yes"
                                  type="radio"
                                  id="yesFullTime"
                                  name="customRadio2"
                                  className="custom-control-input"
                                  onChange={(e) =>
                                    setCommitFulltime(e.target.value)
                                  }
                                />
                                <label
                                  className="custom-control-label"
                                  htmlFor="yesFullTime"
                                >
                                  {t("profile.filter.yes")}
                                </label>
                              </div>
                            </div>
                            <div
                              className="custom-control custom-radio"
                              style={{ display: "flex" }}
                            >
                              <div className="form-group mb-2">
                                <input
                                  value="no"
                                  type="radio"
                                  id="noFullTime"
                                  name="customRadio2"
                                  className="custom-control-input"
                                  onChange={(e) =>
                                    setCommitFulltime(e.target.value)
                                  }
                                />
                                <label
                                  className="custom-control-label"
                                  htmlFor="noFullTime"
                                >
                                  {t("profile.filter.no")}
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="widget mb-2 pb-2 border-bottom">
                          <h4
                            className="widget-title"
                            style={{
                              display: "flex",
                              flexDirection:
                                i18n.language === "ar"
                                  ? "justify-content-end"
                                  : "justify-content-start",
                            }}
                          >
                            {t("profile.filter.equity")}
                          </h4>
                          <div className="mt-4">
                            <div
                              className="custom-control custom-radio"
                              style={{ display: "flex" }}
                            >
                              <div className="form-group mb-2">
                                <input
                                  value="yes"
                                  type="radio"
                                  id="yesEquity"
                                  name="customRadio2"
                                  className="custom-control-input"
                                  onChange={(e) => setEquity(e.target.value)}
                                />
                                <label
                                  className="custom-control-label"
                                  htmlFor="yesFullTime"
                                >
                                  {t("profile.filter.yes")}
                                </label>
                              </div>
                            </div>
                            <div
                              className="custom-control custom-radio"
                              style={{ display: "flex" }}
                            >
                              <div className="form-group mb-2">
                                <input
                                  value="no"
                                  type="radio"
                                  id="noEquity"
                                  name="customRadio2"
                                  className="custom-control-input"
                                  onChange={(e) => setEquity(e.target.value)}
                                />
                                <label
                                  className="custom-control-label"
                                  htmlFor="noFullTime"
                                >
                                  {t("profile.filter.no")}
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="widget">
                          <h4
                            className="widget-title"
                            style={{
                              display: "flex",
                              flexDirection:
                                i18n.language === "ar"
                                  ? "justify-content-end"
                                  : "justify-content-start",
                            }}
                          >
                            {t("profile.filter.whenStart")}
                          </h4>
                          <div className="mt-4">
                            <div
                              className="custom-control custom-radio"
                              style={{ display: "flex" }}
                            >
                              <div className="form-group mb-2">
                                <input
                                  type="radio"
                                  id="immediatley"
                                  name="customRadio6"
                                  value="immediately"
                                  className="custom-control-input"
                                  onChange={(e) => setWhenStart(e.target.value)}
                                />
                                <label
                                  className="custom-control-label"
                                  htmlFor="immediatley"
                                >
                                  {t("profile.filter.immediately")}
                                </label>
                              </div>
                            </div>

                            <div
                              className="custom-control custom-radio"
                              style={{ display: "flex" }}
                            >
                              <div className="form-group mb-2">
                                <input
                                  type="radio"
                                  id="oneWeek"
                                  name="customRadio6"
                                  value="in one week"
                                  className="custom-control-input"
                                  onChange={(e) => setWhenStart(e.target.value)}
                                />
                                <label
                                  className="custom-control-label"
                                  htmlFor="oneWeek"
                                >
                                  {t("profile.filter.oneWeek")}
                                </label>
                              </div>
                            </div>

                            <div
                              className="custom-control custom-radio"
                              style={{ display: "flex" }}
                            >
                              <div className="form-group mb-2">
                                <input
                                  type="radio"
                                  id="oneMonth"
                                  name="customRadio6"
                                  value="in one month"
                                  className="custom-control-input"
                                  onChange={(e) => setWhenStart(e.target.value)}
                                />
                                <label
                                  className="custom-control-label"
                                  htmlFor="oneMonth"
                                >
                                  {t("profile.filter.oneMonth")}
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="widget">
                          <div className="mt-4">
                            <div className="row">
                              <div className="col-lg-6 col-12 mb-4 mb-lg-0">
                                <button
                                  type="reset"
                                  onClick={handelClear}
                                  className="btn btn-outline-primary btn-block"
                                >
                                  {t("profile.filter.clear")}
                                </button>
                              </div>
                              <div className="col-lg-6  col-12">
                                <button
                                  type="submit"
                                  data-toggle="modal"
                                  data-target="#Contactme"
                                  className="btn btn-block btn-primary"
                                >
                                  {t("profile.filter.apply")}
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                )}
              </div>
              {windowDimension.winWidth > 800 && ( //desktop view
                <div
                  style={{ direction: i18n.language === "ar" ? "rtl" : "ltr" }}
                  className="col-lg-4 col-md-6 col-12  "
                >
                  <div className="sidebar sticky-bar p-4 bg-light sticky-bar p-4 rounded shadow">
                    <form
                      id="searchform"
                      className="searchform"
                      onSubmit={submitHandler}
                    >
                      <div className="widget mb-2 pb-2 border-bottom">
                        <h4
                          className="widget-title"
                          style={{
                            display: "flex",
                            flexDirection:
                              i18n.language === "ar"
                                ? "justify-content-end"
                                : "justify-content-start",
                          }}
                        >
                          {t("search.search")}
                        </h4>
                        <div id="jobkeywords" className="widget-search mt-4 mb-0">
                          <div>
                            <input
                              type="text"
                              className="border rounded"
                              name="name"
                              placeholder={t("search.search_input")}
                              value={name}
                              onChange={(e) => handelName(e.target.value)}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="widget mb-2 pb-2 border-bottom">
                        <h4
                          className="widget-title"
                          style={{
                            display: "flex",
                            flexDirection:
                              i18n.language === "ar"
                                ? "justify-content-end"
                                : "justify-content-start",
                          }}
                        >
                          {t("signup.field_country")}
                        </h4>
                        <div className="form-group mt-4 mb-0">
                          <select
                            className="form-control custom-select"
                            value={country}
                            onChange={(e) => setCountry(e.target.value)}
                          >
                            <option>---</option>
                            {data.countries.map((value, key) => {
                              return (
                                <option value={value.name} key={key}>
                                  {value.name}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      </div>
                      <div className="widget mb-2 pb-2 border-bottom">
                        <h4
                          className="widget-title"
                          style={{
                            display: "flex",
                            flexDirection:
                              i18n.language === "ar"
                                ? "justify-content-end"
                                : "justify-content-start",
                          }}
                        >
                          {t("signup.field_city")}
                        </h4>
                        <div className="form-group">
                          <select
                            id="job-catagories"
                            className="form-control custom-select"
                            value={city}
                            onChange={(e) => setCity(e.target.value)}
                          >
                            <option>---</option>

                            {availableCities?.cities.map((e, key) => {
                              return (
                                <option value={e} key={key}>
                                  {e}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      </div>
                      <div className="widget mb-2 pb-2 border-bottom">
                        <h4
                          className="widget-title"
                          style={{
                            display: "flex",
                            flexDirection:
                              i18n.language === "ar"
                                ? "justify-content-end"
                                : "justify-content-start",
                          }}
                        >
                          {t("profile.filter.bestAt")}
                        </h4>
                        <div className="form-group mt-4 mb-0">
                          <select
                            className="form-control custom-select"
                            id="job-catagories"
                            value={bestAt}
                            onChange={(e) => setBestAt(e.target.value)}
                          >
                            <option>---</option>
                            <option value="Fullstack">Fullstack</option>
                            <option value="Frontend">Frontend</option>
                            <option value="Backend">Backend</option>
                            <option value="Devops">Devops</option>
                            <option value="Database">Database</option>
                            <option value="Design">Design</option>
                          </select>
                        </div>
                      </div>
                      <div className="widget mb-2 pb-2 border-bottom">
                        <h4
                          className="widget-title"
                          style={{
                            display: "flex",
                            flexDirection:
                              i18n.language === "ar"
                                ? "justify-content-end"
                                : "justify-content-start",
                          }}
                        >
                          {t("profile.filter.yearsExperience")}
                        </h4>
                        <div className="mt-4">
                          <div
                            className="custom-control custom-radio"
                            style={{ display: "flex" }}
                          >
                            <div className="form-group mb-2">
                              <input
                                value="0-3"
                                type="radio"
                                id="lasthour"
                                name="customRadio"
                                className="custom-control-input"
                                onChange={(e) =>
                                  setYearsExperience(e.target.value)
                                }
                              />
                              <label
                                className="custom-control-label"
                                htmlFor="lasthour"
                              >
                                0-3
                              </label>
                            </div>
                          </div>

                          <div
                            className="custom-control custom-radio"
                            style={{ display: "flex" }}
                          >
                            <div className="form-group mb-2">
                              <input
                                value="3-7"
                                type="radio"
                                id="oneday"
                                name="customRadio"
                                className="custom-control-input"
                                onChange={(e) =>
                                  setYearsExperience(e.target.value)
                                }
                              />
                              <label
                                className="custom-control-label"
                                htmlFor="oneday"
                              >
                                3-7
                              </label>
                            </div>
                          </div>

                          <div
                            className="custom-control custom-radio"
                            style={{ display: "flex" }}
                          >
                            <div className="form-group mb-2">
                              <input
                                value="7+"
                                type="radio"
                                id="sevenday"
                                name="customRadio"
                                className="custom-control-input"
                                onChange={(e) =>
                                  setYearsExperience(e.target.value)
                                }
                              />
                              <label
                                className="custom-control-label"
                                htmlFor="sevenday"
                              >
                                7+
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="widget mb-2 pb-2 border-bottom">
                        <h4
                          className="widget-title"
                          style={{
                            display: "flex",
                            flexDirection:
                              i18n.language === "ar"
                                ? "justify-content-end"
                                : "justify-content-start",
                          }}
                        >
                          {t("profile.filter.workedStartup")}
                        </h4>
                        <div className="mt-4">
                          <div
                            className="custom-control custom-radio"
                            style={{ display: "flex" }}
                          >
                            <div className="form-group mb-2">
                              <input
                                value="yes"
                                type="radio"
                                id="yesWorkedStartup"
                                name="customRadio5"
                                className="custom-control-input"
                                onChange={(e) =>
                                  setStartupExperience(e.target.value)
                                }
                              />
                              <label
                                className="custom-control-label"
                                htmlFor="yesWorkedStartup"
                              >
                                {t("profile.filter.yes")}
                              </label>
                            </div>
                          </div>
                          <div
                            className="custom-control custom-radio"
                            style={{ display: "flex" }}
                          >
                            <div className="form-group mb-2">
                              <input
                                value="no"
                                type="radio"
                                id="noWorkedStartup"
                                name="customRadio5"
                                className="custom-control-input"
                                onChange={(e) =>
                                  setStartupExperience(e.target.value)
                                }
                              />
                              <label
                                className="custom-control-label"
                                htmlFor="noWorkedStartup"
                              >
                                {t("profile.filter.no")}
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="widget mb-2 pb-2 border-bottom">
                        <h4
                          className="widget-title"
                          style={{
                            display: "flex",
                            flexDirection:
                              i18n.language === "ar"
                                ? "justify-content-end"
                                : "justify-content-start",
                          }}
                        >
                          {t("profile.filter.willingRelocate")}
                        </h4>
                        <div className="mt-4">
                          <div
                            className="custom-control custom-radio"
                            style={{ display: "flex" }}
                          >
                            <div className="form-group mb-2">
                              <input
                                value="yes"
                                type="radio"
                                id="yesRelocate"
                                name="customRadio3"
                                className="custom-control-input"
                                onChange={(e) => setRelocate(e.target.value)}
                              />
                              <label
                                className="custom-control-label"
                                htmlFor="yesRelocate"
                              >
                                {t("profile.filter.yes")}
                              </label>
                            </div>
                          </div>
                          <div
                            className="custom-control custom-radio"
                            style={{ display: "flex" }}
                          >
                            <div className="form-group mb-2">
                              <input
                                value="no"
                                type="radio"
                                id="noRelocate"
                                name="customRadio3"
                                className="custom-control-input"
                                onChange={(e) => setRelocate(e.target.value)}
                              />
                              <label
                                className="custom-control-label"
                                htmlFor="noRelocate"
                              >
                                {t("profile.filter.no")}
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="widget mb-2 pb-2 border-bottom">
                        <h4
                          className="widget-title"
                          style={{
                            display: "flex",
                            flexDirection:
                              i18n.language === "ar"
                                ? "justify-content-end"
                                : "justify-content-start",
                          }}
                        >
                          {t("profile.filter.remote")}
                        </h4>
                        <div className="mt-4">
                          <div
                            className="custom-control custom-radio"
                            style={{ display: "flex" }}
                          >
                            <div className="form-group mb-2">
                              <input
                                value="yes"
                                type="radio"
                                id="yesRemote"
                                name="customRadio4"
                                className="custom-control-input"
                                onChange={(e) => setRemote(e.target.value)}
                              />
                              <label
                                className="custom-control-label"
                                htmlFor="yesRemote"
                              >
                                {t("profile.filter.yes")}
                              </label>
                            </div>
                          </div>
                          <div
                            className="custom-control custom-radio"
                            style={{ display: "flex" }}
                          >
                            <div className="form-group mb-2">
                              <input
                                value="no"
                                type="radio"
                                id="noRemote"
                                name="customRadio4"
                                className="custom-control-input"
                                onChange={(e) => setRemote(e.target.value)}
                              />
                              <label
                                className="custom-control-label"
                                htmlFor="noRemote"
                              >
                                {t("profile.filter.no")}
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="widget mb-2 pb-2 border-bottom">
                        <h4
                          className="widget-title"
                          style={{
                            display: "flex",
                            flexDirection:
                              i18n.language === "ar"
                                ? "justify-content-end"
                                : "justify-content-start",
                          }}
                        >
                          {t("profile.filter.commitFulltime")}
                        </h4>
                        <div className="mt-4">
                          <div
                            className="custom-control custom-radio"
                            style={{ display: "flex" }}
                          >
                            <div className="form-group mb-2">
                              <input
                                value="yes"
                                type="radio"
                                id="yesFullTime"
                                name="customRadio2"
                                className="custom-control-input"
                                onChange={(e) =>
                                  setCommitFulltime(e.target.value)
                                }
                              />
                              <label
                                className="custom-control-label"
                                htmlFor="yesFullTime"
                              >
                                {t("profile.filter.yes")}
                              </label>
                            </div>
                          </div>
                          <div
                            className="custom-control custom-radio"
                            style={{ display: "flex" }}
                          >
                            <div className="form-group mb-2">
                              <input
                                value="no"
                                type="radio"
                                id="noFullTime"
                                name="customRadio2"
                                className="custom-control-input"
                                onChange={(e) =>
                                  setCommitFulltime(e.target.value)
                                }
                              />
                              <label
                                className="custom-control-label"
                                htmlFor="noFullTime"
                              >
                                {t("profile.filter.no")}
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="widget mb-2 pb-2 border-bottom">
                        <h4
                          className="widget-title"
                          style={{
                            display: "flex",
                            flexDirection:
                              i18n.language === "ar"
                                ? "justify-content-end"
                                : "justify-content-start",
                          }}
                        >
                          {t("profile.filter.equity")}
                        </h4>
                        <div className="mt-4">
                          <div
                            className="custom-control custom-radio"
                            style={{ display: "flex" }}
                          >
                            <div className="form-group mb-2">
                              <input
                                value="yes"
                                type="radio"
                                id="yesEquity"
                                name="customRadio1"
                                className="custom-control-input"
                                onChange={(e) => setEquity(e.target.value)}
                              />
                              <label
                                className="custom-control-label"
                                htmlFor="yesEquity"
                              >
                                {t("profile.filter.yes")}
                              </label>
                            </div>
                          </div>
                          <div
                            className="custom-control custom-radio"
                            style={{ display: "flex" }}
                          >
                            <div className="form-group mb-2">
                              <input
                                value="no"
                                type="radio"
                                id="noEquity"
                                name="customRadio1"
                                className="custom-control-input"
                                onChange={(e) => setEquity(e.target.value)}
                              />
                              <label
                                className="custom-control-label"
                                htmlFor="noEquity"
                              >
                                {t("profile.filter.no")}
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="widget">
                        <h4
                          className="widget-title"
                          style={{
                            display: "flex",
                            flexDirection:
                              i18n.language === "ar"
                                ? "justify-content-end"
                                : "justify-content-start",
                          }}
                        >
                          {t("profile.filter.whenStart")}
                        </h4>
                        <div className="mt-4">
                          <div
                            className="custom-control custom-radio"
                            style={{ display: "flex" }}
                          >
                            <div className="form-group mb-2">
                              <input
                                type="radio"
                                id="immediatley"
                                name="customRadio6"
                                value="immediately"
                                className="custom-control-input"
                                onChange={(e) => setWhenStart(e.target.value)}
                              />
                              <label
                                className="custom-control-label"
                                htmlFor="immediatley"
                              >
                                {t("profile.filter.immediately")}
                              </label>
                            </div>
                          </div>

                          <div
                            className="custom-control custom-radio"
                            style={{ display: "flex" }}
                          >
                            <div className="form-group mb-2">
                              <input
                                type="radio"
                                id="oneWeek"
                                name="customRadio6"
                                value="in one week"
                                className="custom-control-input"
                                onChange={(e) => setWhenStart(e.target.value)}
                              />
                              <label
                                className="custom-control-label"
                                htmlFor="oneWeek"
                              >
                                {t("profile.filter.oneWeek")}
                              </label>
                            </div>
                          </div>

                          <div
                            className="custom-control custom-radio"
                            style={{ display: "flex" }}
                          >
                            <div className="form-group mb-2">
                              <input
                                type="radio"
                                id="oneMonth"
                                name="customRadio6"
                                value="in one month"
                                className="custom-control-input"
                                onChange={(e) => setWhenStart(e.target.value)}
                              />
                              <label
                                className="custom-control-label"
                                htmlFor="oneMonth"
                              >
                                {t("profile.filter.oneMonth")}
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="widget">
                        <div className="mt-4">
                          <div className="row">
                            <div className="col-lg-6 col-12 mb-4 mb-lg-0">
                              <button
                                type="reset"
                                //(click)="clear()"
                                onClick={handelClear}
                                className="btn btn-outline-primary btn-block"
                              >
                                {t("profile.filter.clear")}
                              </button>
                            </div>
                            <div className="col-lg-6  col-12">
                              <button
                                type="submit"
                                // (click)="filter()"
                                data-toggle="modal"
                                data-target="#Contactme"
                                className="btn btn-block btn-primary"
                              >
                                {t("profile.filter.apply")}
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              )}
              {
                filterLoading ? (<Spinner />) : (

                  <div className="col-lg-8 col-md-6 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0 justify-content-between">
                    <div className="row">
                      {_DATA && _DATA?.maxPage > 0 ? (
                        _DATA?.currentData()?.map((user) => (
                          <div key={user._id} className="col-lg-6 col-12 mb-4">
                            {/* profile card */}
                            <div className="card job-box bg-light border-0 overflow-hidden">
                              <div className="text-center py-5 border-bottom rounded-top">
                                <Avatar
                                  style={{
                                    cursor: "pointer",
                                  }}
                                  sx={{
                                    bgcolor: "#335eea",
                                  }}
                                  src={user?.avatar?.url || user?.avatarLink}
                                  className="avatar avatar-medium mx-auto rounded-circle shadow d-block"
                                  alt=""
                                >
                                  <div
                                    style={{
                                      fontSize: "50px",
                                      textAlign: "center",
                                      paddingTop: "2rem",
                                    }}
                                  >
                                    {user.firstName.charAt(0)}
                                    {user.lastName.charAt(0)}
                                  </div>
                                </Avatar>

                                <h5
                                  className="mt-3 mb-0"
                                  style={{ cursor: "pointer", textAlign: "center" }}
                                >
                                  {user.firstName} {user.lastName}
                                </h5>
                                <p className="text-muted mb-0">{user.country} </p>
                                <p className="text-muted mb-3">{user.city}</p>
                                <Link
                                  to={`/user/${user._id}`}
                                  className="btn btn-primary mt-2 mr-2"
                                >
                                  <img src={userWhiteIcon} alt="user icon" />
                                  {t("profile.open")}
                                </Link>
                                {authUser?.type === "coFounder" ? (<Link
                                  to={`/messages/${user._id}`}
                                  className="btn btn-primary mt-2 mr-2"
                                >
                                  <img src={messageIcon} alt="user icon" />
                                  {t("chat.contacts.open")}
                                </Link>) : ("")}

                              </div>
                              <div className="card-body content position-relative">
                                <ul className="list-unstyled p-0">
                                  <span
                                    className="text-ellipsis--2 h6 ml-2"
                                    style={{
                                      textAlign:
                                        i18n.language === "ar" ? "right" : "left",
                                    }}
                                  >
                                    {user.tellMore}
                                  </span>
                                  <hr />

                                  <li className={"h6 d-flex " + (i18n.language === "ar" ? "text-right" : "text-left")}>
                                    <span style={{ color: "#37a11a " }}>gitHub:</span>{" "}
                                    <a
                                      className="socialLink"
                                      style={{
                                        textDecoration: "none",
                                        color: "#000",
                                      }}
                                      href={`https://github.com/${user?.gitHub}`}
                                      target="_blank"
                                      rel="noreferrer"
                                    >
                                      &nbsp;/{user?.gitHub}
                                    </a>
                                  </li>
                                  <li className={"h6 d-flex " + (i18n.language === "ar" ? "text-right" : "text-left")}>
                                    <span style={{ color: "#37a11a " }}>
                                      Linkedin:
                                    </span>{" "}
                                    <a
                                      className="socialLink"
                                      style={{
                                        textDecoration: "none",
                                        color: "#000",
                                      }}
                                      href={`https://linkedin.com/in/${user.linkedInProfile}`}
                                      target="_blank"
                                      rel="noreferrer"
                                    >
                                      &nbsp;/{user.linkedInProfile}
                                    </a>
                                  </li>
                                  <li className={"h6 d-flex " + (i18n.language === "ar" ? "text-right" : "text-left")}>
                                    <span style={{ color: "#37a11a " }}>
                                      Twitter:{" "}
                                    </span>{" "}
                                    <a
                                      className="socialLink"
                                      style={{
                                        textDecoration: "none",
                                        color: "#000",
                                      }}
                                      href={`https://twitter.com/${user.twitter}`}
                                      target="_blank"
                                      rel="noreferrer"
                                    >
                                      &nbsp;/{user.twitter}
                                    </a>
                                  </li>
                                  <hr />
                                  <li
                                    className={"h6 " + (i18n.language === "ar" ? "text-right" : "text-left")}
                                  >
                                    <span
                                      className="text-muted"
                                    >
                                      {t("profile.yearsExperience")}
                                    </span>
                                    &nbsp; {user.yearsExperience}
                                  </li>

                                  <li
                                    className={"h6 " + (i18n.language === "ar" ? "text-right" : "text-left")}
                                  >
                                    <span
                                      className="text-muted"
                                    >
                                      {t("profile.workedStartup")}{" "}
                                    </span>
                                    &nbsp;{" "}
                                    {t(
                                      `profile.expertise.${user.startupExperience.toLowerCase()}`
                                    )}
                                  </li>
                                  <li
                                    className={"h6 " + (i18n.language === "ar" ? "text-right" : "text-left")}
                                  >
                                    <span
                                      className="text-muted"
                                    >
                                      {t("profile.commitFulltime")}
                                    </span>
                                    &nbsp;
                                    {t(
                                      `profile.expertise.${user.commitFulltime.toLowerCase()}`
                                    )}
                                  </li>
                                  <li
                                    className={"h6 " + (i18n.language === "ar" ? "text-right" : "text-left")}
                                  >
                                    <span
                                      className="text-muted"
                                    >
                                      {t("profile.willingRelocate")}
                                    </span>
                                    &nbsp;
                                    {t(
                                      `profile.expertise.${user.relocate.toLowerCase()}`
                                    )}
                                  </li>
                                  <li
                                    className={"h6 " + (i18n.language === "ar" ? "text-right" : "text-left")}
                                  >
                                    <span
                                      className="text-muted"
                                    >
                                      {t("profile.equity")}
                                    </span>
                                    &nbsp;
                                    {t(
                                      `profile.expertise.${user.equity.toLowerCase()}`
                                    )}
                                  </li>
                                  <li
                                    className={"h6 " + (i18n.language === "ar" ? "text-right" : "text-left")}
                                  >
                                    <span
                                      className="text-muted"
                                    >
                                      {t("profile.whenStart")}
                                    </span>
                                    &nbsp;
                                    {t(
                                      `profile.filter.${user.whenStart.toLowerCase()}`
                                    )}
                                  </li>
                                  <li
                                    className={"h6 " + (i18n.language === "ar" ? "text-right" : "text-left")}
                                  >
                                    <span
                                      className="text-muted"
                                    >
                                      {t("profile.remote")}
                                    </span>{" "}
                                    &nbsp;
                                    {t(
                                      `profile.expertise.${user.remote.toLowerCase()}`
                                    )}
                                  </li>
                                  <li
                                    className={"h6 " + (i18n.language === "ar" ? "text-right" : "text-left")}
                                  >
                                    <span
                                      className="text-muted"
                                    >
                                      {t("profile.bestAt")}
                                    </span>
                                    &nbsp;
                                    {user.bestAt}
                                  </li>
                                  <li
                                    className={"h6 " + (i18n.language === "ar" ? "text-right" : "text-left")}
                                  >
                                    <span
                                      className="text-muted"
                                    >
                                      {t("profile.nbVisits")}
                                    </span>
                                    &nbsp;
                                    {user.nbVisits}
                                  </li>
                                </ul>

                                {authUser !== null ? (
                                  authUser?.type === "coFounder" ? ( //if the user is a cofounder he can contact the user if not he will be redirected to the cofounder signup page
                                    <div className="d-grid gap-2 col-12 mx-auto">
                                      <button
                                        onClick={() => {
                                          navigate(`/user/contact/${user._id}`);
                                          // createChat({ authUser, user });
                                        }}
                                        className="btn btn-primary btn-block"
                                      >
                                        {t("profile.contact")}
                                      </button>
                                    </div>
                                  ) : null
                                ) : (
                                  <div className="d-grid gap-2 col-12 mx-auto">
                                    <button
                                      className="btn btn-primary btn-block"
                                      type="button"
                                      onClick={() => {
                                        navigate("/cofounder-signup");
                                      }}
                                    >
                                      {t("profile.contact")}
                                    </button>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        ))
                      ) : (
                        //if there is no user found
                        <div className="row w-100 justify-content-center">
                          <h4
                            style={{
                              display: "flex",
                              flexDirection:
                                i18n.language === "ar"
                                  ? "justify-content-end"
                                  : "justify-content-start",
                            }}
                          >
                            {t("profile.not_found")}
                          </h4>
                        </div>
                      )}
                    </div>
                    {/**pagination */}
                    <Pagination
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                      }}
                      count={_DATA.maxPage}
                      page={_DATA.currentPage}
                      size="large"
                      variant="outlined"
                      shape="rounded"
                      onChange={(e, p) => _DATA.jump(p)}
                    />
                  </div>
                )
              }
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}
