// Description: This component is used to update the skills of the user
import React, { useEffect, useState } from "react";

import { technologies } from "../../assets/data";
import Loader from "../Loader/Loader";

import {
  Card,
  CardContent,
  Autocomplete,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import "./Skills.css";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useUser } from "../../hooks/auth";
import { useUpdateUserProfile } from "../../hooks/user";
export default function Skills() {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  const { isLoading: loading, data: user } = useUser();
  const {
    isLoading: updateLoading,
    error: updateError,
    message: updateMessage,
    mutate: update,
  } = useUpdateUserProfile();

  const [frontend, setFrontend] = useState(user?.fronted);
  const [backend, setBackend] = useState(user?.backend);
  const [devops, setDevops] = useState(user?.devops);
  const [design, setDesign] = useState(user?.design);
  const [database, setDatabase] = useState(user?.database);

  const submitHandler = async (e) => {
    e.preventDefault();
    update({
      ...user,
      frontend,
      backend,
      devops,
      design,
      database,
    });

    navigate("/account");
  };

  return loading || updateLoading ? (
    <Loader />
  ) : (
    <div className="userCard">
      <Card
        className="p-0"
        style={{ fontFamily: "Tajawal", paddingBottom: "40px" }}
      >
        <br />
        <div
          className="d-flex"
          style={{
            paddingLeft: "15px",
            paddingRight: "15px",
            paddingTop: "15px",
          }}
        >
          <b>Frontend</b>
        </div>
        <div
          className="d-flex"
          style={{ paddingLeft: "15px", paddingRight: "15px" }}
        >
          <CardContent
            style={{ paddingTop: "20px" }}
            className="edit-profile-field-total profile-prop-card-content"
          >
            <Autocomplete
              multiple
              ChipProps={{ color: "primary" }}
              // className="green-chip"
              //className="green-chip"
              color="primary"
              id="tags-outlined"
              //={(option) => option}
              options={technologies.frontEnd}
              value={frontend}
              defaultValue={user?.frontend}
              onChange={(event, newValue) => {
                setFrontend(newValue);
              }}
              filterSelectedOptions
              renderInput={(params) => (
                <TextField
                  color="primary"
                  className="edit-profile-field-total"
                  {...params}
                  label="skills"

                  //placeholder="Favorites"
                />
              )}
            />
          </CardContent>
        </div>
        <div
          className="d-flex"
          style={{
            paddingLeft: "15px",
            paddingRight: "15px",
            paddingTop: "15px",
          }}
        >
          <b>Backend</b>
        </div>
        <div
          className="d-flex"
          style={{ paddingLeft: "15px", paddingRight: "15px" }}
        >
          <CardContent
            style={{ paddingTop: "20px" }}
            className="edit-profile-field-total profile-prop-card-content"
          >
            <Autocomplete
              multiple
              ChipProps={{ color: "primary" }}
              // className="green-chip"
              //className="green-chip"
              color="primary"
              id="tags-outlined"
              //={(option) => option}
              options={technologies.backEnd}
              value={backend}
              defaultValue={user?.backend}
              onChange={(event, newValue) => {
                setBackend(newValue);
              }}
              filterSelectedOptions
              renderInput={(params) => (
                <TextField
                  color="primary"
                  className="edit-profile-field-total"
                  {...params}
                  label="skills"

                  //placeholder="Favorites"
                />
              )}
            />
          </CardContent>
        </div>
        <div
          className="d-flex"
          style={{
            paddingLeft: "15px",
            paddingRight: "15px",
            paddingTop: "15px",
          }}
        >
          <b>Devops</b>
        </div>
        <div
          className="d-flex"
          style={{ paddingLeft: "15px", paddingRight: "15px" }}
        >
          <CardContent
            style={{ paddingTop: "20px" }}
            className="edit-profile-field-total profile-prop-card-content"
          >
            <Autocomplete
              multiple
              ChipProps={{ color: "primary" }}
              // className="green-chip"
              //className="green-chip"
              color="primary"
              id="tags-outlined"
              //={(option) => option}
              options={technologies.devOps}
              value={devops}
              defaultValue={user?.devops}
              onChange={(event, newValue) => {
                setDevops(newValue);
              }}
              filterSelectedOptions
              renderInput={(params) => (
                <TextField
                  color="primary"
                  className="edit-profile-field-total"
                  {...params}
                  label="skills"

                  //placeholder="Favorites"
                />
              )}
            />
          </CardContent>
        </div>
        <div
          className="d-flex"
          style={{
            paddingLeft: "15px",
            paddingRight: "15px",
            paddingTop: "15px",
          }}
        >
          <b>Design</b>
        </div>
        <div
          className="d-flex"
          style={{ paddingLeft: "15px", paddingRight: "15px" }}
        >
          <CardContent
            style={{ paddingTop: "20px" }}
            className="edit-profile-field-total profile-prop-card-content"
          >
            <Autocomplete
              multiple
              ChipProps={{ color: "primary" }}
              // className="green-chip"
              //className="green-chip"
              color="primary"
              id="tags-outlined"
              //={(option) => option}
              options={technologies.Design}
              value={design}
              defaultValue={user?.design}
              onChange={(event, newValue) => {
                setDesign(newValue);
              }}
              filterSelectedOptions
              renderInput={(params) => (
                <TextField
                  color="primary"
                  className="edit-profile-field-total"
                  {...params}
                  label="skills"

                  //placeholder="Favorites"
                />
              )}
            />
          </CardContent>
        </div>
        <div
          className="d-flex"
          style={{
            paddingLeft: "15px",
            paddingRight: "15px",
            paddingTop: "15px",
          }}
        >
          <b>database</b>
        </div>
        <div
          className="d-flex"
          style={{ paddingLeft: "15px", paddingRight: "15px" }}
        >
          <CardContent
            style={{ paddingTop: "20px" }}
            className="edit-profile-field-total profile-prop-card-content"
          >
            <Autocomplete
              multiple
              ChipProps={{ color: "primary" }}
              // className="green-chip"
              //className="green-chip"
              color="primary"
              id="tags-outlined"
              //={(option) => option}
              options={technologies.dataBase}
              value={database}
              defaultValue={user?.database}
              onChange={(event, newValue) => {
                setDatabase(newValue);
              }}
              filterSelectedOptions
              renderInput={(params) => (
                <TextField
                  color="primary"
                  className="edit-profile-field-total"
                  {...params}
                  label="skills"

                  //placeholder="Favorites"
                />
              )}
            />
          </CardContent>
        </div>
        <br />
        <br />
        <br />
        <div
          className="d-flex"
          style={{
            justifyContent: "center",
            paddingLeft: "30%",
            paddingRight: "30%",
          }}
        >
          <button
            //(click)="update()"
            onClick={submitHandler}
            className="update-button"
          >
            {t("profile.editSkills.save")}
          </button>
        </div>
        <br />
      </Card>
    </div>
  );
}
