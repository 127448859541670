import styled from "styled-components"
import { useState, useEffect, useRef } from 'react'
import Contacts from "./Contacts";
import Welcome from "./Welcome";
import Messages from "./Messages";
import { io } from "socket.io-client";
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import Banner from "../Banner/Banner";
export default function Chats({currentUser}) {
  const socket = useRef();
  const [currentChat, setCurrentChat] = useState(undefined);
  const storageDirection = localStorage.getItem('direction') || 'ltr'
	// Switch Languages function
	const [direction, setDirection] = useState(storageDirection);
  const { i18n } = useTranslation();
  const isMobile = useMediaQuery({ query: `(max-width: 760px)` });
 
  useEffect(() => {
    
    if (currentUser) {
      socket.current = io(process.env.REACT_APP_SOCKET_BASE_URL);
      socket.current.emit("add-user", currentUser._id);
    }
  
  }, [currentUser]);

  useEffect(() => {
    localStorage.setItem('direction', i18n.language === 'en' ? 'ltr' : 'rtl');
    setDirection(i18n.language === 'en' ? 'ltr' : 'rtl')
	}, [i18n.language])

  const handleChatChange = (chat) => {
    setCurrentChat(chat);
  }

  return (
    <Container>
      <div className="container p-1">
        <div className={"contacts-container " + (!isMobile ? direction == "rtl" ? "pl-1 border-left" : "pr-1 border-right" : '')}>
          {currentUser ? <Contacts currentUser={currentUser} changeChat={handleChatChange} /> : null }
        </div>
        {currentChat === undefined ?
          <Welcome currentUser={currentUser} /> :
          <Messages currentChat={currentChat} currentUser={currentUser} socket={socket} />
        }
      </div>
    </Container>
  )
}

const Container = styled.div`
  height: 85vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1rem;
  align-items: center;
  .container {
    height: 80vh;
    width: 85vw;
    border: 1px solid #4CA11C;
    display: grid;
    grid-template-columns: 25% 75%;
    @media screen and (min-width: 720px) and (max-width: 1080px) {
      grid-template-columns: 35% 65%;
    }
    .contacts-container{
      border-color: #4CA11C !important;
     
    }
    @media screen and (max-width: 760px) {
      border-bottom: 1px solid #4CA11C;
      grid-template-columns: 100%;
      grid-template-rows:20% 80%;
      .contacts-container{
        border-bottom:1px solid #4CA11C;

        border-right: none;
      }
    }
    .contacts-container {
      overflow-y: auto;
    }
  }
  

`;
