import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

export default function Welcome({ currentUser }) {
  const { t, i18n } = useTranslation();

  return (
    <Container>
      <div className='mobile'>
        <h2 className='text-center text-lg mt-5'>
          {t('chat.welcome')}, <span>{currentUser.firstName}!</span>
        </h2>
        <h3 className='text-center'>{t('chat.chatHint')}.</h3>
      </div>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  color: white;
  span {
    color: #37a11a;
    text-transform: capitalize;
  }
  @media (max-width: 900px) {
    h1{
      font-size: 1.5rem ;
    }
    h3{
      font-size: 1rem ;
      
    }
  }
`;

