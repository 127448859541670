import axios from "axios";

const API = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  withCredentials: true,
});

/*function to check if the user email is already in the database since the sign up is a wizard
 and we need to check if the user email is already in the database before we move to the next step*/
export const userEmail = async (email) => {
  try {
    const { success } = await API.post("/userEmail", { email }).then(
      (res) => res.data
    );
    return success;
  } catch (error) {
    throw error;
  }
};

/*
function to register a new user using email in the database and create a new chat user in the chat engine(we are using chat engine for the chat but it not working yet)
 */
export const register = async (userInfo) => {
  try {
    const { user } = await API.post("/register", { userInfo }).then(
      (res) => res.data
    );
    // const { chat } = await axios.post(
    //   "https://api.chatengine.io/users/",
    //   {
    //     username: user?.email,
    //     secret: user?._id,
    //     first_name: user?.firstName,
    //     last_name: user?.lastName,
    //     email: user?.email,
    //   },
    //   {
    //     headers: {
    //       "PRIVATE-KEY": "183dc15a-0e0c-4844-b455-f63c6db4df15",
    //     },
    //   }
    // );

    return user;
  } catch (error) {
    throw error;
  }
};
//login function using email and password to login
export const login = async (userInfo) => {
  try {
    const { user } = await API.post("/login", { userInfo }).then(
      (res) => res.data
    );
    return user;
  } catch (error) {
    throw error;
  }
};

//logout function
export const logout = async () => {
  try {
    const { message } = await API.get("/logout").then((res) => res.data);
    return message;
  } catch (error) {
    throw error;
  }
};

// currentUser is a function that returns the current user
export const currentUser = async () => {
  try {
    const { user } = await API.get("/current-user").then((res) => res.data);
    return user;
  } catch (error) {
    throw error;
  }
};

/*social sign up for twitter and github. we want to create a new user in the database and create a new chat user in the chat engine 
userInfo has the user info from the social media(mainly the access token and twitter/github account first name and last name) 
and the user info from the wizard

*/
export const socialSignUp = async (userInfo) => {
  try {
    const { user } = await API.post("/register-social", {
      userInfo,
    }).then((res) => res.data);
    // const { chat } = await axios.post(
    //   "https://api.chatengine.io/users/",
    //   {
    //     username: user?.email,
    //     secret: user?._id,
    //     first_name: user?.firstName,
    //     last_name: user?.lastName,
    //     email: user?.email,
    //   },
    //   {
    //     headers: {
    //       //  "PRIVATE-KEY": "183dc15a-0e0c-4844-b455-f63c6db4df15",
    //       "PRIVATE-KEY": "183dc15a-0e0c-4844-b455-f63c6db4df15",
    //     },
    //   }
    // );
    return user;
  } catch (error) {
    throw error;
  }
};

/*
social login for twitter and github. we want to login the user in the database and return the user info
 */
export const socialLogin = async (socialUser) => {
  try {
    const { user } = await API.post("/login-social", {
      socialUser,
    }).then((res) => res.data);
    return user;
  } catch (error) {
    throw error;
  }
};

//function to send the email to the user to reset the password firebase will send the email and take care of the reset password process
export const forgotPassword = async (email) => {
  try {
    const { message } = await API.post("/forgot/password", {
      email,
    }).then((res) => res.data);
    return message;
  } catch (error) {
    throw error;
  }
};
