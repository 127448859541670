import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  register,
  currentUser,
  login,
  logout,
  socialSignUp,
  socialLogin,
  userEmail,
  forgotPassword,
} from "../api/auth";

export const useRegister = () => {
  const navigate = useNavigate();
  const { t } = useTranslation(); //t is the translation function from react-i18next

  const queryClient = useQueryClient();
  const { mutate, isLoading, error } = useMutation(
    ["user"],
    (user) => register(user),
    {
      onSuccess: (data) => {
        navigate("/confirm", { replace: true }); // Navigate to confirm page to let them know to check their email

        queryClient.invalidateQueries({ queryKey: ["user"] }); // Invalidate the user query so current user is requested again

        // Show a success toast
        toast.success(t("signup.success"), {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      },
      onError: (error) => {
        // Show an error toast
        toast.error(t("signup.error"), {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      },
    }
  );
  //mutate is the function that is called to trigger the mutation AKA the register function in api/auth
  return { mutate, isLoading, error };
};

export const useLogin = () => {
  const { t } = useTranslation();

  const queryClient = useQueryClient();
  const { mutate, isLoading, error } = useMutation(
    ["user"],
    (userInfo) => login(userInfo),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ["user"] }); // Invalidate the user query so current user is requested again
        // Show a success toast
        toast.success(t("login.success"), {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      },
      onError: (error) => {
        // Show an error toast
        toast.error(t("login.error"), {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      },
    }
  );

  return { mutate, isLoading, error };
};

export const useLogout = () => {
  const queryClient = useQueryClient();

  const { data, isLoading, isError, refetch } = useQuery(
    ["message"],
    () => logout(),
    {
      enabled: false,
      onSuccess: () => {
        queryClient.setQueryData(["user"], null); // Set the user query data to null so the user is no longer logged in
      },
    }
  );

  return { data, isLoading, isError, refetch };
};

export const useUser = () => {
  const queryClient = useQueryClient();
  const { data, isLoading, isError, refetch } = useQuery(
    ["user"],
    () => currentUser(), // This is the function that is called to get the current user
    {
      onError: (error) => {
        queryClient.setQueryData(["user"], null); // Set the user query data to null so the user is no longer logged in if there is an error
      },
    }
  );

  return { data, isLoading, isError, refetch };
};
export const useSocialRegister = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const queryClient = useQueryClient();
  const { mutate, isLoading, error } = useMutation(
    ["user"],
    (userInfo) => socialSignUp(userInfo), //this is the function that is called to trigger the mutation AKA the socialSignUp function in api/auth
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries({ queryKey: ["user"] }); // Invalidate the user query so current user is requested again
        navigate("/account", { replace: true });
        toast.success(t("signup.success"), {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      },
      onError: (error) => {
        // Show an error toast
        toast.error(t("signup.error"), {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      },
    }
  );

  return { mutate, isLoading, error };
};

export const useSocialLogin = () => {
  const { t } = useTranslation();

  const queryClient = useQueryClient();
  const { mutate, isLoading, error } = useMutation(
    ["user"],
    (socialUser) => socialLogin(socialUser), //this is the function that is called to trigger the mutation AKA the socialLogin function in api/auth
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries({ queryKey: ["user"] }); // Invalidate the user query so current user is requested again
        toast.success(t("login.success"), {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      },
      onError: (error) => {
        // Show an error toast
        toast.error(t("login.error"), {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      },
    }
  );

  return { mutate, isLoading, error };
};

export const useUserEmail = () => {
  const { t } = useTranslation();

  const { mutate, isLoading, error, data } = useMutation(
    ["success"],
    (email) => userEmail(email), //this is the function that is called to trigger the mutation AKA the userEmail function in api/auth to check if the email is already in use
    {
      onError: (error) => {
        // Show an error toast
        toast.error(t("signup.user_exist"), {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      },
    }
  );

  return { mutate, isLoading, error, data };
};

export function useMutationForgotPassword() {
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const { data, mutate, isLoading, isError } = useMutation(
    ["message"],
    (email) => forgotPassword(email), //this is the function that is called to trigger the mutation AKA the forgotPassword function in api/auth which sends the email to the user
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries({ queryKey: ["user"] }); // Invalidate the user query so current user is requested again
        // Show a success toast
        toast.success(t("forgot.success"), {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      },
      onError: (error) => {
        // Show an error toast
        toast.error(t("forgot.error"), {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      },
    }
  );

  return { data, mutate, isLoading, isError };
}
