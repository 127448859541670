//pagination component is used to paginate the data in the table i got this code from the internet and i have modified it to suit my needs
import { useState } from "react";

function usePagination(data) {
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(6); //how many profiles to show per page
  const maxPage = Math.ceil(data?.length / rowsPerPage);

  function currentData() {
    const begin = (currentPage - 1) * rowsPerPage;
    const end = begin + rowsPerPage;
    return data?.slice(begin, end);
  }

  function next() {
    setCurrentPage((currentPage) => Math.min(currentPage + 1, maxPage));
  }

  function prev() {
    setCurrentPage((currentPage) => Math.max(currentPage - 1, 1));
  }

  function jump(page) {
    const pageNumber = Math.max(1, page);
    setCurrentPage((currentPage) => Math.min(pageNumber, maxPage));
  }
  const handleChangePage = (newPage) => {
    setCurrentPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setCurrentPage(1);
  };

  return {
    next,
    prev,
    jump,
    currentData,
    handleChangePage,
    handleChangeRowsPerPage,
    rowsPerPage,
    currentPage,
    maxPage,
  };
}

export default usePagination;
