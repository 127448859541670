// Description: This is the FAQ page
import React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Footer from "../Footer/Footer";
import { useTranslation } from "react-i18next";

import "./Faq.css";
import { Link } from "react-router-dom";
import Banner from "../Banner/Banner";
export default function Faq() {
  const { t, i18n } = useTranslation();
  const faq = [
    {
      title: "What is imateco.com?",
      text: "It's a mobile & desktop site that allows technical resources that want to join technology startups to register and get discovered.",
      link: "signup",
      url: "/signup",
    },
    {
      title: "Who should register?",
      text: "If you are a A) technical person and B) want to be a cofounder in a tech startup, register so non technical founders looking for “CTOs” can find you and reach out to you.",
      link: "",
      url: "",
    },
    {
      title: "Is it free?",

      text: "Yes, its 100% free to register and search, it's our contribution to a better tech ecosystem ",
      link: "",
      url: "",
    },
    {
      title: "If it's free how will it be sustainable",
      text: "We will eventually accept donation and sponsors for imateco.com, the same model as Wikipedia ",
      link: "",
      url: "",
    },
    {
      title: "Will my profile be visible after I register?",
      text: "Yes, we had to make sure that people looking for a technical cofounder can just search profiles without having to register.  This way we keep the user base of imateco focused on what it really should be , technical talent that has a desire to join a startup. ",
      link: "",
      url: "",
    },
    {
      title: "Will there be other languages than Arabic and English?",
      text: "The site is done intentionally to allow for other languages , but we made a decision to launch in English as the common denominator and also Arabic.",
      link: "",
      url: "",
    },
    {
      title: "Will I find my cofounder on imateco?",
      text: "Maybe, it's actually harder to find the right co-founder than to get accepted into Stanford (Acceptance into Stanford is ~5%) , identifying a getting along with a co founder takes months, and a lot of coffees and late night discussions.  You will not just contact someone on imateco and expect that A) they will want to join you or B) they are the right technical resource as a CTO for your startups.  imateco is the starting point, the first step in a thousand steps to get a technical cofounder.",
      link: "",
      url: "",
    },
    {
      title:
        "I’m a technical person, and i wanna be a cofounder, and I registered , how do I make sure I get contacted?",
      text: "The first issue is tactical,  and it is related to your registered email, when you register via email, LinkedIn, or Twitter, make sure you use an account that you regularly use, because when someone contacts you on the site, that contact goes in the form of an email to your register email. \nThe second issue is your profile, you need to sell yourself, make sure you go after registration and complete your profile, fill in great content in the “Tell us more about yourself” section, this is where you can shin and differentiate your profile than the others, a complete profile simply gets more contact requests.",
      link: "",
      url: "",
    },
    {
      title:
        "Why didn't you make the site registration by invite only to filter for quality?",
      text: "For two reasons, the first is who are we to judge if you are good or not.   Some of the registered users could be junior developers that turn out to be amazing cofounders, it's not our role to decide who is good and who is not , all we are doing is giving visibility to technical people that want to become co founders. \nThe second reason is that the really good CTOs already are co founders or have amazing jobs, and the really really good ones already have startups, so we are not worried about that category, we want to give a change to the many many technical talents that just do not have visibility.",
      link: "",
      url: "",
    },
    {
      title: "I can only see a select list of countries at registration?",
      text: "Yes that is correct, we are rolling imateco.com out to a select countries for now since phase I of the goal is to connect potential founders together, and wanted to minimize distance as a factor.  Of course once we demonstrate that imateco is needed, we will be allowing registration from other regions.",
      link: "",
      url: "",
    },
    {
      title:
        "Will you provide us content to what makes a great cofounder and what are the typical equity percentages etc..?",
      text: "Yes, we are planning to start publishing best practice guidelines that are related to co founders and team building, team psychology and typical compensation.  And if you have any useful material please share it with us",
      link: "",
      url: "",
    },
    {
      title: "Is imateco.com a job’s site?",
      text: "We hope not, we want to make sure that the people that are registering are genuine and really want to become technical cofounders in a startup.",
      link: "",
      url: "",
    },
    {
      title: "Why do I need a technical co-founder?",
      text: "Technical startups are based on the in-house technical skills and abilities of the founders, as it creates a durable base for the startup and ensures longevity. One of the criterias startups must have is the involvement of at least one technical partner and one business partner, a doer and a seller.",
      link: "",
      url: "",
    },
    {
      title:
        "أنا مؤسس وأريد التواصل بمطور لأكون أحد مؤسسي ، كيف يمكنني التواصل بهم؟",
      text: "عند النقر فوق الزر 'تواصل' ، ستبدأ محادثة دردشة معهم. يرجى الملاحظة يستلزم عليك التسجيل وتأكيد حسابك قبل أن تتمكن من الدردشة مع أي شخص على IMATECO وهذا يسمح لنا بمعرفة ما إذا كان الأشخاص يستجيبون لبعضهم البعض أم لا ونقوم عادةً بإزالة الملفات الشخصية الخاملة بعد فترة",
      link: "",
      url: "",
    },
  ];

  return (
    <div>
      <br />
      <div className=" container">
        <div className="row align-content-center justify-content-center">
          {/*
          ads
          */}
          <Banner />
          {/*
          ads
          */}
          <div className="col-xl-2 col-lg-2 col-md-12 col-sm-12 pt-3"></div>
          <section
            className="col-xl-7 col-lg-7 col-md-12 col-sm-12"
            style={{
              // paddingLeft: "4rem",
              // paddingRight: "4rem",
              paddingBottom: "5rem",
            }}
          >
            <h3 className="title-faq">{t("global.faq")}</h3>

            {faq.map((value, key) => (
              <Accordion
                key={key}
                style={{
                  direction: i18n.language === "ar" ? "rtl" : "ltr",
                }}
              >
                <AccordionSummary
                  className="question"
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography
                    style={{
                      textAlign: i18n.language === "ar" ? "right" : "left",
                      fontSize: i18n.language === "ar" ? "14px" : "16px",
                    }}
                    className="question-title"
                  >
                    {t(`faq.title${key + 1}`)}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography
                    style={{
                      textAlign: i18n.language === "ar" ? "right" : "left",
                      fontSize: i18n.language === "ar" ? "14px" : "16px",
                    }}
                    className="question-description"
                  >
                    {t(`faq.text${key + 1}`)}
                    <Link to={t(`faq.url${key + 1}`)}>
                      {t(`faq.link${key + 1}`)}
                    </Link>
                  </Typography>
                </AccordionDetails>
              </Accordion>
            ))}
          </section>
          <div className="col-xl-2 col-lg-2 col-md-12 col-sm-12 pt-3"></div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
