import React, { Suspense } from "react";
import { BrowserRouter } from "react-router-dom";
// import ReactDOM from "react-dom";
import App from "./App";
import ReactDOM from "react-dom/client";

import { StyledEngineProvider } from "@mui/material/styles";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import "bootstrap/dist/css/bootstrap.min.css";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import "./i18n";
import "./index.css";
import Loader from "./Components/Loader/Loader";
import i18n from "./i18n";
const themeOptions = createTheme({
  direction: i18n.language === "ar" ? "rtl" : "ltr",
  palette: {
    type: "light",
    primary: {
      main: "#37a11a",
      contrastText: "rgba(255,255,255,0.87)",
    },
    error: {
      main: "#e43f52",
    },
  },
});
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
});
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
    <Suspense fallback={<Loader />}>
      <ThemeProvider theme={themeOptions}>
        <StyledEngineProvider injectFirst>
          <QueryClientProvider client={queryClient}>
            <BrowserRouter>
              <App />
            </BrowserRouter>
            <ReactQueryDevtools initialIsOpen={false} />
          </QueryClientProvider>
        </StyledEngineProvider>
      </ThemeProvider>
    </Suspense>
  // </React.StrictMode>
);
