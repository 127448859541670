import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";

import { useState, useEffect, Suspense } from "react";
import Header from "./Components/Header/Header";
import Login from "./Components/Login/Login";
import Home from "./Components/Home/Home";

import Account from "./Components/Account/Account";

import Register from "./Components/Register/Register";

//import UpdatePassword from "./Components/UpdatePassword/UpdatePassword";
import ForgotPassword from "./Components/ForgotPassword/ForgotPassword";
//import ResetPassword from "./Components/ResetPassword/ResetPassword";
import UserProfile from "./Components/UserProfile/UserProfile";

import NotFound from "./Components/NotFound/NotFound";
import Profiles from "./Components/Profiles/Profiles";

import Partners from "./Components/Partners/Partners";
import About from "./Components/About/About";
import Faq from "./Components/Faq/Faq";
import Terms from "./Components/TermsAndPrivacy/Terms";
import Privacy from "./Components/TermsAndPrivacy/Privacy";
import SendConfirmUsers from "./Components/ConfirmUsers/SendConfirmUsers";
import UserConfirmed from "./Components/ConfirmUsers/UserConfirmed";
import Loader from "./Components/Loader/Loader";
import "bootstrap/dist/css/bootstrap.min.css";
import EditPersonal from "./Components/UpdateProfile/EditPersonal";
import AboutMe from "./Components/UpdateProfile/AboutMe";
import Expertise from "./Components/UpdateProfile/Expertise";
import Skills from "./Components/UpdateProfile/Skills";
import SocialLinks from "./Components/UpdateProfile/SocialLinks";
import "./App.css";
import ContactUser from "./Components/ContactUser/ContactUser";
import Blog from "./Components/Blog/Blog";
import Post from "./Components/Blog/Post";
import CoFounderSignUp from "./Components/Register/CoFounderSignUp";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useUser } from "./hooks/auth";
import Fab from '@mui/material/Fab';
import MessageOutlinedIcon from '@mui/icons-material/MessageOutlined';
import Chats from "./Components/Chat/Chats";
import { Link } from "react-router-dom";

function App() {
  const { data } = useUser();
  const [chat, setChat] = useState("Data has not been fetched yet!");
  useEffect(() => {
    document.title = `Find Your Technical Co-founder`;
  });

  return (
    <div>
      <Header />
      <Routes>
        <Route path="/load" element={<Loader />} />
        <Route
          path="/"
          element={
            <Suspense fallback={<Loader />}>
              <Home />
            </Suspense>
          }
        />
        <Route path="/us" element={<About />} />
        <Route path="/profiles" element={<Profiles />} />
        <Route path="/partners" element={<Partners />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route
          path="/login"
          element={data ? <Navigate to="/account" replace={true} /> : <Login />}
        />
        <Route path="/faq" element={<Faq />} />
        {/* <Route
          path="/chat"
          element={data ? <Chat /> : <Navigate to="/login" replace={true} />}
        /> */}
        <Route path="/blog" element={<Blog />} />
        <Route path="/blog/:blogId/:id" element={<Post />} />
        <Route
          path="/signup"
          element={
            data ? <Navigate to="/account" replace={true} /> : <Register />
          }
        />
        <Route
          path="/cofounder-signup"
          element={
            data ? (
              <Navigate to="/account" replace={true} />
            ) : (
              <CoFounderSignUp />
            )
          }
        />

        <Route path="/forgot/password" element={<ForgotPassword />} />
        <Route path="/user/:id" element={<UserProfile />} />
        <Route path="/user/contact/:id" element={<ContactUser />} />

        <Route path="/confirm" element={<SendConfirmUsers />} />
        <Route path="/confirm/:email" element={<UserConfirmed />} />
        <Route
          path="/account"
          element={data ? <Account /> : <Navigate to="/login" replace={true} />}
        />
        <Route
          path="/account/edit-personal"
          element={
            data ? <EditPersonal /> : <Navigate to="/login" replace={true} />
          }
        />
        <Route
          path="/account/about-me"
          element={data ? <AboutMe /> : <Navigate to="/login" replace={true} />}
        />
        <Route
          path="/account/expertise"
          element={
            data ? <Expertise /> : <Navigate to="/login" replace={true} />
          }
        />
        <Route
          path="/account/skills"
          element={data ? <Skills /> : <Navigate to="/login" replace={true} />}
        />
        <Route
          path="/account/social-links"
          element={
            data ? <SocialLinks /> : <Navigate to="/login" replace={true} />
          }
        />

        <Route path='/messages/:id'
          element={
            data ? <Chats currentUser={data} /> : <Navigate to="/login" replace={true} />
          }
        />

        <Route path='/messages'
          element={
            data ? <Chats currentUser={data} /> : <Navigate to="/login" replace={true} />
          }
        />
        <Route path="*" element={<NotFound />} />
      </Routes>
      <ToastContainer />
      {data ?
        <Fab color="primary" aria-label="add" sx={{
          position: 'fixed',
          bottom: 5,
          right: 5,
        }}>
          <Link to={"/messages"} className="text-heilight">
            <MessageOutlinedIcon color="white" />
          </Link>
        </Fab>
        : ''}

    </div>
  );
}

export default App;
