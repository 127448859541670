// Description: This component is used to update the user's expertise
import React, { useEffect, useState } from "react";

import { data } from "../../assets/data";
import Loader from "../Loader/Loader";

import {
  Card,
  CardContent,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";

import "./Expertise.css";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useUser } from "../../hooks/auth";
import { useUpdateUserProfile } from "../../hooks/user";
export default function Expertise() {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const { isLoading: loading, data: user } = useUser();
  const {
    isLoading: updateLoading,
    error: updateError,
    message: updateMessage,
    mutate: update,
  } = useUpdateUserProfile();

  const [yearsExperience, setYearsExperience] = useState(user?.yearsExperience);
  const [startupExperience, setStartupExperience] = useState(
    user?.startupExperience
  );
  const [relocate, setRelocate] = useState(user?.relocate);
  const [commitFulltime, setCommitFulltime] = useState(user?.commitFulltime);
  const [remote, setRemote] = useState(user?.remote);
  const [equity, setEquity] = useState(user?.equity);
  const [whenStart, setWhenStart] = useState(user?.whenStart);
  const [bestAt, setBestAt] = useState(user?.bestAt);
  const submitHandler = async (e) => {
    e.preventDefault();
    update({
      ...user,
      yearsExperience,
      startupExperience,
      relocate,
      commitFulltime,
      remote,
      equity,
      whenStart,
      bestAt,
    });

    navigate("/account");
  };

  return loading || updateLoading ? (
    <Loader />
  ) : (
    <div
      style={{ direction: i18n.language === "ar" ? "rtl" : "ltr" }}
      className="userCard"
    >
      <br />
      <Card
        className="p-0 expertise"
        style={{ fontFamily: "Tajawal", paddingBottom: "40px" }}
      >
        <CardContent
          className="mr-lg-r ml-lg-5 pl-lg-5 pr-lg-5 pt-lg-5"
          style={{ paddingTop: "20px" }}
        >
          <div
            className="d-flex"
            style={{
              paddingLeft: "15px",
              paddingRight: "15px",
              justifyContent:
                i18n.language === "ar" ? "flex-end" : "flex-start",
            }}
          >
            <h5>{t("profile.expertise.yearsExperience")}</h5>
          </div>
          <div
            className="d-flex"
            style={{
              justifyContent: "center",
              paddingLeft: "15px",
              paddingRight: "15px",
            }}
          >
            <ToggleButtonGroup
              style={{ minWidth: "80%" }}
              value={yearsExperience}
              exclusive
              onChange={(e, newValue) => setYearsExperience(newValue)}
            >
              <ToggleButton style={{ minWidth: "33%" }} value="0-3">
                0-3
              </ToggleButton>
              <ToggleButton style={{ minWidth: "33%" }} value="3-7">
                3-7
              </ToggleButton>
              <ToggleButton style={{ minWidth: "33%" }} value="7+">
                7+
              </ToggleButton>
            </ToggleButtonGroup>
          </div>
          <br />

          <div
            className="d-flex"
            style={{
              paddingLeft: "15px",
              paddingRight: "15px",
              justifyContent:
                i18n.language === "ar" ? "flex-end" : "flex-start",
            }}
          >
            <h5>{t("profile.expertise.workedStartup")}</h5>
          </div>
          <div
            className="d-flex"
            style={{
              justifyContent: "center",
              paddingLeft: "15px",
              paddingRight: "15px",
            }}
          >
            <ToggleButtonGroup
              style={{ minWidth: "80%" }}
              value={startupExperience}
              exclusive
              onChange={(e, newValue) => setStartupExperience(newValue)}
            >
              <ToggleButton style={{ minWidth: "50%" }} value="yes">
                {t("profile.expertise.yes")}
              </ToggleButton>
              <ToggleButton style={{ minWidth: "50%" }} value="no">
                {t("profile.expertise.no")}
              </ToggleButton>
            </ToggleButtonGroup>
          </div>
          <br />
          <div
            className="d-flex"
            style={{
              paddingLeft: "15px",
              paddingRight: "15px",
              justifyContent:
                i18n.language === "ar" ? "flex-end" : "flex-start",
            }}
          >
            <h5>{t("profile.expertise.commitFulltime")}</h5>
          </div>
          <div
            className="d-flex"
            style={{
              justifyContent: "center",
              paddingLeft: "15px",
              paddingRight: "15px",
            }}
          >
            <ToggleButtonGroup
              style={{ minWidth: "80%" }}
              value={commitFulltime}
              exclusive
              onChange={(e, newValue) => setCommitFulltime(newValue)}
            >
              <ToggleButton style={{ minWidth: "50%" }} value="yes">
                {t("profile.expertise.yes")}
              </ToggleButton>
              <ToggleButton style={{ minWidth: "50%" }} value="no">
                {t("profile.expertise.no")}
              </ToggleButton>
            </ToggleButtonGroup>
          </div>
          <br />
          <div
            className="d-flex"
            style={{
              paddingLeft: "15px",
              paddingRight: "15px",
              justifyContent:
                i18n.language === "ar" ? "flex-end" : "flex-start",
            }}
          >
            <h5>{t("profile.expertise.willingRelocate")}</h5>
          </div>
          <div
            className="d-flex"
            style={{
              justifyContent: "center",
              paddingLeft: "15px",
              paddingRight: "15px",
            }}
          >
            <ToggleButtonGroup
              style={{ minWidth: "80%" }}
              value={relocate}
              exclusive
              onChange={(e, newValue) => setRelocate(newValue)}
            >
              <ToggleButton style={{ minWidth: "50%" }} value="yes">
                {t("profile.expertise.yes")}
              </ToggleButton>
              <ToggleButton style={{ minWidth: "50%" }} value="no">
                {t("profile.expertise.no")}
              </ToggleButton>
            </ToggleButtonGroup>
          </div>
          <br />
          <div
            className="d-flex"
            style={{
              paddingLeft: "15px",
              paddingRight: "15px",
              justifyContent:
                i18n.language === "ar" ? "flex-end" : "flex-start",
            }}
          >
            <h5>{t("profile.expertise.remote")}</h5>
          </div>
          <div
            className="d-flex"
            style={{
              justifyContent: "center",
              paddingLeft: "15px",
              paddingRight: "15px",
            }}
          >
            <ToggleButtonGroup
              style={{ minWidth: "80%" }}
              value={remote}
              exclusive
              onChange={(e, newValue) => setRemote(newValue)}
            >
              <ToggleButton style={{ minWidth: "50%" }} value="yes">
                {t("profile.expertise.yes")}
              </ToggleButton>
              <ToggleButton style={{ minWidth: "50%" }} value="no">
                {t("profile.expertise.no")}
              </ToggleButton>
            </ToggleButtonGroup>
          </div>
          <br />
          <div
            className="d-flex"
            style={{
              paddingLeft: "15px",
              paddingRight: "15px",
              justifyContent:
                i18n.language === "ar" ? "flex-end" : "flex-start",
            }}
          >
            <h5>{t("profile.expertise.equity")}</h5>
          </div>
          <div
            className="d-flex"
            style={{
              justifyContent: "center",
              paddingLeft: "15px",
              paddingRight: "15px",
            }}
          >
            <ToggleButtonGroup
              style={{ minWidth: "80%" }}
              value={equity}
              exclusive
              onChange={(e, newValue) => setEquity(newValue)}
            >
              <ToggleButton style={{ minWidth: "50%" }} value="yes">
                {t("profile.expertise.yes")}
              </ToggleButton>
              <ToggleButton style={{ minWidth: "50%" }} value="no">
                {t("profile.expertise.no")}
              </ToggleButton>
            </ToggleButtonGroup>
          </div>
          <br />
          <div
            className="d-flex"
            style={{
              paddingLeft: "15px",
              paddingRight: "15px",
              justifyContent:
                i18n.language === "ar" ? "flex-end" : "flex-start",
            }}
          >
            <h5>{t("profile.expertise.whenStart")}</h5>
          </div>
          <div
            className="d-flex"
            style={{
              justifyContent: "center",
              paddingLeft: "15px",
              paddingRight: "15px",
            }}
          >
            <ToggleButtonGroup
              style={{ minWidth: "80%" }}
              value={whenStart}
              exclusive
              orientation="vertical"
              onChange={(e, newValue) => setWhenStart(newValue)}
            >
              <ToggleButton style={{ minWidth: "33%" }} value="immediately">
                {t("profile.expertise.immediately")}
              </ToggleButton>
              <ToggleButton style={{ minWidth: "33%" }} value="in one week">
                {t("profile.expertise.in one week")}
              </ToggleButton>
              <ToggleButton style={{ minWidth: "33%" }} value="in one month">
                {t("profile.expertise.in one month")}
              </ToggleButton>
            </ToggleButtonGroup>
          </div>
          <br />
          <div
            className="d-flex"
            style={{
              paddingLeft: "15px",
              paddingRight: "15px",
              justifyContent:
                i18n.language === "ar" ? "flex-end" : "flex-start",
            }}
          >
            <h5>{t("profile.expertise.bestAt")}</h5>
          </div>
          <div
            className="d-flex"
            style={{
              justifyContent: "center",
              paddingLeft: "15px",
              paddingRight: "15px",
            }}
          >
            <ToggleButtonGroup
              style={{ minWidth: "80%" }}
              value={bestAt}
              orientation="vertical"
              exclusive
              onChange={(e, newValue) => setBestAt(newValue)}
            >
              <ToggleButton value="Frontend">Frontend</ToggleButton>
              <ToggleButton value="Backend">Backend</ToggleButton>
              <ToggleButton value="Devops">Devops</ToggleButton>
              <ToggleButton value="Design">Design</ToggleButton>
              <ToggleButton value="Database">Database</ToggleButton>
            </ToggleButtonGroup>
          </div>
          <br />
          <br />
          <div
            className="d-flex"
            style={{
              justifyContent: "center",
              paddingLeft: "30%",
              paddingRight: "30%",
            }}
          >
            <button
              //(click)="update()"
              onClick={submitHandler}
              className="update-button"
            >
              {t("profile.expertise.save")}
            </button>
          </div>
          <br />
          <br />
          <br />
        </CardContent>
      </Card>
    </div>
  );
}
