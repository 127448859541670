import axios from "axios";
import i18n from "../i18n";

/*
get a single post from the blog either AR or EN
 */
export const getPost = async (blogId, id) => {
  try {
    const { data } = await axios.get(
      `${process.env.REACT_APP_BLOGGER_API_URL}/${blogId}/posts/${id}?key=${process.env.REACT_APP_BLOGGER_KEY}`
    );

    return data;
  } catch (error) {
    throw error;
  }
};

//get all posts from the AR blog
export const getBlogPostsAr = async () => {
  try {
    const { items: posts } = await axios
      .get(
        `${process.env.REACT_APP_BLOGGER_API_URL}/9083737850860108735/posts?key=${process.env.REACT_APP_BLOGGER_KEY}`
      )
      .then((res) => res.data);

    return posts;
  } catch (error) {
    throw error;
  }
};

//get all posts from the EN blog
export const getBlogPostsEn = async () => {
  try {
    const { items: posts } = await axios
      .get(
        `${process.env.REACT_APP_BLOGGER_API_URL}/7663272713870778732/posts?key=${process.env.REACT_APP_BLOGGER_KEY}`
      )
      .then((res) => res.data);

    return posts;
  } catch (error) {
    throw error;
  }
};

/*
Get all posts from the blog either AR or EN based on the language
 */
export const getBlogPosts = async (blogId) => {
  try {
    const { items: posts } = await axios
      .get(
        `${process.env.REACT_APP_BLOGGER_API_URL}/${blogId}/posts?key=${process.env.REACT_APP_BLOGGER_KEY}`
      )
      .then((res) => res.data);

    return posts;
  } catch (error) {
    throw error;
  }
};
