//user profile
import {
  Avatar,
  Card,
  CardHeader,
  CardContent,
  Divider,
  Button,
} from "@mui/material";
import React, { useEffect } from "react";

import { Link, useParams, useNavigate } from "react-router-dom";
import Chip from "@mui/material/Chip";
import { ChatEngine, getOrCreateChat } from "react-chat-engine-advanced";

import Stack from "@mui/material/Stack";
import { Adsense } from "@ctrl/react-adsense";
import PinIcon from "../../assets/img/map-pin (1).svg";

import Loader from "../Loader/Loader";
import linkedinIcon from "../../assets/img/linkedin-profile.svg";
import twitterIcon from "../../assets/img/001-twitter-profile.svg";
import GitHubIcon from "../../assets/img/github-logo (1).png";

import "./UserProfile.css";
import { useTranslation } from "react-i18next";
import { useGetUserProfile } from "../../hooks/user";
import { useUser } from "../../hooks/auth";
import Banner from "../Banner/Banner";
//import { CreateChat, useCreateChat } from "../../hooks/chat";
const UserProfile = () => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  const {
    data: authUser,
    isLoading: authUserLoading,
    isError: authUserError,
  } = useUser();

  const params = useParams();

  const {
    data: user,
    isLoading: userLoading,
    isError: userError,
    refetch,
  } = useGetUserProfile(params.id);

  return userLoading === true ? (
    <Loader />
  ) : (
    <div
      style={{
        marginTop: "-20px",
        direction: i18n.language === "ar" ? "rtl" : "ltr",
      }}
    >
      <div className="col-md-12"></div>
      <div class="infos">
        <br />
        <Card>
          <br />
          <Banner />
          <div className="mt-5 mb-15 custom-card-header justify-content-between">
            <div className="d-flex ">
              <CardHeader
                avatar={
                  <Avatar
                    //className="card-avatar"
                    sx={{ height: "12vmax", width: "12vmax" }}
                    src={user?.avatar?.url || user?.avatarLink}
                  >
                    {user?.firstName.charAt(0)}
                    {user?.lastName.charAt(0)}
                  </Avatar>
                }
              />
              <div className="mr-3">
                <h4
                  style={{
                    textAlign: i18n.language === "ar" ? "right" : "left",
                  }}
                >
                  {user?.firstName} {user?.lastName}
                </h4>
                <h6 className={"second-title " + (i18n.language === "ar" ? "text-right" : "text-left")}>
                  <img src={PinIcon} alt="" />
                  {user?.city} , {user?.country}
                </h6>
              </div>
            </div>
          </div>
          <CardContent>
            <div class="d-flex">
              <Divider />
            </div>
            <div class="d-flex">
              <div
                class="ptb-20 profile-description"
                style={{
                  textAlign: i18n.language === "ar" ? "right" : "left",
                }}
              >
                {user?.tellMore}
              </div>
            </div>
            <br />
            <Divider />
            <div class="d-flex">
              <div class="ptb-20 social-links" style={{ paddingTop: "10px" }}>
                <b>{t("profile.social_links")}</b>
              </div>
            </div>
            <div
              class="d-flex flex-wrap"
              // style={{ paddingBottom: "10px", paddingTop: "5px" }}
            >
              {/* {typeof user?.gitHub !== "undefined" && ( */}
              <div class="col-xs-6 pt-4  col-sm-6 py-lg-2 py-md-2 col-md-3 col-lg-3">
                <div
                  style={{
                    float: i18n.language === "ar" ? "right" : "left",
                    wordBreak: "break-all",
                  }}
                >
                  <img alt="linkedin icon" src={GitHubIcon} /> &nbsp;
                  <a
                    style={{
                      fontSize: "14px",
                      textDecoration: "none",
                      color: "#37a11a",
                    }}
                    href={`https://github.com/${user?.gitHub}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    / {user?.gitHub}
                  </a>
                </div>
              </div>
              {/* )} */}

              <div class="col-xs-6 pt-4  col-sm-6 py-lg-2 py-md-2 col-md-3 col-lg-3">
                <div
                  style={{
                    float: i18n.language === "ar" ? "right" : "left",
                    wordBreak: "break-all",
                  }}
                >
                  <img alt="linkedin icon" src={linkedinIcon} /> &nbsp;
                  <a
                    style={{
                      fontSize: "14px",
                      textDecoration: "none",
                      color: "#37a11a",
                    }}
                    href={`https://linkedin.com/in/${user?.linkedInProfile}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    / {user?.linkedInProfile}
                  </a>
                </div>
              </div>
              <div class="col-xs-6 pt-4  col-sm-6 py-lg-2 py-md-2 col-md-3 col-lg-3">
                <div
                  style={{
                    float: i18n.language === "ar" ? "right" : "left",
                    wordBreak: "break-all",
                  }}
                >
                  <img src={twitterIcon} alt="twitter icon" /> &nbsp;
                  <a
                    style={{
                      fontSize: "14px",
                      textDecoration: "none",
                      color: "#37a11a",
                    }}
                    href={`https://twitter.com/${user?.twitter}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    / {user?.twitter}
                  </a>
                </div>
              </div>
            </div>
          
              <Divider className="mt-2" />
         
            <div
              class="d-flex"
              style={{ columnCount: "2", paddingTop: "10px" }}
            >
              <div style={{ width: "100%" }} className="col-xs-6 pt-2  col-sm-6 py-lg-2 py-md-2 col-md-12 col-lg-12">
              <div className={"mb-2 " + (i18n.language === "ar" ? "text-right" : "text-left")}>
                <span className="green-heading">
                  {t("profile.yearsExperience")}
                </span>
                <span className="profile-prop">{user?.yearsExperience}</span>
              </div>
              <div className={"mb-2 " + (i18n.language === "ar" ? "text-right" : "text-left")}>
                <span className="green-heading">
                  {t("profile.workedStartup")}
                </span>
                <span className="profile-prop">
                  {" "}
                  {t(
                    `profile.expertise.${user?.startupExperience?.toLowerCase()}`
                  )}
                </span>
              </div>
              <div className={"mb-2 " + (i18n.language === "ar" ? "text-right" : "text-left")}>
                <span className="green-heading">
                  {t("profile.commitFulltime")}
                </span>
                <span className="profile-prop">
                  {t(
                    `profile.expertise.${user?.commitFulltime?.toLowerCase()}`
                  )}
                </span>
              </div>
              <div className={"mb-2 " + (i18n.language === "ar" ? "text-right" : "text-left")}>
                <span className="green-heading">
                  {t("profile.willingRelocate")}
                </span>
                <span className="profile-prop">
                  {" "}
                  {t(`profile.expertise.${user?.relocate?.toLowerCase()}`)}
                </span>
              </div>
              <div className={"mb-2 " + (i18n.language === "ar" ? "text-right" : "text-left")}>
                <span className="green-heading">{t("profile.remote")}</span>
                <span className="profile-prop">
                  {" "}
                  {t(`profile.expertise.${user?.remote?.toLowerCase()}`)}
                </span>
              </div>
              <div className={"mb-2 " + (i18n.language === "ar" ? "text-right" : "text-left")}>
                <span className="green-heading">{t("profile.equity")}</span>
                <span className="profile-prop">
                  {t(`profile.expertise.${user?.equity?.toLowerCase()}`)}
                </span>
              </div>
              <div className={"mb-2 " + (i18n.language === "ar" ? "text-right" : "text-left")}>
                <span className="green-heading">{t("profile.whenStart")}</span>{" "}
                <span className="profile-prop">
                  {t(`profile.expertise.${user?.whenStart?.toLowerCase()}`)}
                </span>
              </div>
              <div className={"mb-2 " + (i18n.language === "ar" ? "text-right" : "text-left")}>
                <span className="green-heading">{t("profile.bestAt")}</span>{" "}
                <span className="profile-prop">{user?.bestAt}</span>
              </div>
              </div>
            </div>
          </CardContent>
        </Card>
        <Card className="profile-prop-card mtb-15 p-0">
          <CardHeader
            className="profile-prop-card-header profile-prop-card-header-content"
            title={
              <div className="profile-prop-card-header-content">
                <span>
                  <b>{t("profile.frontend")}</b>
                </span>
              </div>
            }
          />
          <CardContent className="profile-prop-card-content">
            <Stack
              direction="row"
              spacing={1}
              className="profile-prop-chip-list"
              color="primary"
            >
              {user?.frontend.map((value, key) => (
                <Chip color="primary" label={value} key={key} />
              ))}
            </Stack>
          </CardContent>
        </Card>
        <Card className="profile-prop-card mtb-15 p-0">
          <CardHeader
            className="profile-prop-card-header profile-prop-card-header-content"
            title={
              <div className="profile-prop-card-header-content">
                <span>
                  <b>{t("profile.backend")}</b>
                </span>
              </div>
            }
          />
          <CardContent className="profile-prop-card-content">
            <Stack
              direction="row"
              spacing={1}
              className="profile-prop-chip-list"
              color="primary"
            >
              {user?.backend.map((value, key) => (
                <Chip color="primary" label={value} key={key} />
              ))}
            </Stack>
          </CardContent>
        </Card>
        <Card className="profile-prop-card mtb-15 p-0">
          <CardHeader
            className="profile-prop-card-header profile-prop-card-header-content"
            title={
              <div className="profile-prop-card-header-content">
                <span>
                  <b>{t("profile.devops")}</b>
                </span>
              </div>
            }
          />
          <CardContent className="profile-prop-card-content">
            <Stack
              direction="row"
              spacing={1}
              className="profile-prop-chip-list"
              color="primary"
            >
              {user?.devops.map((value, key) => (
                <Chip color="primary" label={value} key={key} />
              ))}
            </Stack>
          </CardContent>
        </Card>
        <Card className="profile-prop-card mtb-15 p-0">
          <CardHeader
            className="profile-prop-card-header profile-prop-card-header-content"
            title={
              <div className="profile-prop-card-header-content">
                <span>
                  <b>{t("profile.design")}</b>
                </span>
              </div>
            }
          />
          <CardContent className="profile-prop-card-content">
            <Stack
              direction="row"
              spacing={1}
              className="profile-prop-chip-list"
              color="primary"
            >
              {user?.design.map((value, key) => (
                <Chip color="primary" label={value} key={key} />
              ))}
            </Stack>
          </CardContent>
        </Card>
        <Card className="profile-prop-card mtb-15 p-0">
          <CardHeader
            className="profile-prop-card-header profile-prop-card-header-content"
            title={
              <div className="profile-prop-card-header-content">
                <span>
                  <b>{t("profile.database")}</b>
                </span>
              </div>
            }
          />
          <CardContent className="profile-prop-card-content">
            <Stack
              direction="row"
              spacing={1}
              className="profile-prop-chip-list"
              color="primary"
            >
              {user?.database.map((value, key) => (
                <Chip color="primary" label={value} key={key} />
              ))}
            </Stack>
          </CardContent>
        </Card>
      </div>
      <div class="contact-button pb-5" style={{ paddingBottom: "15px" }}>
        <div style={{ marginBottom: "20px" }}>
          {/* <Link style={{ color: "white" }} to={`/user/contact/${user?._id}`}>
            <Button
              class="mb-5"
              // (click)="goToContactPage()"
            >
              {t("search.profile.contact")}
            </Button>

          </Link> */}

          {authUser !== null ? (
            authUser?.type === "coFounder" ? (
              <Button
                onClick={() => {
                  navigate(`/user/contact/${user?._id}`);
                  // createChat({ authUser, user });
                }}
                className="mb-5"
                // (click)="goToContactPage()"
              >
                {t("search.profile.contact")}
              </Button>
            ) : null
          ) : (
            <Button
              onClick={() => {
                navigate("/cofounder-signup");
              }}
              class="mb-5"
              // (click)="goToContactPage()"
            >
              {t("search.profile.contact")}
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default UserProfile;
