import { initializeApp } from "firebase/app";
import {
  getAuth,
  TwitterAuthProvider,
  signInWithPopup,
  GithubAuthProvider,
} from "firebase/auth";
import { toast } from "react-toastify";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);
export const TwitterSignIn = async () => {
  //this is the function that is called when the user clicks the twitter button
  const provider = new TwitterAuthProvider();
  try {
    const result = await signInWithPopup(auth, provider);
    const user = result;

    return user;
  } catch (error) {
    toast.error(error.message);
  }
};

export const GitHubSignIn = async () => {
  //this is the function that is called when the user clicks the github button
  const provider = new GithubAuthProvider();
  try {
    const result = await signInWithPopup(auth, provider);
    const user = result;

    return user;
  } catch (error) {
    toast.error(error.message);
  }
};

export default app;
