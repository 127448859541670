import axios from "axios";

const API = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  withCredentials: true,
});

//get counter
export const getCounter = async () => {
  try {
    const { counter } = await API.get("/getCounter").then((res) => res.data);
    return counter;
  } catch (error) {
    throw error;
  }
};
