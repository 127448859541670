// This component is the main account component that renders the correct account component based on the user type

import React, { useEffect } from "react";
import CoFounderAccount from "./CoFounderAccount";
import TechAccount from "./TechAccount";
import { useUser } from "../../hooks/auth";
export default function Account() {
  const { data: user, isLoading: userLoading, error } = useUser(); //useUser is a custom hook that returns the user data, loading state, and error state

  return user?.type === "coFounder" ? (
    <CoFounderAccount user={user} userLoading={userLoading} />
  ) : (
    <TechAccount user={user} userLoading={userLoading} />
  );
}
