//a component that contains the about us page and the footer component

import React from "react";
import Footer from "../Footer/Footer";
import aboutUs from "../../assets/img/aboutus.png";
import twitterIcon from "../../assets/img/002-twitter.png";
import { useTranslation } from "react-i18next";
import "./About.css";
export default function About() {
  const { t, i18n } = useTranslation();
  return (
    <div>
      <section className="aboutus center-content pt-3  ">
        <img className="aboutus-img" src={aboutUs} alt="about us vector" />
        <h3
          style={{ textAlign: i18n.language === "ar" ? "right" : "left" }}
          className="aboutus-title"
        >
          {t("global.about_us")}
        </h3>
        <h3
          style={{ textAlign: i18n.language === "ar" ? "right" : "left" }}
          className="aboutus-description"
        >
          {t("about_us.paragraph1")}
        </h3>
        <h3
          style={{ textAlign: i18n.language === "ar" ? "right" : "left" }}
          className="aboutus-description"
        >
          {t("about_us.paragraph2")}
        </h3>
        <h3
          style={{ textAlign: i18n.language === "ar" ? "right" : "left" }}
          className="aboutus-description"
        >
          {t("about_us.paragraph3")}
        </h3>
        <h3
          style={{ textAlign: i18n.language === "ar" ? "right" : "left" }}
          // [ngStyle]="{'text-align':(this.storageService.getLanguage()==='ar') ? 'right' : 'left' }"
          className="aboutus-description"
        >
          {t("about_us.paragraph4")}
        </h3>
        <span>https://twitter.com/mzu3bi</span>
        <h3 className="aboutus-twitter">{t("about_us.twitter")}</h3>
        <a
          href="https://www.twitter.com/IMATECOrocks "
          target="_blank"
          rel="noreferrer"
        >
          <img className="twitter-link" src={twitterIcon} alt="twitter icon" />
        </a>
      </section>
      <Footer />
    </div>
  );
}
