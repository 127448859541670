import React, { useState, useEffect , useRef} from 'react'
import styled from "styled-components"
import ChatInput from './ChatInput';
import { useSendMessage } from '../../hooks/message'
import { v4 as uuidv4} from "uuid";
import "./Chat.css";
import { getAllMessages } from "../../api/message";
import ChatAvatar from '../../assets/img/chat-avatar.png'

export default function Messages({ currentChat, currentUser, socket }) {
  const [messages, setMessages] = useState([]);
  const [arrivalMessage, setArrivalMessage] = useState(null);
  const {  isLoading: updateLoading,
    error: updateError,
    message: updateMessage,
    mutate: sendMessage, } = useSendMessage();

  const scrollRef = useRef();

  useEffect(() => {
    const fetchData = async () => {
      if(currentChat){
        const messages = await getAllMessages(currentUser._id,currentChat._id);
        setMessages(messages);
      }
    }
    fetchData();
      
  }, [currentChat]);

  const handleSendMsg = async (msg) => {
    const currentUserId = currentUser._id;
    const contactId = currentChat._id;
    const currentChatId = currentChat.userDoc._id;
    sendMessage({currentUserId, currentChatId, contactId, msg});

    socket.current.emit("send-msg", {
      to: currentChatId,
      from: currentUserId,
      message: msg,
    });

    const msgs = [...messages];
    msgs.push({
      fromSelf: true,
      message: msg,
    });
    setMessages(msgs);
  };

  useEffect(() => {
    if (socket.current) {
      socket.current.on("msg-recieved", (msg) => {
        setArrivalMessage({
          fromSelf: false,
          message: msg,
        });
      })
    }
  }, []);

  useEffect(()=>{
    arrivalMessage && setMessages((prev)=>[...prev,arrivalMessage]);
  },[arrivalMessage]);

  useEffect(() => {
    scrollRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);

  const addDefaultSrc = (ev)=>{
    ev.target.src = ChatAvatar;
  }

  return (
    <>
      {
        currentChat && (
          <Container>
            <div className="chat-header shadow-sm">
              <div className="user-details">
                <div className="avatar">
                  <img src={currentChat.userDoc.avatar ? currentChat.userDoc.avatar.url : ChatAvatar} onError={(e) => { addDefaultSrc(e) }} className="rounded-circle" alt="avatar" width={32} />
                </div>
                <div className="username">
                  <h6>{currentChat.userDoc.firstName} {currentChat.userDoc.lastName}</h6>
                </div>
              </div>
            </div>
            <div className="chat-messages">
              {messages && messages.map((message) => {
                return (
                  <div ref={scrollRef} key={uuidv4()}>
                    <div 
                      className={`message ${message.fromSelf ?
                        "sended" :
                        "recieved"
                        }`}
                    >
                      <div className="content">
                        <p className="m-0">{message.message.text ? message.message.text : message.message}</p>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
            <ChatInput handleSendMsg={handleSendMsg} />
          </Container>
        )
      }
    </>
  );
}

const Container = styled.div`
  display: grid;
  grid-template-rows: 6% 84% 10%;
  gap: 0.1rem;
  overflow: hidden;
  @media screen and (min-width: 720px) and (max-width: 1080px) {
    grid-template-rows: 10% 75% 15%;
  }
  .chat-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 1rem;
    height: 3rem;
    .user-details {
      display: flex;
      align-items: center;
      gap: 1rem;
      .avatar {
        img {
          height: 2rem;
        }
      }
      .username {
        h3 {
          color: white;
          text-transform: capitalize;
        }
      }
    }
  }
  .chat-messages {
    padding: 1rem 1rem;
    display: flex;
    flex-direction: column;
    gap: .5rem;
    overflow: auto;
    &::-webkit-scrollbar {
      width: 0.2rem;
      &-thumb {
        background-color: #ffffff39;
        width: 0.1rem;
        border-radius: 1rem;
      }
    }
    .message {
      display: flex;
      align-items: center;
      .content {
        max-width: 100%;
        overflow-wrap: break-word;
        padding: 1rem;
        font-size: 1.1rem;
        border-radius: 1rem;
        color: #d1d1d1;
        @media screen and (min-width: 720px) and (max-width: 1080px) {
          max-width: 70%;
        }
      }
    }
    .sended {
      justify-content: flex-end;
      .content {
        background-color: #dee2e6;
      }
    }
    .recieved {
      justify-content: flex-start;
      .content {
        background-color: #37a11a99;
      }
    }
  }
  @media screen and (max-width: 760px) {
    .chat-header{
      border-bottom:1px solid #4CA11C;
      height:3rem;
    }
    .user-details {
      display: flex;
      align-items: center;
    
      .avatar {
        img {
          height: 1rem;
        }
      }
      .username {
        h3 {
          color: white;
          text-transform: capitalize;
        }
      }
    }
    .chat-messages{
      margin-top:7%;
    }
  }
    

  }

`;