import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  getPost,
  getBlogPostsAr,
  getBlogPostsEn,
  getBlogPosts,
} from "../api/blog";

// get post
export const useGetPost = (blogId, id) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { data, isLoading, error, refetch } = useQuery(
    ["post"],
    () => getPost(blogId, id), //get post from api/blog based on blogId and id
    {
      onError: (error) => {
        toast.error(t("error"));
        navigate("/blog");
        queryClient.invalidateQueries("posts");
      },
    }
  );

  return { data, isLoading, error, refetch };
};

// get arabic blog
export const useGetArabicBlog = () => {
  const { t, i18n } = useTranslation();
  const { data, isLoading, error, refetch } = useQuery(
    ["posts"],
    () => getBlogPostsAr(), //get all posts from AR blog

    {
      onError: (error) => {
        toast.error(t("error"));
        //queryClient.invalidateQueries(["posts"]);
      },
    }
  );

  return { data, isLoading, error, refetch };
};

// get english blog
export const useGetEnglishBlog = () => {
  const { t } = useTranslation();

  const { data, isLoading, error, refetch } = useQuery(
    ["posts"],
    () => getBlogPostsEn(), //get all posts from EN blog
    {
      onError: (error) => {
        toast.error(t("error"));
        //queryClient.invalidateQueries(["posts"]);
      },
    }
  );
  return { data, isLoading, error, refetch };
};

// get blog

export const useGetBlog = () => {
  const { i18n } = useTranslation();

  let blogId;
  if (i18n.language === "ar") {
    //get blogId based on language
    blogId = "9083737850860108735";
  }
  if (i18n.language === "en") {
    blogId = "7663272713870778732";
  }

  const { data, isLoading, error, refetch } = useQuery(
    ["posts"],
    () => getBlogPosts(blogId) //get all posts from blog based on blogId
  );
  return { data, isLoading, error, refetch };
};
