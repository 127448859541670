import React from 'react';
import styled, { keyframes } from 'styled-components';

const spinAnimation = keyframes`
  to {
    transform: rotate(360deg);
  }
`;

const SpinnerContainer = styled.div`
  display: inline-block;
  position: relative;
  width: 5rem;
  height: 5rem;
  vertical-align: -0.125em;
  margin-right:3rem;
`;

const SpinnerRing = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  border: 0.25em solid #8ACFF2;
  border-right-color: transparent;
  border-radius: 50%;
  animation: .75s linear infinite ${spinAnimation};
`;

const LoadingText = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #8ACFF2;
  font-size: 1em; /* Adjust the font size as needed */
`;

const Spinner = () => {
  return (
    <SpinnerContainer>
      <SpinnerRing />
      <LoadingText>Loading</LoadingText>
    </SpinnerContainer>
  );
};

export default Spinner;
