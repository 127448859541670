// This is the 404 page
import { Typography } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import ErrorImg from "../../assets/img/404.svg";
import "./NotFound.css";

const NotFound = () => {
  return (
    <div className="notFound">
      <div className="notFoundContainer">
        <br />
        <img src={ErrorImg} alt="404" width="700px" />

        <Link to="/">
          <Typography variant="h5">Go to Home</Typography>
        </Link>
      </div>
    </div>
  );
};

export default NotFound;
