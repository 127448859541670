// Description: This component is used to tell the user that the email has been sent to confirm the account

import React from "react";
import { useTranslation } from "react-i18next";
import "../Register/Register.css";
import { Button } from "@mui/material";
import { Link } from "react-router-dom";
export default function SendConfirmUsers() {
  const { t } = useTranslation();

  return (
    <div>
      <br />
      <section className="signup">
        <h3
          // *ngIf="oathSignup==false"
          className="signup-success-verify"
        >
          {t("signup.verify")}
        </h3>
        <h3
          // *ngIf="oathSignup==true"
          className="signup-success-verify"
        >
          {t("signup.success")}
        </h3>
        <h3 className="signup-success-check">{t("signup.check")}</h3>
        <div className="d-flex justify-content-center w-100">
          <div className="w-lg-25">
            <br />
            <br />

            <br />
            <br />
            <Link to="/login">
              <Button
                variant="contained"
                className="ml-md-5 mr-md-5 resend-button"
              >
                {t("signup.go_login")}
              </Button>
            </Link>
          </div>
        </div>
      </section>
    </div>
  );
}
