import Loader from "../Loader/Loader";
import Footer from "../Footer/Footer";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import "./Home.css";

import { useGetCounter } from "../../hooks/counter";


// Import Reactstrap:
import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Fragment } from "react";
import square from "../../assets/img/namacoders-square.JPG";
import largeleaderboard from "../../assets/img/namacoders-leaderboard.JPG";

export default function Home() {
	
  const { data: counter, isLoading: loading, error } = useGetCounter();

  const { t, i18n } = useTranslation();
  return loading ? (
    <Loader />
  ) : (
    <Fragment>

		<main className='home-page mb-auto'>

			<div className="">
				<a href="https://www.namacoders.com" target='_blank'>
					<img src={largeleaderboard} alt="Nama Coders Banner Desktop" className='m-auto leaderboard h-auto object-fit-cover header-background d-none d-sm-block' />
					<img src={square} alt="Nama Coders Banner Mobile" className='m-auto square h-aut header-background d-block d-sm-none' />
				</a>
			</div>

			<header className='position-relative d-flex align-items-center justify-content-center'>
				<img src="./home_banner.png" alt="header home banner" className="w-100 h-100 object-fit-cover header-background" />
				<div className="d-flex flex-column justify-content-center align-items-center mx-auto w-50 postion-absolute header-message position-absolute text-wraper">
					<img src="./mono_logo.png" alt="" className="mb-3" />
					<p className='fw-medium text-center fs-5 text-white'>{t('home.heading-message-1')} <span className='text-heilight text-uppercase fw-bold'>{t('home.heading-message-2')}</span> {t('home.heading-message-3')}</p>
				</div>
			</header>

			<section className='py-4 py-md-5 my-xl-3'>
				<Container>
					<Row className="row-gap-4 px-4 px-sm-0">
						<Col sm={6} md={6} lg={3}>
							<Card className='border-0 bg-light py-4'>
								<Card.Body className='text-center'>
									<Card.Title>
										<img src='./icons/search.svg' alt="icons search" className='mb-3'/>
									</Card.Title>
									<Card.Subtitle className="fs-5 mb-2 fw-medium text-muted mb-3 text-capitalize">{t('home.searches')}</Card.Subtitle>
									<Card.Text className='fs-3 text-heilight fw-bold'>{counter?.nbSearchs}</Card.Text>
								</Card.Body>
							</Card>
						</Col>
						<Col sm={6} md={6} lg={3} >
							<Card className='border-0 bg-light py-4'>
								<Card.Body className='text-center'>
									<Card.Title>
										<img src='./icons/users.svg' alt="icons users" className='mb-3'/>
									</Card.Title>
									<Card.Subtitle className="fs-5 mb-2 fw-medium text-muted mb-3 text-capitalize">{t('home.users')}</Card.Subtitle>
									<Card.Text className='fs-3 text-heilight fw-bold'>{counter?.nbUsers}</Card.Text>
								</Card.Body>
							</Card>
						</Col>
						<Col sm={6} md={6} lg={3} >
							<Card className='border-0 bg-light py-4'>
								<Card.Body className='text-center'>
									<Card.Title>
										<img src='./icons/messages.svg' alt="icons messages" className='mb-3'/>
									</Card.Title>
									<Card.Subtitle className="fs-5 mb-2 fw-medium text-muted mb-3 text-capitalize">{t('home.messages')}</Card.Subtitle>
									<Card.Text className='fs-3 text-heilight fw-bold'>{counter?.nbMessages}</Card.Text>
								</Card.Body>
							</Card>
						</Col>
						<Col sm={6} md={6} lg={3} >
							<Card className='border-0 bg-light py-4'>
								<Card.Body className='text-center'>
									<Card.Title>
										<img src='./icons/visitors.svg' alt="icons visitors" className='mb-3'/>
									</Card.Title>
									<Card.Subtitle className="fs-5 mb-2 fw-medium text-muted mb-3 text-capitalize">{t('home.File visits')}</Card.Subtitle>
									<Card.Text className='fs-2 text-heilight fw-bold'>{counter?.nbVisits}</Card.Text>
								</Card.Body>
							</Card>
						</Col>
					</Row>
				</Container>
			</section>

			<Container><hr /></Container>

			<section className='py-4 py-md-5 my-xl-3 card-sign-as-wraper' lang={i18n.language === "ar" ? "ar" : "en"}>
				<Container className='px-lg-5'>
					<Row className="justify-content-center">
						<Col xl={{ span: 10}}>
							<Row className="row-gap-4 px-4 px-sm-0">
								<Col md={6}>
									<Card className='border-0 bg-light px-2 py-4 h-100 card-sign-as'>
										<Card.Body className='d-flex flex-column justify-content-between gap-4 h-100'>
											<Card.Title className='fw-medium fs-3'>{t('home.Are you looking for a')} <span className='text-heilight'>{t('home.technical co-founder')}</span></Card.Title>
											<div>
												<Card.Subtitle className="fs-6 mb-2 fw-medium fw-bold mb-3">{t('home.looking for a developer')}</Card.Subtitle>
												<Card.Text className='fw-medium mb-2 fw-bold'>{t('home.You should only register if you are:')}</Card.Text>
											</div>
											<div className="d-flex">
												<Link to='/profiles' className="btn btn-outline-primary m-auto">{t('home.search')}</Link>
											</div>
										</Card.Body>
									</Card>
								</Col>
								<Col md={6}>
									<Card className='border-0 bg-light px-2 py-4 h-100 card-sign-as'>
										<Card.Body className='d-flex flex-column justify-content-between gap-4 h-100'>
											<Card.Title className='fw-medium fs-3'>{t('home.are_you_tech')} <span className='text-heilight'>{t('home.are_you_tech2')}</span> {t('home.are_you_tech3')}</Card.Title>
											<div>
												<Card.Subtitle className="fs-6 mb-2 fw-medium fw-bold mb-3">{t('home.Sign up to become a')} {t('home.technical co-founder')} {t('home.at Start-up company')}</Card.Subtitle>
												<Card.Text className='fw-medium mb-2 fw-bold'></Card.Text>
											</div>
											<div className="d-flex">
												<Link to='/signup' className="btn btn-outline-primary m-auto">{t('home.Register As Technical Founder')}</Link>
											</div>
										</Card.Body>
									</Card>
								</Col>
							</Row>
						</Col>
					</Row>
					<p className='text-center py-4'>
						{t('home.If you have an account, please log in')}
						<Link to='/login' className="fw-medium text-capitalize">{t('home.here')}</Link>
					</p>
				</Container>
			</section>

			<section className='pb-4 pb-md-5'>
				<Container>
					<p className="text-center fs-3 mb-4 fw-medium">{t('home.Our Partners')}</p>
					<div className="d-flex justify-content-center gap-4">
						<a href="https://www.namaventures.com/" target="_balnk">
							<img src="./nama_venture_logo.png" alt="Nama Venture Logo" className='logo-partners ' />
						</a>
						<a href="https://namacoders.com" target="_balnk">
							<img src="./nama_coders_logo.png" alt="Nama Cofers Logo" className='logo-partners bg-light' />
						</a>
					</div>
				</Container>
			</section>

		</main>
		<Footer />
	</Fragment>
  );
}
