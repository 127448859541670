// Description: This is the component for the co-founder sign up page its the same as the sign up page but without the tech experience section and with more questions
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Checkbox } from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import userIcon from "../../assets/img/user.svg";
import emailIcon from "../../assets/img/mail.svg";
import keyIcon from "../../assets/img/key.svg";
import linkedInIcon from "../../assets/img/linkedin.svg";
import twitterIcon from "../../assets/img/twitter.svg";
import { data } from "../../assets/data";
import PasswordChecklist from "react-password-checklist";
import "./Register.css";
import Loader from "../Loader/Loader";
import { useTranslation } from "react-i18next";
import { Button } from "@mui/material";
import MyGitHubLoginButton from "./MyGitHubLoginButton";
import MyTwitterLoginButton from "./MyTwitterLoginButton";
import square from "../../assets/img/nama_ad_simple_square_250x250.svg";
import largeleaderboard from "../../assets/img/nama_ad_simple_horizintal_320X100 (1).svg";
import ConditionToSignUpCoFounder from "./ConditionToSignUpCoFounder";
import { TwitterSignIn, GitHubSignIn } from "../../firebase";
import { useRegister, useSocialRegister, useUserEmail } from "../../hooks/auth";
import Banner from "../Banner/Banner";
function getWindowSize() {
  //this function is used to get the window size
  const { innerWidth, innerHeight } = window;
  return { innerWidth, innerHeight };
}
export default function CoFounderSignUp() {
  const {
    data: messageEmail,
    isLoading: loadingEmail,
    error: errorEmail,
    mutate: mutateEmail,
  } = useUserEmail(); //this is used to check if the email is already used

  const {
    mutate: register,
    isLoading: RegisterLoading,
    error: RegisterError,
  } = useRegister(); //this is used to register the user using the email and password

  const {
    mutate: SocialRegister,
    isLoading: SocialLoading,
    error: SocialError,
  } = useSocialRegister(); //this is used to register the user using the social media

  const [checked, setChecked] = useState(false);
  const [checkError, setcheckError] = useState("");
  const [isMobile, setIsMobile] = useState(false);
  const [country, setCountry] = useState("");
  const [city, setCity] = useState("");
  const [yearsExperience, setYearsExperience] = useState("none");
  const [startupExperience, setStartupExperience] = useState("none");
  const [relocate, setRelocate] = useState("none");
  const [commitFulltime, setCommitFulltime] = useState("none");
  const [remote, setRemote] = useState("none");
  const [equity, setEquity] = useState("none");
  const [whenStart, setWhenStart] = useState("none");
  const [linkedInProfile, setLinkedInProfile] = useState("");
  const [gitHub, setGitHub] = useState("none");
  const [twitter, SetTwitter] = useState("");
  const [tellMore, setTellMore] = useState("");
  const [bestAt, setBestAt] = useState("none");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [socialLogin, setSocialLogin] = useState(false);
  const [socialLoginCheck, setSocialLoginCheck] = useState(false);
  const [email, setEmail] = useState("");
  const [avatar, setAvatar] = useState("");
  const [password, setPassword] = useState("");
  const [passwordAgain, setPasswordAgain] = useState("");
  const availableCities = data.countries.find((c) => c.name === country);
  const [type, setType] = useState("coFounder");
  const [isLoadingSocial, setIsLoadingSocial] = useState(false);

  useEffect(() => {
    if (messageEmail) {
      //if the email is not used then you can move to the next step
      setIsFirstStep(false);
      setIsSecondStep(true);
      if (isMobile === true) {
        //if the user is on mobile then you can move to the next step
        setIsMobile(false);
        setIsSecondStep(true);
      }
    }

    if (errorEmail) {
      setSocialLogin(false);
    }
  }, [messageEmail, errorEmail, isMobile]);

  const [windowSize, setWindowSize] = useState(getWindowSize());
  useEffect(() => {
    console.log(type)
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []); //this is used to get the window size

  const handleCheck = (event) => {
    //this is used to check if the checkbox is checked or not
    setChecked(event.target.checked);
    if (event.target.checked === true) {
      setcheckError(false);
    } else if (event.target.checked === false) {
      setcheckError(true);
    }
  };

  const { t, i18n } = useTranslation();

  const [isFirstStep, setIsFirstStep] = useState();
  const [isSecondStep, setIsSecondStep] = useState(false);

  const handelSlide1to2 = (e) => {
    //this is used to move to the next step
    e.preventDefault();
    if (checkError === "") {
      //if the checkbox is not checked then you can't move to the next step
      setcheckError(true);
    }

    if (checkError === false) {
      //if the checkbox is checked then you can send the email to the server to check if it is used or not
      mutateEmail(email);
    }
  };
  const handelSlide1toIsMobile = () => {
    if (checkError === "") {
      setcheckError(true); //if the checkbox is not checked then you can't move to the next step
    }
    if (checkError === false) {
      //mobile version
      setIsFirstStep(false);
      setIsMobile(true);
    }
  };
  const handelSlideIsMobileto2 = (e) => {
    e.preventDefault();
    if (checkError === "") {
      //if the checkbox is not checked then you can't move to the next step
      setcheckError(true);
    }
    if (checkError === false) {
      //if the checkbox is checked then you can send the email to the server to check if it is used or not
      mutateEmail(email);
    }
  };

  const handleImageChange = (e) => {
    //this is used to get the avatar of the user
    const file = e.target.files[0];

    const Reader = new FileReader();
    Reader.readAsDataURL(file);

    Reader.onload = () => {
      if (Reader.readyState === 2) {
        setAvatar(Reader.result);
      }
    };
  };
  const [frontend, setFrontEnd] = useState(["none"]);
  const [devops, setDevops] = useState(["none"]);
  const [backend, setBackend] = useState(["none"]);
  const [design, setDesign] = useState(["none"]);
  const [database, setDatabase] = useState(["none"]);
  const [socialUser, setSocialUser] = useState({});
  const [count, setCount] = useState(0); //this is used to count the number of characters in the tellMore input

  const handelTellMore = (e) => {
    //this is used to get the value of the tellMore input
    setTellMore(e.target.value);
    setCount(e.target.value.length);
  };
  const handelGithub = async (e) => {
    if (checkError === false) {
      //if the checkbox is checked then you can move to the next step
      const user = await GitHubSignIn(); //this is used to sign in with github
      setSocialUser(user.user.auth); //this is used to get the user info

      let lastName = "-"; //this is used to get the last name of the user

      setEmail(user.user.auth.currentUser.email); //this is used to get the email of the user

      setPassword(user.user.auth.currentUser.uid); //this is used to get the password of the user

      setFirstName(user.user.auth.currentUser.displayName); //this is used to get the first name of the user

      setLastName(lastName);
      setAvatar(user.user.auth.currentUser.photoURL); //this is used to get the avatar of the user

      setGitHub(user.user.auth.currentUser.reloadUserInfo.screenName); //this is used to get the github username of the user
      mutateEmail(user.user.auth.currentUser.email); //this is used to send the email to the server to check if it is used or not

      setSocialLogin(true); //this to note that the user is using social login
    } else {
      setcheckError(true);
    }
  };
  const handelTwitter = async (e) => {
    if (checkError === false) {
      const user = await TwitterSignIn(); //this is used to sign in with twitter
      setSocialUser(user.user.auth); //this is used to get the user info
      let lastName = "-";

      setEmail(user.user.auth.currentUser.email); //this is used to get the email of the user

      setPassword(user.user.auth.currentUser.uid); //this is used to get the password of the user

      setFirstName(user.user.auth.currentUser.displayName); //this is used to get the first name of the user
      setLastName(lastName);
      setAvatar(user.user.auth.currentUser.photoURL);

      SetTwitter(user.user.auth.currentUser.reloadUserInfo.screenName); //this is used to get the twitter username of the user
      mutateEmail(user.user.auth.currentUser.email); //this is used to send the email to the server to check if it is used or not

      setSocialLogin(true);
    } else {
      setcheckError(true);
    }
  };

  const submitHandler = (e) => {
    e.preventDefault();

    if (socialLogin === true) {
      //if the user is using social login
      const user = {
        firstName,
        lastName,
        email,
        password,
        avatar,
        country,
        city,
        yearsExperience,
        startupExperience,
        relocate,
        commitFulltime,
        remote,
        equity,
        whenStart,
        tellMore,
        linkedInProfile,
        gitHub,
        twitter,
        bestAt,
        frontend,
        devops,
        backend,
        design,
        database,
        type,
        socialLogin,
      };
      SocialRegister({ user, socialUser }); //this is used to register the user using social login
    } else {
      const user = {
        firstName,
        lastName,
        email,
        password,
        avatar,
        country,
        city,
        yearsExperience,
        startupExperience,
        relocate,
        commitFulltime,
        remote,
        equity,
        whenStart,
        tellMore,
        linkedInProfile,
        gitHub,
        twitter,
        bestAt,
        frontend,
        devops,
        backend,
        design,
        database,
        type,
        socialLogin,
      };
      register(user); //this is used to register the user using normal login
    }
  };

  useEffect(() => {
    if (socialLogin === true) {
      //if the user is using social login
      setIsFirstStep(false);
      setIsSecondStep(true);
    } else {
      setIsFirstStep(true);
      setIsSecondStep(false);
    }
  }, [socialLogin, checkError, checked, socialLoginCheck]);

  return RegisterLoading || SocialLoading ? (
    <Loader />
  ) : (
    <div className=" ">
      {loadingEmail && <Loader />}
      <br />
      <ConditionToSignUpCoFounder />
      <div className="container">
        <section
          className="row bg-home d-flex align-items-center justify-content-center "
          style={{
            paddingTop: "30px",
            direction: i18n.language === "ar" ? "rtl" : "ltr",
          }}
        >
          <Banner />
          <form onSubmit={submitHandler}>
            <div className="container mt-sm-5 mt-2">
              <div className=" row justify-content-center align-items-center">
                {isFirstStep === true && //
                  (isLoadingSocial === true ? (
                    <Loader />
                  ) : (
                    <>
                      <div className="col-lg-10 col-12 mb-5">
                        <div className="card login-page bg-white shadow rounded border-0">
                          <br />
                          <h4 className=" text-center">{t("signup.title")}</h4>
                          <div className="login-form mt-0">
                            <div className="row">
                              {windowSize.innerWidth > 700 && ( //desktop
                                <>
                                  <div className="col-lg-6 ">
                                    <div className="form-group position-relative px-3">
                                      <label
                                        style={{
                                          display: "flex",
                                          flexDirection:
                                            i18n.language === "ar"
                                              ? "justify-content-end"
                                              : "justify-content-start",
                                        }}
                                      >
                                        {t("signup.field_firstName")}{" "}
                                        <span className="text-danger"> *</span>
                                      </label>
                                      <i
                                        data-feather="user"
                                        className="fea icon-sm icons"
                                      ></i>
                                      <img
                                        src={userIcon}
                                        className="fea icon-sm icons"
                                        alt="user icon"
                                      />
                                      <input
                                        //[ngStyle]="{'border-color':( form1Error && (signupFormEmailGroup.value.firstName == null || signupFormEmailGroup.value.firstName.length<1))   ? 'red' : 'gray' }"
                                        className="form-control pl-5"
                                        placeholder={t("signup.field_firstName")}
                                        required
                                        value={firstName}
                                        onChange={(e) =>
                                          setFirstName(e.target.value)
                                        }
                                      />
                                    </div>
                                  </div>

                                  <div className="col-lg-6">
                                    <div className="form-group position-relative px-3">
                                      <label
                                        style={{
                                          display: "flex",
                                          flexDirection:
                                            i18n.language === "ar"
                                              ? "justify-content-end"
                                              : "justify-content-start",
                                        }}
                                      >
                                        {t("signup.field_lastName")}
                                        <span className="text-danger"> *</span>
                                      </label>
                                      <i
                                        data-feather="user"
                                        className="fea icon-sm icons"
                                      ></i>
                                      <img
                                        src={userIcon}
                                        className="fea icon-sm icons"
                                        alt="user icon"
                                      />
                                      <input
                                        className="form-control pl-5"
                                        placeholder={t("signup.field_lastName")}
                                        required
                                        value={lastName}
                                        onChange={(e) =>
                                          setLastName(e.target.value)
                                        }
                                      />
                                    </div>
                                  </div>
                                  <div className="col-lg-6">
                                    <div className="form-group position-relative px-3">
                                      <label
                                        style={{
                                          display: "flex",
                                          flexDirection:
                                            i18n.language === "ar"
                                              ? "justify-content-end"
                                              : "justify-content-start",
                                        }}
                                      >
                                        {t("signup.email")}{" "}
                                        <span className="text-danger"> *</span>
                                        {errorEmail && (
                                          <span
                                            className="text-danger"
                                            style={{
                                              textAlign:
                                                i18n.language === "ar"
                                                  ? "right"
                                                  : "left",
                                            }}
                                          >
                                            {t("signup.user_exist")}
                                          </span>
                                        )}
                                      </label>
                                      <i
                                        data-feather="user"
                                        className="fea icon-sm icons"
                                      ></i>
                                      <img
                                        src={emailIcon}
                                        className="fea icon-sm icons"
                                        alt="email icon"
                                      />
                                      <input
                                        type="email"
                                        className="form-control pl-5"
                                        placeholder={t("signup.email")}
                                        name="email"
                                        required
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                      />
                                    </div>
                                  </div>
                                  <div className="col-lg-6">
                                    <div className="form-group position-relative px-3">
                                      <label
                                        style={{
                                          display: "flex",
                                          flexDirection:
                                            i18n.language === "ar"
                                              ? "justify-content-end"
                                              : "justify-content-start",
                                        }}
                                      >
                                        {t("signup.image")}
                                      </label>

                                      <div className="row">
                                        <div className="col-10">
                                          <input
                                            className="form-control "
                                            type="file"
                                            accept="image/*"
                                            onChange={handleImageChange}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </>
                              )}
                              {windowSize.innerWidth < 700 ? ( //mobile
                                <div className="px-3">
                                  <div className="col-12 col-lg-6 pb-3">
                                    <Button
                                      onClick={handelSlide1toIsMobile}
                                      className="btn btn-primary btn-block m-auto"
                                      style={{width: "96%"}}
                                    >
                                      {t("signup.button_signup")}
                                    </Button>
                                  </div>
                                  <br />
                                  <div className="col-12 col-lg-6 mb-1">
                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          required
                                          checked={checked}
                                          onChange={handleCheck}
                                          style={{
                                            textAlign:
                                              i18n.language === "ar"
                                                ? "right"
                                                : "left",
                                          }}
                                          sx={{
                                            color: checkError === true && "#e43f52",
                                          }}
                                          color="success"
                                        />
                                      }
                                      label={
                                        <>
                                          <p
                                            style={{
                                              textAlign:
                                                i18n.language === "ar"
                                                  ? "right"
                                                  : "left",
                                              color:
                                                checkError === true && "#e43f52",
                                            }}
                                          >
                                            {t("signup.understand")}{" "}
                                            <Link
                                              style={{
                                                textDecoration: "none",
                                                color: " #37A11A",
                                              }}
                                              to="/terms"
                                            >
                                              <strong>
                                                {t("signup.userAgreement")}{" "}
                                              </strong>
                                            </Link>
                                            {t("signup.and")}{" "}
                                            <Link
                                              style={{
                                                textDecoration: "none",
                                                color: " #37A11A",
                                              }}
                                              to="/privacy"
                                            >
                                              <strong>
                                                {t("signup.privacyPolicy")}
                                              </strong>
                                            </Link>
                                          </p>
                                        </>
                                      }
                                    />
                                  </div>
                                </div>
                              ) : (
                                <>
                                  <div className="col-sm-5 col-md-12 col-lg-6 mb-1">
                                    <FormControlLabel
                                      className="px-3"
                                      control={
                                        <Checkbox
                                          required
                                          checked={checked}
                                          onChange={handleCheck}
                                          style={{
                                            textAlign:
                                              i18n.language === "ar"
                                                ? "right"
                                                : "left",
                                          }}
                                          color="success"
                                        />
                                      }
                                      label={
                                        <>
                                          <p
                                            style={{
                                              textAlign:
                                                i18n.language === "ar"
                                                  ? "right"
                                                  : "left",
                                              color:
                                                checkError === true && "#e43f52",
                                              border:
                                                checkError === true &&
                                                "2px solid #e43f52",
                                            }}
                                          >
                                            {t("signup.understand")}{" "}
                                            <Link
                                              style={{
                                                textDecoration: "none",
                                                color: " #37A11A",
                                              }}
                                              to="/terms"
                                            >
                                              <strong>
                                                {t("signup.userAgreement")}{" "}
                                              </strong>
                                            </Link>
                                            {t("signup.and")}{" "}
                                            <Link
                                              style={{
                                                textDecoration: "none",
                                                color: " #37A11A",
                                              }}
                                              to="/privacy"
                                            >
                                              <strong>
                                                {t("signup.privacyPolicy")}
                                              </strong>
                                            </Link>
                                          </p>
                                        </>
                                      }
                                    />
                                  </div>
                                  <div className="col-12 col-md-10 mx-md-5 mx-lg-4 px-sm-1 col-lg-5 pt-lg-2 pt-md-0 pt-sm-0 pb-4">
                                    <button
                                      onClick={(e) => handelSlide1to2(e)}
                                      variant="contained"
                                      className="btn btn-primary btn-block m-auto" style={{width: "96%", height: "50px"}}
                                    >
                                      {t("signup.button_signup")}
                                    </button>
                                  </div>
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  ))}
                {isMobile === true && (
                  <div className="col-lg-10 col-12 mb-5">
                    <div className="card login-page bg-white shadow rounded border-0">
                      <h4 className=" text-center">{t("signup.title")}</h4>

                      <div className="login-form mt-4">
                        <div className="row">
                          {windowSize.innerWidth < 700 && (
                            <>
                              <div className="col-lg-6">
                                <div className="form-group position-relative px-3">
                                  <label
                                    style={{
                                      display: "flex",
                                      flexDirection:
                                        i18n.language === "ar"
                                          ? "justify-content-end"
                                          : "justify-content-start",
                                    }}
                                  >
                                    {t("signup.field_firstName")}
                                    <span className="text-danger"> *</span>
                                  </label>
                                  <i
                                    data-feather="user"
                                    className="fea icon-sm icons"
                                  ></i>
                                  <img
                                    src={userIcon}
                                    className="fea icon-sm icons"
                                    alt="user icon"
                                  />
                                  <input
                                    className="form-control pl-5"
                                    placeholder={t("signup.field_firstName")}
                                    required
                                    value={firstName}
                                    onChange={(e) => setFirstName(e.target.value)}
                                  />
                                </div>
                              </div>

                              <div className="col-lg-6">
                                <div className="form-group position-relative px-3">
                                  <label
                                    style={{
                                      display: "flex",
                                      flexDirection:
                                        i18n.language === "ar"
                                          ? "justify-content-end"
                                          : "justify-content-start",
                                    }}
                                  >
                                    {t("signup.field_lastName")}
                                    <span className="text-danger"> *</span>
                                  </label>
                                  <i
                                    data-feather="user"
                                    className="fea icon-sm icons"
                                  ></i>
                                  <img
                                    src={userIcon}
                                    className="fea icon-sm icons"
                                    alt="user icon"
                                  />
                                  <input
                                    className="form-control pl-5"
                                    placeholder={t("signup.field_lastName")}
                                    required
                                    value={lastName}
                                    onChange={(e) => setLastName(e.target.value)}
                                  />
                                </div>
                              </div>
                              <div className="col-lg-6">
                                <div className="form-group position-relative px-3">
                                  <label
                                    style={{
                                      display: "flex",
                                      flexDirection:
                                        i18n.language === "ar"
                                          ? "justify-content-end"
                                          : "justify-content-start",
                                    }}
                                  >
                                    {t("signup.email")}{" "}
                                    <span className="text-danger"> *</span>
                                    {errorEmail && (
                                      <span
                                        className="text-danger"
                                        style={{
                                          textAlign:
                                            i18n.language === "ar"
                                              ? "right"
                                              : "left",
                                        }}
                                      >
                                        {t("signup.user_exist")}
                                      </span>
                                    )}
                                  </label>
                                  <i
                                    data-feather="user"
                                    className="fea icon-sm icons"
                                  ></i>
                                  <img
                                    src={emailIcon}
                                    className="fea icon-sm icons"
                                    alt="email icon"
                                  />
                                  <input
                                    type="email"
                                    className="form-control pl-5"
                                    placeholder={t("signup.email")}
                                    name="email"
                                    required
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                  />
                                </div>
                              </div>
                              <div className="col-lg-6">
                                <div className="form-group position-relative px-3 ">
                                  <label
                                    style={{
                                      display: "flex",
                                      flexDirection:
                                        i18n.language === "ar"
                                          ? "justify-content-end"
                                          : "justify-content-start",
                                    }}
                                  >
                                    {t("signup.image")}
                                  </label>

                                  <div className="row">
                                    <div className="col-10">
                                      <input
                                        className="form-control "
                                        type="file"
                                        accept="image/*"
                                        onChange={handleImageChange}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </>
                          )}
                          {windowSize.innerWidth < 700 ? (
                            <div className="px-3">
                              <div className=" col-12 col-lg-6 pb-3 ">
                                <Button
                                  onClick={(e) => handelSlideIsMobileto2(e)}
                                  className="btn btn-primary btn-block"
                                >
                                  {t("signup.button_signup")}
                                </Button>
                              </div>
                              <br />
                              <div className="col-12 col-lg-6 mb-1 px-3">
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      required
                                      checked={checked}
                                      onChange={handleCheck}
                                      style={{
                                        textAlign:
                                          i18n.language === "ar"
                                            ? "right"
                                            : "left",
                                      }}
                                      color="success"
                                    />
                                  }
                                  label={
                                    <>
                                      <p
                                        style={{
                                          textAlign:
                                            i18n.language === "ar"
                                              ? "right"
                                              : "left",
                                          color: checkError === true && "#e43f52",
                                          border:
                                            checkError === true &&
                                            "2px solid #e43f52",
                                        }}
                                      >
                                        {t("signup.understand")}{" "}
                                        <Link
                                          style={{
                                            textDecoration: "none",
                                            color: " #37A11A",
                                          }}
                                          to="/terms"
                                        >
                                          <strong>
                                            {t("signup.userAgreement")}{" "}
                                          </strong>
                                        </Link>
                                        {t("signup.and")}{" "}
                                        <Link
                                          style={{
                                            textDecoration: "none",
                                            color: " #37A11A",
                                          }}
                                          to="/privacy"
                                        >
                                          <strong>
                                            {t("signup.privacyPolicy")}
                                          </strong>
                                        </Link>
                                      </p>
                                    </>
                                  }
                                />
                              </div>
                            </div>
                          ) : (
                            <>
                              <div className="col-12 col-lg-6 mb-1">
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      required
                                      checked={checked}
                                      onChange={handleCheck}
                                      style={{
                                        textAlign:
                                          i18n.language === "ar"
                                            ? "right"
                                            : "left",
                                      }}
                                      color="success"
                                    />
                                  }
                                  label={
                                    <>
                                      <p
                                        style={{
                                          textAlign:
                                            i18n.language === "ar"
                                              ? "right"
                                              : "left",
                                          color: checkError === true && "#e43f52",
                                          border:
                                            checkError === true &&
                                            "2px solid #e43f52",
                                        }}
                                      >
                                        {t("signup.understand")}{" "}
                                        <Link
                                          style={{
                                            textDecoration: "none",
                                            color: " #37A11A",
                                          }}
                                          to="/terms"
                                        >
                                          <strong>
                                            {t("signup.userAgreement")}{" "}
                                          </strong>
                                        </Link>
                                        {t("signup.and")}{" "}
                                        <Link
                                          style={{
                                            textDecoration: "none",
                                            color: " #37A11A",
                                          }}
                                          to="/privacy"
                                        >
                                          <strong>
                                            {t("signup.privacyPolicy")}
                                          </strong>
                                        </Link>
                                      </p>
                                    </>
                                  }
                                />
                              </div>
                              <div className="col-12 col-lg-6 ml-5">
                                <Button
                                  onClick={(e) => handelSlide1to2(e)}
                                  className="btn btn-primary btn-block"
                                >
                                  {t("signup.button_signup")}
                                </Button>
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <div className=" row justify-content-center align-items-center">
                {isSecondStep === true && (
                  <div className="col-lg-5 col-md-6 mb-5 ">
                    <div className="card login-page bg-white shadow rounded border-0">
                      <div className="card-body">
                        <h5 className="card-title text-center">
                          {t("signup.title2")}
                        </h5>
                        <h6 className="card-title text-center">
                          <b>{email}</b>
                        </h6>
                        <div className="login-form mt-4">
                          <div className="row">
                            {socialLogin === true ? null : (
                              <>
                                <div className="col-lg-12">
                                  <div className="form-group position-relative toolPassword ">
                                    <label
                                      style={{
                                        display: "flex",
                                        flexDirection:
                                          i18n.language === "ar"
                                            ? "justify-content-end"
                                            : "justify-content-start",
                                      }}
                                    >
                                      {t("signup.field_password")}
                                      <span className="text-danger"> *</span>
                                    </label>
                                    <img
                                      src={keyIcon}
                                      className="fea icon-sm icons"
                                      alt="password icon"
                                    />
                                    <input
                                      type="password"
                                      className="form-control pl-5"
                                      placeholder={t("signup.field_password")}
                                      required
                                      value={password}
                                      onChange={(e) =>
                                        setPassword(e.target.value)
                                      }
                                    />
                                  </div>
                                </div>

                                <div className="col-lg-12">
                                  <div className="form-group position-relative toolPassword">
                                    <label
                                      style={{
                                        display: "flex",
                                        flexDirection:
                                          i18n.language === "ar"
                                            ? "justify-content-end"
                                            : "justify-content-start",
                                      }}
                                    >
                                      {t("signup.password-control")}
                                      <span className="text-danger"> *</span>
                                    </label>
                                    <img
                                      src={keyIcon}
                                      className="fea icon-sm icons"
                                      alt="password icon"
                                    />
                                    <input
                                      type="password"
                                      className="form-control pl-5"
                                      placeholder={t("signup.password-control")}
                                      required
                                      value={passwordAgain}
                                      onChange={(e) =>
                                        setPasswordAgain(e.target.value)
                                      }
                                    />
                                  </div>
                                  {i18n.language === "ar" ? (
                                    <PasswordChecklist
                                      rules={["minLength", "match"]}
                                      minLength={6}
                                      value={password}
                                      valueAgain={passwordAgain}
                                      messages={{
                                        minLength: "6 أحرف على الأقل",

                                        match: "يجب ان تتطابق كلمات المرور",
                                      }}
                                    />
                                  ) : (
                                    <PasswordChecklist
                                      rules={["minLength", "match"]}
                                      minLength={6}
                                      value={password}
                                      valueAgain={passwordAgain}
                                      messages={{
                                        minLength: "At least 6 characters",

                                        match: "Password must match",
                                      }}
                                    />
                                  )}
                                </div>
                              </>
                            )}

                            <div className="col-lg-12">
                              <div className="form-group position-relative ">
                                <label
                                  style={{
                                    display: "flex",
                                    flexDirection:
                                      i18n.language === "ar"
                                        ? "justify-content-end"
                                        : "justify-content-start",
                                  }}
                                >
                                  {t("signup.field_country")}
                                  <span className="text-danger"> *</span>
                                </label>
                                <select
                                  value={country}
                                  onChange={(e) => setCountry(e.target.value)}
                                  className="form-control custom-select"
                                >
                                  <option value="">---</option>

                                  {data.countries.map((value, key) => {
                                    return (
                                      <option value={value.name} key={key}>
                                        {value.name}
                                      </option>
                                    );
                                  })}
                                </select>
                              </div>
                            </div>
                            <div className="col-lg-12">
                              <div className="form-group position-relative ">
                                <label
                                  style={{
                                    display: "flex",
                                    flexDirection:
                                      i18n.language === "ar"
                                        ? "justify-content-end"
                                        : "justify-content-start",
                                  }}
                                >
                                  {t("signup.field_city")}
                                  <span className="text-danger"> *</span>
                                </label>
                                <select
                                  className="form-control custom-select"
                                  value={city}
                                  onChange={(e) => setCity(e.target.value)}
                                >
                                  <option value="">--</option>
                                  {availableCities?.cities.map((e, key) => {
                                    return (
                                      <option value={e} key={key}>
                                        {e}
                                      </option>
                                    );
                                  })}
                                </select>
                              </div>
                            </div>
                            <div className="col-lg-12" dir="ltr">
                              <label>
                                <b>LinkedIn</b>
                                <span className="text-danger"> *</span>
                              </label>
                              <div className="input-group mb-3">
                                <div className="input-group-prepend">
                                  <span
                                    className="input-group-text prepend-text"
                                    id="basic-addon3"
                                  >
                                    <img
                                      src={linkedInIcon}
                                      className="fea icon-sm icons mr-1"
                                      alt="linkedin icon"
                                    />{" "}
                                    https://www.linkedin.com/in/
                                  </span>
                                </div>
                                <input
                                  type="text"
                                  className="form-control"
                                  aria-describedby="basic-addon3"
                                  value={linkedInProfile}
                                  onChange={(e) =>
                                    setLinkedInProfile(e.target.value)
                                  }
                                />
                              </div>
                            </div>

                            <div className="col-lg-12" dir="ltr">
                              <label>
                                <b>Twitter</b>
                                <span className="text-danger"> *</span>
                              </label>
                              <div className="input-group mb-3">
                                <div className="input-group-prepend">
                                  <span
                                    className="input-group-text prepend-text"
                                    id="basic-addon3"
                                  >
                                    <img
                                      src={twitterIcon}
                                      className="fea icon-sm icons mr-1"
                                      alt="twitter icon"
                                    />{" "}
                                    https://twitter.com/
                                  </span>
                                </div>
                                <input
                                  type="text"
                                  className="form-control"
                                  aria-describedby="basic-addon3"
                                  value={twitter}
                                  onChange={(e) => SetTwitter(e.target.value)}
                                />
                              </div>
                            </div>
                            <hr />
                            <div className="col-lg-12">
                              <div className="form-group position-relative moreTool px-3">
                                <hr />
                                <label
                                  style={{
                                    display: "flex",
                                    flexDirection:
                                      i18n.language === "ar"
                                        ? "justify-content-end"
                                        : "justify-content-start",
                                  }}
                                >
                                  {t("profile.aboutMe.label_tell_more")}
                                  <span className="text-danger"> *</span>
                                </label>

                                <textarea
                                  style={{
                                    borderColor: count < 75 ? "red" : "gray",
                                  }}
                                  className="form-control"
                                  placeholder="Tell us more about yourself"
                                  maxLength="1000"
                                  minLength="75"
                                  rows="3"
                                  required
                                  value={tellMore}
                                  onChange={handelTellMore}
                                />
                                <div className="d-flex justify-content-end w-100">
                                  <p
                                    style={{
                                      color: count < 75 ? "red" : "green",
                                    }}
                                  >
                                    {count} / 75{" "}
                                    {t("profile.expertise.characters")}
                                  </p>
                                </div>
                              </div>
                            </div>

                            <div className="col-lg-12">
                              <div className="row justify-content-between ">
                                <div className="col-lg-12 pb-3 pt-3 ">
                                  <button
                                    type="submit"
                                    className="btn btn-primary btn-block "
                                  >
                                    {t("signup.continue")}
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </form>
        </section>
      </div>
    </div>
  );
}
