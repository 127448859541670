import axios from "axios";

const API = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  withCredentials: true,
});

// update user profile
export const updateUserProfile = async (userInfo) => {
  try {
    const { user } = await API.put("/update/profile", { userInfo }).then(
      (res) => res.data
    );
    return user;
  } catch (error) {
    throw error;
  }
};

// get user by id
export const getUserById = async (id) => {
  try {
    const { user } = await API.get(`/user/${id}`).then((res) => res.data);
    return user;
  } catch (error) {
    throw error;
  }
};

//get all users
export const getAllUsers = async (
  firstName,
  lastName,
  country,
  city,
  bestAt,
  yearsExperience,
  startupExperience,
  relocate,
  commitFulltime,
  remote,
  equity,
  whenStart
) => {
  try {
    const url = `/users?firstName=${firstName}&lastName=${lastName}&country=${country}&city=${city}&bestAt=${bestAt}&yearsExperience=${yearsExperience}&startupExperience=${startupExperience}&relocate=${relocate}&remote=${remote}&commitFulltime=${commitFulltime}&equity=${equity}&whenStart=${whenStart}`
    const { users } = await API.get(url
    ).then((res) => res.data);

    return users;
  } catch (error) {
    throw error;
  }
};
//confirm user after the firebase confirmation the link takes them to the confirm page and we confirm the user in the database
export const confirmUser = async (email) => {
  try {
    const { newUser } = await API.get(`/confirm/${email}`).then(
      (res) => res.data
    );
    return newUser;
  } catch (error) {
    throw error;
  }
};

//send email to contact user
export const sendContactEmail = async (emailData) => {
  try {
    const { message } = await API.post(`/user/contact/${emailData.userId}`, {
      emailData,
    }).then((res) => res.data);
    return message;
  } catch (error) {
    throw error;
  }
};
