import { Button, TextField, IconButton } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import Loader from "../Loader/Loader";
import { useTranslation } from "react-i18next";
import "./ContactUser.css";
import { useGetUserProfile, useSendContactEmail } from "../../hooks/user";
import { useUser } from "../../hooks/auth";
export default function ContactUser() {
  const { t, i18n } = useTranslation();

  const { data: authUser, isLoading: authUserLoading } = useUser(); //useUser is a custom hook that returns the user data, loading state, and error state

  let navigate = useNavigate();

  const { mutate, isLoading, error } = useSendContactEmail(); //useSendContactEmail is a custom hook that returns the mutate function, loading state, and error state

  const params = useParams();
  const {
    data: user,
    isLoading: userLoading,
    isError: userError,
    refetch,
  } = useGetUserProfile(params.id); //useGetUserProfile is a custom hook that returns the user data, loading state, error state, and refetch function

  const [errorLength, setErrorLength] = useState(false);
  const [coFounderQ1, setCoFounderQ1] = useState("");
  const [coFounderQ2, setCoFounderQ2] = useState("");
  const [coFounderQ3, setCoFounderQ3] = useState("");
  const [isDisabled, setIsDisabled] = useState(true);
  const [coFounderName, setCoFounderName] = useState(
    `${authUser?.firstName} ${authUser?.lastName}`
  );
  const [coFounderLinkedin, setCoFounderLinkedin] = useState(
    authUser?.linkedInProfile
  );
  const [coFounderEmail, setCoFounderEmail] = useState(authUser?.email);
  const [coFounderMessage, setCoFounderMessage] = useState("");
  const [userId, setUserId] = useState(params.id);

  const submitHandler = async (e) => {
    e.preventDefault();
    if (coFounderMessage.length < 199) {
      setErrorLength(true);
    } else {
      setErrorLength(false);

      mutate({
        coFounderName,
        coFounderEmail,
        coFounderMessage,
        userId,
        coFounderQ1,
        coFounderQ2,
        coFounderQ3,
        coFounderLinkedin,
      });
    }
  };

  useEffect(() => {
    if (coFounderMessage.length > 199) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [coFounderMessage]);

  return userLoading || authUserLoading ? (
    <Loader />
  ) : (
    <div className="userCard">
      <div
        dir={i18n.language === "ar" ? "rtl" : "ltr"}
        className="mr-lg-5 ml-lg-5 "
        style={{
          height: "100%",
          backgroundColor: "white",
          marginTop: "50px",
          direction: i18n.language === "ar" ? "rtl" : "ltr",
        }}
      >
        <br />
        <div
          className="page-card-header d-flex justify-content-between"
          style={{
            flexDirection: i18n.language === "ar" ? "row-reverse" : "row",
          }}
        >
          <IconButton
            color="primary"
            style={{ marginLeft: "25px", marginRight: "25px" }}
            onClick={(e) => navigate(-1)}
          >
            <ArrowBackIcon />
          </IconButton>
          <p
            className="flex-1 title-show-profile"
            style={{
              margin: "auto",
              // paddingLeft: "25px",
              // paddingRight: "25px",
            }}
          >
            {t("search.profile.contact")}
          </p>
          <br />
        </div>
        <div
          className=" d-flex justify-content-between"
          style={{
            flexDirection: i18n.language === "ar" ? "row-reverse" : "row",
          }}
        >
          <p style={{ textAlign: "center" }}> {t("contact_condition")}</p>
        </div>
        <div
          className="d-flex justify-content-between"
          style={{
            paddingLeft: "25px",
            paddingRight: "25px",
            paddingBottom: "10px",
          }}
        >
          <span className="flex-1 title-contact-name">
            <b>
              {user?.firstName} {user?.lastName}
            </b>
          </span>
        </div>
        <form onSubmit={submitHandler}>
          <div className="d-flex">
            <div
              className="contact-field-total profile-contact-name"
              style={{
                textAlign: i18n.language === "ar" ? "right" : "left",
                paddingLeft: "25px",
                paddingRight: "25px",
              }}
            >
              <TextField
                style={{
                  textAlign: i18n.language === "ar" ? "right" : "left",
                }}
                color="primary"
                className="contact-field-total"
                variant="outlined"
                label={t("search.profile.name")}
                value={coFounderName}
                onChange={(e) => setCoFounderName(e.target.value)}
              />
            </div>
          </div>
          <br />
          <div className="d-flex">
            <div
              className="contact-field-total profile-contact-email"
              style={{
                textAlign: i18n.language === "ar" ? "right" : "left",
                paddingLeft: "25px",
                paddingRight: "25px",
              }}
            >
              <TextField
                variant="outlined"
                className="contact-field-total"
                label={t("search.profile.email")}
                value={coFounderEmail}
                onChange={(e) => setCoFounderEmail(e.target.value)}
              />
            </div>
          </div>
          <br />
          <div className="d-flex">
            <div
              className="contact-field-total profile-contact-name"
              style={{
                textAlign: i18n.language === "ar" ? "right" : "left",
                paddingLeft: "25px",
                paddingRight: "25px",
              }}
            >
              <TextField
                style={{
                  textAlign: i18n.language === "ar" ? "right" : "left",
                }}
                color="primary"
                className="contact-field-total"
                variant="outlined"
                label={t("search.profile.linkedin")}
                value={coFounderLinkedin}
                onChange={(e) => setCoFounderLinkedin(e.target.value)}
              />
            </div>
          </div>
          <br />
          <div className="d-flex">
            <div
              className="contact-field-total profile-contact-message"
              style={{
                textAlign: i18n.language === "ar" ? "right" : "left",

                paddingLeft: "25px",
                paddingRight: "25px",
              }}
            >
              <TextField
                required
                multiline
                rows={4}
                className="contact-field-total"
                label={t("search.profile.message")}
                value={coFounderMessage}
                onChange={(e) => setCoFounderMessage(e.target.value)}
                error={errorLength}
              />
            </div>
          </div>
          <br />

          <div
            className="widget mb-4 pb-2 ml-4 border-bottom"
            style={{
              direction: i18n.language === "ar" ? "rtl" : "ltr",
              textAlign: i18n.language === "ar" ? "right" : "left",
            }}
          >
            <h6
              className="widget-title"
              style={{
                display: "flex",
                flexDirection:
                  i18n.language === "ar"
                    ? "justify-content-end"
                    : "justify-content-start",
              }}
            >
              {t("contact_q1")}
            </h6>
            <div className="mt-4">
              <div
                className="custom-control custom-radio"
                style={{ display: "flex" }}
              >
                <div className="form-group mb-2">
                  <input
                    value="yes"
                    type="radio"
                    id="q1yes"
                    name="q1"
                    className="custom-control-input"
                    onChange={(e) => setCoFounderQ1(e.target.value)}
                    style={{}}
                  />
                  <label
                    className="custom-control-label"
                    htmlFor="q1yes"
                    style={{}}
                  >
                    {t("profile.filter.yes")}
                  </label>
                </div>
              </div>
              <div
                className="custom-control custom-radio"
                style={{ display: "flex" }}
              >
                <div className="form-group mb-2">
                  <input
                    value="no"
                    type="radio"
                    id="q1no"
                    name="q1"
                    className="custom-control-input"
                    onChange={(e) => setCoFounderQ1(e.target.value)}
                  />
                  <label className="custom-control-label" htmlFor="q1no">
                    {t("profile.filter.no")}
                  </label>
                </div>
              </div>
            </div>
          </div>

          <div className="widget mb-4 pb-2 ml-4 border-bottom">
            <h6
              className="widget-title"
              style={{
                display: "flex",
                flexDirection:
                  i18n.language === "ar"
                    ? "justify-content-end"
                    : "justify-content-start",
              }}
            >
              {t("contact_q2")}
            </h6>
            <div className="mt-4">
              <div
                className="custom-control custom-radio"
                style={{ display: "flex" }}
              >
                <div className="form-group mb-2">
                  <input
                    value="yes"
                    type="radio"
                    id="q2yes"
                    name="q2"
                    className="custom-control-input"
                    onChange={(e) => setCoFounderQ2(e.target.value)}
                  />
                  <label className="custom-control-label" htmlFor="q2yes">
                    {t("profile.filter.yes")}
                  </label>
                </div>
              </div>
              <div
                className="custom-control custom-radio"
                style={{ display: "flex" }}
              >
                <div className="form-group mb-2">
                  <input
                    value="no"
                    type="radio"
                    id="q2no"
                    name="q2"
                    className="custom-control-input"
                    onChange={(e) => setCoFounderQ2(e.target.value)}
                  />
                  <label className="custom-control-label" htmlFor="q2no">
                    {t("profile.filter.no")}
                  </label>
                </div>
              </div>
            </div>
          </div>

          <div className="widget mb-4 pb-2 ml-4 border-bottom">
            <h6
              className="widget-title"
              style={{
                display: "flex",
                flexDirection:
                  i18n.language === "ar"
                    ? "justify-content-end"
                    : "justify-content-start",
              }}
            >
              {t("profile.filter.commitFulltime")}
            </h6>
            <div className="mt-4">
              <div
                className="custom-control custom-radio"
                style={{ display: "flex" }}
              >
                <div className="form-group mb-2">
                  <input
                    value="yes"
                    type="radio"
                    id="q3yes"
                    name="q3"
                    className="custom-control-input"
                    onChange={(e) => setCoFounderQ3(e.target.value)}
                  />
                  <label className="custom-control-label" htmlFor="q3yes">
                    {t("profile.filter.yes")}
                  </label>
                </div>
              </div>
              <div
                className="custom-control custom-radio"
                style={{ display: "flex" }}
              >
                <div className="form-group mb-2">
                  <input
                    value="no"
                    type="radio"
                    id="q3no"
                    name="q3"
                    className="custom-control-input"
                    onChange={(e) => setCoFounderQ3(e.target.value)}
                  />
                  <label className="custom-control-label" htmlFor="q3no">
                    {t("profile.filter.no")}
                  </label>
                </div>
              </div>
            </div>
          </div>

          <br />

          <br />
          <div className="d-flex w-100 justify-content-center pb-5 ">
            <Button
              color="primary"
              variant="contained"
              type="submit"
              className="w-50"
              disabled={isDisabled}
            >
              {t("search.profile.send")}
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
}
