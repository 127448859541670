// Description: Login Component
import React, { useEffect, useState } from "react";
import userIcon from "../../assets/img/mail.svg";
import keyIcon from "../../assets/img/key.svg";

import { useTranslation } from "react-i18next";

import "./Login.css";
import { Link } from "react-router-dom";

import {
  GithubLoginButton,
  TwitterLoginButton,
} from "react-social-login-buttons";
import Loader from "../Loader/Loader";

import { GitHubSignIn, TwitterSignIn } from "../../firebase";
import { useLogin, useSocialLogin } from "../../hooks/auth";
import Banner from "../Banner/Banner";
export default function Login() {
  const { t, i18n } = useTranslation();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const { mutate: login, isLoading: loginLoading } = useLogin(); //login via email

  const { mutate: socialLogin, isLoading: socialLoginLoading } =
    useSocialLogin(); //login via social media

  const handelTwitter = async (e) => {
    const user = await TwitterSignIn(); //this is the firebase function that is used to login via twitter

    socialLogin(user.user.auth); //send the user to the backend to be saved in the database
  };

  const handelGithub = async (e) => {
    const user = await GitHubSignIn(); //this is the firebase function that is used to login via github

    socialLogin(user.user.auth); //send the user to the backend to be saved in the database
  };

  const loginHandler = (e) => {
    e.preventDefault();

    login({ email, password }); //login via email
  };

  useEffect(() => {
    window.scrollTo(0, 0); //scroll to the top of the page when the component is loaded
  }, []);

  return loginLoading || socialLoginLoading ? (
    <Loader />
  ) : (
    <div>
      <br />
      <div className="container">
        <section
          className="row bg-home d-flex align-items-center justify-content-center "
          style={{ paddingTop: "0px" }}
        >
          {/*
        ads
        */}
          <Banner />
          {/*
        ads
        */}
          <div
            style={{ direction: i18n.language === "ar" ? "rtl" : "ltr" }}
            className="container mt-sm-5 mt-2"
          >
            <div className="row justify-content-center">
              <div className="col-xl-2 col-lg-2 col-md-12 col-sm-12 pt-3"></div>
              <div className="col-lg-5 col-md-6">
                <div className="card login-page bg-white shadow rounded border-0">
                  <div className="card-body">
                    <h4 className="text-center">{t("global.login")}</h4>
                    <form className="mt-4" onSubmit={loginHandler}>
                      <div className="row">
                        <div className="col-lg-12">
                          <div className="form-group position-relative">
                            <label
                              style={{
                                display: "flex",
                                flexDirection:
                                  i18n.language === "ar"
                                    ? "justify-content-end"
                                    : "justify-content-start",
                              }}
                            >
                              {t("login.field_login")}{" "}
                              <span className="text-danger">*</span>
                            </label>
                            <i
                              data-feather="user"
                              className="fea icon-sm icons"
                            ></i>
                            <img
                              src={userIcon}
                              className="fea icon-sm icons"
                              alt="user icon"
                            />
                            <input
                              type="email"
                              className="form-control pl-5"
                              placeholder={t("login.field_login")}
                              name={t("login.field_login")}
                              required
                              value={email}
                              onChange={(e) => setEmail(e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <div className="form-group position-relative">
                            <label
                              style={{
                                display: "flex",
                                flexDirection:
                                  i18n.language === "ar"
                                    ? "justify-content-end"
                                    : "justify-content-start",
                              }}
                            >
                              {t("login.field_password")}{" "}
                              <span className="text-danger">*</span>
                            </label>

                            <img
                              src={keyIcon}
                              className="fea icon-sm icons"
                              alt="password icon"
                            />
                            <input
                              type="password"
                              className="form-control pl-5"
                              //="password"
                              placeholder={t("login.field_password")}
                              required
                              value={password}
                              onChange={(e) => setPassword(e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <div className="d-flex justify-content-between mb-3">
                            <p className="forgot-pass mb-0">
                              <Link
                                to="/forgot/password"
                                className="text-dark font-weight-bold"
                              >
                                {t("global.forgot")}
                              </Link>
                            </p>
                          </div>
                        </div>
                        <div className="col-lg-12 mb-0">
                          <button
                            // (click)="login()"
                            type="submit"
                            className="btn btn-primary btn-block"
                          >
                            {" "}
                            {t("global.login")}
                          </button>
                        </div>
                        <div className="col-lg-12 mt-4 text-center">
                          <h6
                            style={{
                              textAlign:
                                i18n.language === "ar" ? "right" : "left",
                            }}
                          >
                            {t("login.login_with")}
                          </h6>
                          <div className="row  justify-content-between">
                            <div className="col-12 ">
                              <GithubLoginButton onClick={handelGithub} />
                            </div>
                            <div className="col-12 text-center">
                              <TwitterLoginButton onClick={handelTwitter} />
                            </div>

                            <div
                              style={{ justifyContent: "center" }}
                              className="col-12 text-center"
                            >
                              <p
                                className="mb-0 mt-3"
                                style={{ justifyContent: "center" }}
                              >
                                <small
                                  style={{ justifyContent: "center" }}
                                  className="text-dark mr-2 text-center"
                                >
                                  {t("login.no_account")}
                                </small>
                                <Link
                                  to="/signup"
                                  className="text-dark font-weight-bold"
                                >
                                  {t("login.new_account")}
                                </Link>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div className="col-xl-2 col-lg-2 col-md-12 col-sm-12 pt-3"></div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}
