import React from "react";
import "./Terms.css";
export default function Terms() {
  return (
    <div>
      <div className="c10">
        <h2 className="c7" id="h.if5gfdy3vx1">
          <span className="c9">Terms and Conditions</span>
        </h2>
        <p className="c0">
          <span className="c2">Welcome to imateco!</span>
        </p>
        <p className="c0">
          <span className="c2">
            These terms and conditions outline the rules and regulations for the
            use of imateco&#39;s Website, located at imateco.com.
          </span>
        </p>
        <p className="c0">
          <span className="c4">
            By accessing this website we assume you accept these terms and
            conditions. Do not continue to use imateco if you do not agree to
            take all of the terms and conditions stated on this page. Our Terms
            and Conditions were created with the help of the
          </span>
          <span className="c12">
            <a
              className="c8"
              href="https://www.google.com/url?q=https://www.termsandconditionsgenerator.com/&amp;sa=D&amp;ust=1589924782135000"
            >
              Terms And Conditions Generator
            </a>
          </span>
          <span className="c4">&nbsp;and the </span>
          <span className="c12">
            <a
              className="c8"
              href="https://www.google.com/url?q=https://www.privacypolicyonline.com/terms-conditions-generator/&amp;sa=D&amp;ust=1589924782136000"
            >
              Free Terms &amp; Conditions Generator
            </a>
          </span>
          <span className="c2">.</span>
        </p>
        <p className="c0">
          <span className="c2">
            The following terminology applies to these Terms and Conditions,
            Privacy Statement and Disclaimer Notice and all Agreements:
            &quot;Client&quot;, &quot;You&quot; and &quot;Your&quot; refers to
            you, the person log on this website and compliant to the
            Company&rsquo;s terms and conditions. &quot;The Company&quot;,
            &quot;Ourselves&quot;, &quot;We&quot;, &quot;Our&quot; and
            &quot;Us&quot;, refers to our Company. &quot;Party&quot;,
            &quot;Parties&quot;, or &quot;Us&quot;, refers to both the Client
            and ourselves. All terms refer to the offer, acceptance and
            consideration of payment necessary to undertake the process of our
            assistance to the Client in the most appropriate manner for the
            express purpose of meeting the Client&rsquo;s needs in respect of
            provision of the Company&rsquo;s stated services, in accordance with
            and subject to, prevailing law of Netherlands. Any use of the above
            terminology or other words in the singular, plural, capitalization
            and/or he/she or they, are taken as interchangeable and therefore as
            referring to same.
          </span>
        </p>
        <h3 className="c7" id="h.us44pzg5atur">
          <span className="c5">Cookies</span>
        </h3>
        <p className="c0">
          <span className="c2">
            We employ the use of cookies. By accessing imateco, you agreed to
            use cookies in agreement with the imateco&#39;s Privacy Policy.
          </span>
        </p>
        <p className="c0">
          <span className="c2">
            Most interactive websites use cookies to let us retrieve the
            user&rsquo;s details for each visit. Cookies are used by our website
            to enable the functionality of certain areas to make it easier for
            people visiting our website. Some of our affiliate/advertising
            partners may also use cookies.
          </span>
        </p>
        <h3 className="c7" id="h.spx5x7dqohbr">
          <span className="c5">License</span>
        </h3>
        <p className="c0">
          <span className="c2">
            Unless otherwise stated, imateco and/or its licensors own the
            intellectual property rights for all material on imateco. All
            intellectual property rights are reserved. You may access this from
            imateco for your own personal use subjected to restrictions set in
            these terms and conditions.
          </span>
        </p>
        <p className="c0">
          <span className="c2">You must not:</span>
        </p>
        <ul className="c3 lst-kix_l29ab2vp3s29-0 start">
          <li className="c1">
            <span className="c2">Republish material from imateco</span>
          </li>
          <li className="c1">
            <span className="c2">
              Sell, rent or sub-license material from imateco
            </span>
          </li>
          <li className="c1">
            <span className="c2">
              Reproduce, duplicate or copy material from imateco
            </span>
          </li>
          <li className="c1">
            <span className="c2">Redistribute content from imateco</span>
          </li>
        </ul>
        <p className="c0">
          <span className="c2">
            This Agreement shall begin on the date hereof.
          </span>
        </p>
        <p className="c0">
          <span className="c2">
            Parts of this website offer an opportunity for users to post and
            exchange opinions and information in certain areas of the website.
            imateco does not filter, edit, publish or review Comments prior to
            their presence on the website. Comments do not reflect the views and
            opinions of imateco,its agents and/or affiliates. Comments reflect
            the views and opinions of the person who post their views and
            opinions. To the extent permitted by applicable laws, imateco shall
            not be liable for the Comments or for any liability, damages or
            expenses caused and/or suffered as a result of any use of and/or
            posting of and/or appearance of the Comments on this website.
          </span>
        </p>
        <p className="c0">
          <span className="c2">
            imateco reserves the right to monitor all Comments and to remove any
            Comments which can be considered inappropriate, offensive or causes
            breach of these Terms and Conditions.
          </span>
        </p>
        <p className="c0">
          <span className="c2">You warrant and represent that:</span>
        </p>
        <ul className="c3 lst-kix_239ijla49d2f-0 start">
          <li className="c1">
            <span className="c2">
              You are entitled to post the Comments on our website and have all
              necessary licenses and consents to do so;
            </span>
          </li>
          <li className="c1">
            <span className="c2">
              The Comments do not invade any intellectual property right,
              including without limitation copyright, patent or trademark of any
              third party;
            </span>
          </li>
          <li className="c1">
            <span className="c2">
              The Comments do not contain any defamatory, libelous, offensive,
              indecent or otherwise unlawful material which is an invasion of
              privacy
            </span>
          </li>
          <li className="c1">
            <span className="c2">
              The Comments will not be used to solicit or promote business or
              custom or present commercial activities or unlawful activity.
            </span>
          </li>
        </ul>
        <p className="c0">
          <span className="c2">
            You hereby grant imateco a non-exclusive license to use, reproduce,
            edit and authorize others to use, reproduce and edit any of your
            Comments in any and all forms, formats or media.
          </span>
        </p>
        <h3 className="c7" id="h.1s63ij1ylupd">
          <span className="c5">Hyperlinking to our Content</span>
        </h3>
        <p className="c0">
          <span className="c2">
            The following organizations may link to our Website without prior
            written approval:
          </span>
        </p>
        <ul className="c3 lst-kix_vcuepkrxfova-0 start">
          <li className="c1">
            <span className="c2">Government agencies;</span>
          </li>
          <li className="c1">
            <span className="c2">Search engines;</span>
          </li>
          <li className="c1">
            <span className="c2">News organizations;</span>
          </li>
          <li className="c1">
            <span className="c2">
              Online directory distributors may link to our Website in the same
              manner as they hyperlink to the Websites of other listed
              businesses; and
            </span>
          </li>
          <li className="c1">
            <span className="c2">
              System wide Accredited Businesses except soliciting non-profit
              organizations, charity shopping malls, and charity fundraising
              groups which may not hyperlink to our Web site.
            </span>
          </li>
        </ul>
        <p className="c0">
          <span className="c2">
            These organizations may link to our home page, to publications or to
            other Website information so long as the link: (a) is not in any way
            deceptive; (b) does not falsely imply sponsorship, endorsement or
            approval of the linking party and its products and/or services; and
            (c) fits within the context of the linking party&rsquo;s site.
          </span>
        </p>
        <p className="c0">
          <span className="c2">
            We may consider and approve other link requests from the following
            types of organizations:
          </span>
        </p>
        <ul className="c3 lst-kix_cwgn56k3ioqo-0 start">
          <li className="c1">
            <span className="c2">
              commonly-known consumer and/or business information sources;
            </span>
          </li>
          <li className="c1">
            <span className="c2">dot.com community sites;</span>
          </li>
          <li className="c1">
            <span className="c2">
              associations or other groups representing charities;
            </span>
          </li>
          <li className="c1">
            <span className="c2">online directory distributors;</span>
          </li>
          <li className="c1">
            <span className="c2">internet portals;</span>
          </li>
          <li className="c1">
            <span className="c2">
              accounting, law and consulting firms; and
            </span>
          </li>
          <li className="c1">
            <span className="c2">
              educational institutions and trade associations.
            </span>
          </li>
        </ul>
        <p className="c0">
          <span className="c2">
            We will approve link requests from these organizations if we decide
            that: (a) the link would not make us look unfavorably to ourselves
            or to our accredited businesses; (b) the organization does not have
            any negative records with us; (c) the benefit to us from the
            visibility of the hyperlink compensates the absence of imateco; and
            (d) the link is in the context of general resource information.
          </span>
        </p>
        <p className="c0">
          <span className="c2">
            These organizations may link to our home page so long as the link:
            (a) is not in any way deceptive; (b) does not falsely imply
            sponsorship, endorsement or approval of the linking party and its
            products or services; and (c) fits within the context of the linking
            party&rsquo;s site.
          </span>
        </p>
        <p className="c0">
          <span className="c2">
            If you are one of the organizations listed in paragraph 2 above and
            are interested in linking to our website, you must inform us by
            sending an e-mail to imateco. Please include your name, your
            organization name, contact information as well as the URL of your
            site, a list of any URLs from which you intend to link to our
            Website, and a list of the URLs on our site to which you would like
            to link. Wait 2-3 weeks for a response.
          </span>
        </p>
        <p className="c0">
          <span className="c2">
            Approved organizations may hyperlink to our Website as follows:
          </span>
        </p>
        <ul className="c3 lst-kix_15qu1lfjssod-0 start">
          <li className="c1">
            <span className="c2">By use of our corporate name; or</span>
          </li>
          <li className="c1">
            <span className="c2">
              By use of the uniform resource locator being linked to; or
            </span>
          </li>
          <li className="c1">
            <span className="c2">
              By use of any other description of our Website being linked to
              that makes sense within the context and format of content on the
              linking party&rsquo;s site.
            </span>
          </li>
        </ul>
        <p className="c0">
          <span className="c2">
            No use of imateco&#39;s logo or other artwork will be allowed for
            linking absent a trademark license agreement.
          </span>
        </p>
        <h3 className="c7" id="h.4lzbfv3rf0tl">
          <span className="c5">iFrames</span>
        </h3>
        <p className="c0">
          <span className="c2">
            Without prior approval and written permission, you may not create
            frames around our Webpages that alter in any way the visual
            presentation or appearance of our Website.
          </span>
        </p>
        <h3 className="c7" id="h.yw6658bcxi6">
          <span className="c5">Content Liability</span>
        </h3>
        <p className="c0">
          <span className="c2">
            We shall not be hold responsible for any content that appears on
            your Website. You agree to protect and defend us against all claims
            that is rising on your Website. No link(s) should appear on any
            Website that may be interpreted as libelous, obscene or criminal, or
            which infringes, otherwise violates, or advocates the infringement
            or other violation of, any third party rights.
          </span>
        </p>
        <h3 className="c7" id="h.kdjnlihdvzio">
          <span className="c5">Your Privacy</span>
        </h3>
        <p className="c0">
          <span className="c2">Please read Privacy Policy</span>
        </p>
        <h3 className="c7" id="h.22cpto78f3g8">
          <span className="c5">Reservation of Rights</span>
        </h3>
        <p className="c0">
          <span className="c2">
            We reserve the right to request that you remove all links or any
            particular link to our Website. You approve to immediately remove
            all links to our Website upon request. We also reserve the right to
            amen these terms and conditions and it&rsquo;s linking policy at any
            time. By continuously linking to our Website, you agree to be bound
            to and follow these linking terms and conditions.
          </span>
        </p>
        <h3 className="c7" id="h.j1yx2mfpo7d8">
          <span className="c5">Removal of links from our website</span>
        </h3>
        <p className="c0">
          <span className="c2">
            If you find any link on our Website that is offensive for any
            reason, you are free to contact and inform us any moment. We will
            consider requests to remove links but we are not obligated to or so
            or to respond to you directly.
          </span>
        </p>
        <p className="c0">
          <span className="c2">
            We do not ensure that the information on this website is correct, we
            do not warrant its completeness or accuracy; nor do we promise to
            ensure that the website remains available or that the material on
            the website is kept up to date.
          </span>
        </p>
        <h3 className="c7" id="h.ie10m15asjf">
          <span className="c5">Disclaimer</span>
        </h3>
        <p className="c0">
          <span className="c2">
            To the maximum extent permitted by applicable law, we exclude all
            representations, warranties and conditions relating to our website
            and the use of this website. Nothing in this disclaimer will:
          </span>
        </p>
        <ul className="c3 lst-kix_y95903efz31k-0 start">
          <li className="c1">
            <span className="c2">
              limit or exclude our or your liability for death or personal
              injury;
            </span>
          </li>
          <li className="c1">
            <span className="c2">
              limit or exclude our or your liability for fraud or fraudulent
              misrepresentation;
            </span>
          </li>
          <li className="c1">
            <span className="c2">
              limit any of our or your liabilities in any way that is not
              permitted under applicable law; or
            </span>
          </li>
          <li className="c1">
            <span className="c2">
              exclude any of our or your liabilities that may not be excluded
              under applicable law.
            </span>
          </li>
        </ul>
        <p className="c0">
          <span className="c2">
            The limitations and prohibitions of liability set in this Section
            and elsewhere in this disclaimer: (a) are subject to the preceding
            paragraph; and (b) govern all liabilities arising under the
            disclaimer, including liabilities arising in contract, in tort and
            for breach of statutory duty.
          </span>
        </p>
        <p className="c0">
          <span className="c2">
            As long as the website and the information and services on the
            website are provided free of charge, we will not be liable for any
            loss or damage of any nature.
          </span>
        </p>
        <p className="c6">
          <span className="c11"></span>
        </p>
      </div>
    </div>
  );
}
