export const data = {
  countries: [
    {
      name: "Saudi Arabia",
      cities: [
        "Riyadh",
        "Jeddah",
        "Mecca",
        "Medina",
        "Hotuf",
        "Taif",
        "Dammam",
        "Buraidah",
        "Khobar",
        "Tabuk",
        "Qatif",
        "Khamis Mushait",
        "Hail",
        "Hafar Al-Batin",
        "Jubail",
        "Kharj",
        "Abha",
        "Najran",
        "Yanbu",
        "Al Qunfudhah",
      ],
    },
    {
      name: "United Arab Emirates",
      cities: [
        "Dubai",
        "Abu Dhabi",
        "Sharjah",
        "Al Ain",
        "Ajman",
        "Ras Al Khaimah",
        "Fujairah",
        "Umm Al Quwain",
        "Khor Fakkan",
      ],
    },
    {
      name: "Jordan",
      cities: [
        "Amman",
        "Zarqa",
        "Irbid",
        "Mafraq",
        "Balqa",
        "Karak",
        "Jerash",
        "Madaba",
        "Aqaba",
        "Ajloun",
        "Ma'an",
        "Tafila",
        "Al-Salt",
      ],
    },
    {
      name: "Egypt",
      cities: [
        "Cairo",
        "Alexandria",
        "Giza",
        "Shubra El Kheima",
        "Port Said",
        "Suez",
        "El Mahalla El Kubra",
        "Luxor",
        "Mansoura",
        "Tanta",
        "Asyut",
        "Ismailia",
        "Faiyum",
        "Zagazig",
        "Damietta",
        "Aswan",
        "Minya",
        "Damanhur",
        "Beni Suef",
        "Hurghada",
        "Qena",
        "Sohag",
        "Shibin El Kom",
        "Banha",
        "Arish",
      ],
    },
    {
      name: "Lebanon",
      cities: [
        "Beirut",
        "Tripoli",
        "Zahlé",
        "Sidon",
        "Aley",
        "Tyre",
        "Nabatieh",
        "Jounieh",
        "Batroun",
        "Baalbek",
      ],
    },
    {
      name: "Oman",
      cities: [
        "Muscat",
        "Seeb",
        "Salalah",
        "Bawshar",
        "Sohar",
        "Suwayq",
        "Ibri",
        "Saham",
        "Barka",
        "Rustaq",
      ],
    },
    {
      name: "Kuwait",
      cities: [
        "Kuwait City",
        "Hawalli",
        "Farwaniya",
        "Mubarak Al-Kabeer",
        "Ahmadi",
        "Jahra",
      ],
    },
    {
      name: "Bahrain",
      cities: [
        "Manama",
        "Rifa",
        "Muharraq",
        "Hamad Town",
        "A'ali",
        "Isa Town",
        "Sitra",
        "Budaiya",
        "Jidhafs",
        "Al-Malikiyah",
      ],
    },
  ],
};

export const tech = [
  { value: "Fullstack", label: "Fullstack" },
  { value: "Frontend", label: "Frontend" },
  { value: "Backend", label: "Backend" },
  { value: "Devops", label: "Devops" },
  { value: "Database", label: "Database" },
  { value: "Design", label: "Design" },
];
export const filters = [
  {
    id: "Years of experience",
    name: "Years of experience",
    options: [
      { value: "0-3", label: "0-3", checked: false },
      { value: "3-7", label: "3-7", checked: false },
      { value: "7+", label: "7+", checked: false },
    ],
  },

  {
    id: "Experience in startups",
    name: "Experience in startups",
    options: [
      { value: "Yes", label: "Yes", checked: false },
      { value: "No", label: "No", checked: false },
    ],
  },
  {
    id: "Willing to relocate",
    name: "Willing to relocate",
    options: [
      { value: "Yes", label: "Yes", checked: false },
      { value: "No", label: "No", checked: false },
    ],
  },
  {
    id: "Willing to work remotely",
    name: "Willing to work remotely",
    options: [
      { value: "Yes", label: "Yes", checked: false },
      { value: "No", label: "No", checked: false },
    ],
  },
  {
    id: "Commit full-time to startup",
    name: "Commit full-time to startup",
    options: [
      { value: "Yes", label: "Yes", checked: false },
      { value: "No", label: "No", checked: false },
    ],
  },
  {
    id: "Equity only startups",
    name: "Equity only startups",
    options: [
      { value: "Yes", label: "Yes", checked: false },
      { value: "No", label: "No", checked: false },
    ],
  },
  {
    id: "When can you start?",
    name: "When can you start?",
    options: [
      { value: "Immediately", label: "Immediately", checked: false },
      { value: "In one week", label: "In one week", checked: false },
      { value: "In one month", label: "In one month", checked: false },
    ],
  },
];
export const yearsOfExperience = [
  { value: "0-3", label: "0-3", checked: false },
  { value: "3-7", label: "3-7", checked: false },
  { value: "7+", label: "7+", checked: false },
];

export const experienceInStartups = [
  { value: "Yes", label: "Yes", checked: false },
  { value: "No", label: "No", checked: false },
];

export const willingToRelocate = [
  { value: "Yes", label: "Yes", checked: false },
  { value: "No", label: "No", checked: false },
];

export const willingToWorkRemotely = [
  { value: "Yes", label: "Yes", checked: false },
  { value: "No", label: "No", checked: false },
];
export const commitStartUp = [
  { value: "Yes", label: "Yes", checked: false },
  { value: "No", label: "No", checked: false },
];

export const EquityOnly = [
  { value: "Yes", label: "Yes", checked: false },
  { value: "No", label: "No", checked: false },
];
export const timeToStart = [
  { value: "Immediately", label: "Immediately", checked: false },
  { value: "In one week", label: "In one week", checked: false },
  { value: "In one month", label: "In one month", checked: false },
];

export const technologies = {
  frontEnd: [
    "HTML",

    "CSS",

    "Java",

    "JavaScript",

    "Java",

    "npm",

    "yarn",

    "SASS",
    "PostCSS",

    "Less",
    "Bootstrap",
    "Materialize CSS",
    "Bulma",
    "Semantic UI",
    "gulp",
    "Prettier",
    "ESLint",
    "JSHint",
    "JSLint",
    "JSCS",
    "Webpack",
    "Parcel",
    "Rollup",
    "ReactJS",
    "Angular",
    "Flutter",
    "React Native",
    "VueJS",
    "Redux",
    "MabX",
    "RxJS",
    "ngrx",
    "Vuex",
    "JSHint",

    "StyleComponents",
    "CSS Modules",
    "Emotion",
    "Radium",
    "Glamorous",
    "Jest",
    "Enzyme",
    "Cypress",
    "Mocha",
    "Chai",
    "Ava",
    "Karma",
    "Jasmine",
    "Protractor",
    "Storage",
    "Web Sockets",
    "Service Workers",
    "Location",
    "Notifications",
    "Device Orientation",
    "Payments",
    "Credentials",
    "PRPL Pattern",
    "RAIL Model",
    "Performance Metrics",
    "Using Light House",
    "Using Dev Tools",
    "TypeScript",
    "Flow",
    "NextJS",
    "AlterJS",
    "Universal",
    "NuxtJS",
    "GatsbyJS",
    "Electron",
    "Proton Native",
    "Carlo",
    "React Native",
    "NativeScript",
  ],
  backEnd: [
    "Elixir",
    "Scala",
    "Erlang",
    "Clojure",
    "Haskell",
    "Java",
    ".NET",
    "Python",
    "Ruby",
    "PHP",
    "NodeJS",
    "Golang",
    "Rust",
    "OAuth",
    "Basic Authentication",
    "Token Authentication",
    "JWT",
    "OpenID",
    "Groovy",
    "Spring Framework",
    "Laravel",
    "MEAN",
    "MERN",
    "Meteor",
    "Grails",
    "JHipster",
    "Vaadin",
    "Django",
    "Hibernate",
    "Apacha Struts",
    "JSF",
  ],
  devOps: [
    "Kamatera",
    "Buddy",
    "basis",
    "TestRail",
    "CloudHealth",
    "QuerySurge",
    "Jenkins",
    "Vagrant",
    "pagerduty",
    "Prometheus",
    "Ganglia",
    "Snort",
    "AWS",
    "Google Cloud",
    "Microsoft Azure",
    "Splunk",
    "Nagios",
    "Chef",
    "Sumo Logic",
    "OverOps",
    "Consul",
    "Docker",
    "Stackify Retrace",
    "CFEngine",
    "Artifactory",
    "Capistrano",
    "Monit",
    "Supervisor",
    "Ansible",
    "Code Climate",
    "Icinga",
    "New Relic APM",
    "Juju",
    "ProductionMap",
    "Scalyr",
    "Rudder",
    "Puppet Enterprise",
    "Graylog",
    "UpGuard",
    "Terraform",
    "Docker Swarn",
  ],
  Design: [
    "MockFlow",
    "Balsamiq",
    "Axure",
    "Adobe Comp",
    "Sketch",
    "InVision Studio",
    "Craft",
    "Proto.io",
    "Adobe XD",
    "Marvel",
    "Figma",
    "Framer X",
    "Flinto",
    "Principle",
    "UXPin",
    "ProtoPie",
    "Justinmind",
    "Origami Studio",
    "Fluid",
    "Keynote",
    "Zeplin",
  ],
  dataBase: [
    "MySQL",
    "PostgreSQL",
    "Oracle Database",
    "Redis",
    "Kafka",
    "RabbitMQ",
    "SQL Server",
    "MariaDB",
    "SQLite",
    "Neo4J",
    "DB2",
    "Access",
    "CouchDB",
    "RethinkDB",
    "Memcached",
    "MongoDB",
    "Cassandra",
    "Couchbase",
    "ArangoDB",
    "DynamoDB",
    "OrientDB",
    "HBase",
    "CosmosDB",
    "GCD",
  ],
};
